import { ALL_DASHBOARD_DETAIL} from './types';
import { apiRequest, checkTokenExpire } from './fetchActions';
import { authHeader } from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()

export const fetchAllDashboardDetail = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/dashboard-api`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: ALL_DASHBOARD_DETAIL, payload: result })
    } catch (error) {
      
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}


