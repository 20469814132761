import React, {Component} from 'react';
import { Button, Card, Container, Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { listCountries, viewTranslator, updateTranslator } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { toast } from'react-toastify';
import swal from 'sweetalert';
import Autosuggest from 'react-autosuggest';
import NotFound from '../Errors/404'

import * as check from '../../helpers/validation';
import appConfig from 'appConfig';

const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
toast.configure()

class EditTranslator extends Component{

    constructor(props){
        super(props)

        this.state = {
            translatorId:props.match.params.translatorId,
            showMessage:'',
            errors: {},
            disable: false,
            total_languages_suggestions:[],
            countries:{},
            fields:{
                language_suggestion:'',
            },
            loader:true,
            error:false,
        }

        this.initialState = JSON.parse(JSON.stringify(this.state));
    }

    componentDidMount(){

        this.getTranslatorDetails(this.state.translatorId);
    }

    getTranslatorDetails = async (translatorId)=>{
        this.setState({loader:true})
        await this.props.viewTranslator(translatorId)
        if(this.props.viewTranslatordetail.success){
            this.props.viewTranslatordetail.data.languages = this.props.viewTranslatordetail.data.languages.map(lang => lang.language)
            this.props.viewTranslatordetail.data['language_suggestion'] = "";

            this.setState({
                fields:this.props.viewTranslatordetail.data,
                loader:false,
            }, () => {
                this.getCountries();
            })
        }else{
            if(this.props.viewTranslatordetail.message == "Server encountered error"){
                this.setState({
                    error:true
                })
            }
            this.setState({loader:false})
        }
    }

    

    handleOnChange = (e, parameter) => {
        let fields = this.state.fields
        let errors = this.state.errors
        errors[parameter] = ''
        const value = parameter === 'profile' ? e.target.files[0] : e.target.value;
        fields[parameter] = value;
        if(parameter === 'profile'){
            
            fields['userAvatar'] = URL.createObjectURL(e.target.files[0])
        }
        this.setState({ fields, errors })
    }

    getCountries = async () => {
        await this.props.listCountries()
        if(Object.keys(this.props.countries).length >= 0){
            var array = (this.props.countries);
            array = Object.values(array);

            array = array.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            this.setState({
                countries: array
            })
        }else{
            
        }
    }

    resetForm = () => {
        this.setState({...this.initialState})
   }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        
        if(!fields["fullname"]){
            formIsValid = false;
            errors["fullname"] = " Full Name is required";
        }

        if(typeof fields["fullname"] !== "undefined" && !fields["fullname"] === false){
            if(!fields["fullname"].match(/^[a-zA-Z\s]+$/)){
               formIsValid = false;
               errors["fullname"] = "Enter Only letters";
            }
        }

        if(!fields["country"]){
            formIsValid = false;
            errors["country"] = "Country is required";
        }

        if(fields["languages"].length == 0){
            formIsValid = false;
            errors["languages"] = "At least enter one language";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    onHandleProjectChange = (event, { newValue, method }) => {
        let fields = this.state.fields
        fields['language_suggestion'] = newValue
        this.setState({ fields })
    };

    renderSuggestion = (suggestion, { query }) => {
        return (
            <div>
                {suggestion.name}
            </div>
            
        )
    }

    getSuggestionValue = (suggestion) => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors['languages'] = ''
        this.setState({ errors })
        if (suggestion.name < 1) return '';

        if(suggestion.name.trim().length < 1) return '';
            let languageFound = fields['languages'].find(t => t.toLowerCase() === suggestion.name.toLowerCase())
            if(languageFound){
                errors['languages'] = `Can't add duplicate languages`
                this.setState({ errors })
                return '';
            }

            fields['languages'].push(suggestion.name);
            errors['languages'] = ''
            this.setState({fields, errors, disable: false});
        
        return '';
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            total_languages_suggestions: []
        });
    };

    onSuggestionsFetchRequested = async ({ value }) => {

        await this.props.listCountries();
        var all_languages = [];

        all_languages = this.props.total_languages_suggestions;

        const escapedValue = this.escapeRegexCharacters(value.trim());

        var array = Object.values(all_languages);
        array.push({ name: "Khmer", native: "Cambodia" })
    
        if (escapedValue === '') {
            all_languages = [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');
        this.setState({
            total_languages_suggestions: array.filter((c, i) => regex.test(c.name))
        });
    };


    handleRemoveLanguageOnClick = (tag) => {

        let fields = this.state.fields;
        let languages = fields['languages'];
        languages = languages.filter(t => t !== tag)
        if(languages.length < 1){
            let errors = this.state.errors
            errors['languages'] = 'At least enter one languages'
            this.setState({ errors })
        }
        fields['languages'] = languages
        this.setState({fields})
    }

    ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;
      
      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

    

    handleOnSubmit = async (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            this.setState({ disable: true })
            let fields = this.state.fields
        
            var form_data = new FormData();

            for ( var key in fields ) {
                form_data.append(key, fields[key]);
            }
            await this.props.updateTranslator(form_data);
            if(this.props.translatorUpdated.success){
                this.setState({ disable: false })
                swal("Good job!", this.props.translatorUpdated.message, "success")
                .then(async (willDelete) => {
                    if (willDelete) {
                        this.props.history.push('/translators')
                        
                    }
                });
                this.setState({loader:false})
            }else{
                this.setState({ disable: false })
                toast.dismiss()
                toast.error( this.props.translatorUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }
        }
    }

    render(){
        const { countries, errors, disable, fields, loader, total_languages_suggestions, error } = this.state;
        const inputProps = {
            placeholder: `Add Language`,
            value: this.state.fields['language_suggestion'],
            onChange: this.onHandleProjectChange
        };
        if(!error){

            return (
                <Container fluid className="p-sm-0">
                    <Row className="mb-4">
                      <Col sm={12} className="p-0">
                        <div className="headTag d-flex align-items-center">
                          <h3>Edit Translator <span>Translator User Overview</span></h3>
                        </div>
                      </Col>
                        {   loader ?
                                <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5"> 
                                    <Loader type="Audio" color="#000" height={30} width={30} />
                                </div>
                                : 
                                <Col sm={12} className='background-add-translater'>
                                    <Card>
                                    <Card.Body>
                                        <Form onSubmit={this.handleOnSubmit}>
                                            <Row>
                                                <Col md={3} className="img-edit-translate">
                                                    <label className="imageUpload" for="imageUpload">
                                                        <img src={fields['userAvatar'] ? fields['userAvatar']  : this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${fields['profile']}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${fields['profile']}`  : require("assets/img/placeholder.png")}  alt="..." />
                                                        <input type="file" hidden id="imageUpload" onChange={(e) => this.handleOnChange(e, 'profile')} accept="image/*" />
                                                    </label>
                                                </Col>
                                                <Col md={9}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control type="text" placeholder="Fullname"  onChange={(e) => this.handleOnChange(e, 'fullname')} value={this.state.fields['fullname']}/> 
                                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["fullname"]}</span>                               
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control type="text" placeholder="Email Address" onChange={(e) => this.handleOnChange(e, 'email')} value={this.state.fields['email']} readonly='true' />
                                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["email"]}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control type="text" placeholder="Cell phone" onChange={(e) => this.handleOnChange(e, 'cellphone')} value={this.state.fields['cellphone'] === null? '' : this.state.fields['cellphone']} />
                                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["cellphone"]}</span>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control as="select" onChange={(e) => this.handleOnChange(e, 'role')} value={this.state.fields['role']}>
                                                                    <option value={fields['role']}>{fields['role']}</option>
                                                                    <option value={fields['role'][0] === 'admin' ? 'translator' : 'admin'}>{fields['role'][0] === 'admin' ? 'translator' : 'admin'}</option>
                                                                </Form.Control>     
                                                                {/* <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["country"]}</span>                           */}
                                                            </Form.Group>
                                                        </Col>    
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control as="select" onChange={(e) => this.handleOnChange(e, 'country')} value={this.state.fields['country']}>
                                                                    <option value="">Select Country</option>
                                                                    {countries.length > 0 &&
                                                                    countries.map((c, i) => {
                                                                        return (
                                                                            <option key={i} value={c.name}>
                                                                                {c.name}
                                                                            </option>
                                                                        )
                                                                    })
                                                                    
                                                                    }
                                                                    
                                                                </Form.Control>     
                                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["country"]}</span>                          
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={12}>
                                                        <div className="form-group languages-lebal">
                                                            <label htmlFor="languages">Languages</label>
                                                            {fields['languages'].length > 0 &&
                                                            <ul>
                                                                {fields['languages'].map((t, i) => (
                                                                    <li key={i}>
                                                                            <span style={{display:'inline'}} className="mr-2"> {t} </span>
                                                                            <span style={{display:'inline', cursor:'pointer'}} className="fa fa-times" onClick={() => this.handleRemoveLanguageOnClick(t)}></span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            }
                                                            <div className="inputs">
                                                                <Autosuggest 
                                                                    suggestions={total_languages_suggestions}
                                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                    getSuggestionValue={this.getSuggestionValue}
                                                                    renderSuggestion={this.renderSuggestion}
                                                                    inputProps={inputProps}
                                                                /> 
                                                            </div>
                                                            <small style={{ color:'#db1313'}}> { errors['languages'] || '' } </small>
                                                        </div>
                                                        </Col>
    
                                                    </Row>
                                                    <div className='submit-btn-edit-translate'>
                                                    <Button variant="primary" type="submit" disabled={ disable }>
                                                        Submit
                                                    </Button>
                                                    </div>  
                                                </Col>
                                            </Row>
                                        </Form>            
                                    </Card.Body>
                                    </Card>
                                </Col>
                        }
                    </Row>
                  </Container>
            )
        }else{

            return (
                <NotFound />
            )
        }
    }
}

const mapStateToProps = state => {
  const { translator } = state
  return {
    total_languages_suggestions: translator.countries.languages,
    countries: translator.countries.data,
    viewTranslatordetail : translator.viewTranslator,
    translatorUpdated : translator.translatorUpdated,
  }
}
const dispatchAction = { listCountries, viewTranslator, updateTranslator }
export default connect(mapStateToProps, dispatchAction)(EditTranslator);
