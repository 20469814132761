
import React, { Component, useState, useEffect, Fragment} from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Navbar, Form, Container, Nav, Dropdown, Button, Badge,Card, Row, Col, Table } from "react-bootstrap";
import Loader from 'react-loader-spinner';
import { isLoggedIn, decodedToken, isTranslatorLoggedIn, decodedTranslatorToken } from '../../helpers/decode-token';
import { connect } from 'react-redux';
import { getAdmin } from '../../actions/adminActions';
import { viewTranslator } from '../../actions/translatorActions';
import { toast } from'react-toastify';
import appConfig from "appConfig";

class Sidebar extends Component {

  constructor(props){

    super(props)
    
    this.state = {
        admin:'',
        translator:'',
        loader:true,
        isAdmin : false ,
        isTranslatorAdmin : false
    }

  }
 

  ImageExist = (url) => 
  {
    var img = new Image();
    img.src = url;
   
    if(img.src.indexOf('null') == -1 ){

      return true;
    }
    return false;
  }


  componentDidMount(){
    if(isLoggedIn){
      this.fetchUser()
    }
    if(isTranslatorLoggedIn){

      this.getTranslatorDetails(decodedTranslatorToken.userId);
    }

    if(window.location.href !== '/'){
      document.getElementById('dashboard-class').classList.remove('active'); 
    }


  }

  fetchUser = async () => {
      await this.props.getAdmin(decodedToken.userId)
      if(this.props.admin.success){
          this.setState({
              admin: this.props.admin.user,
              loader:false,
              isAdmin : true
          })
        }else{
          await this.props.viewTranslator(decodedToken.userId)
          if(this.props.translator.success){
              this.setState({
                admin: this.props.translator.data,
                loader:false,
                isTranslatorAdmin : true
              })
          }
              
      }
  }

  getProfileData = () =>{

    const {admin} = this.state ; 

    var isAdminImage = this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`)

    if(isAdminImage){
      return (
          // var src=`${process.env.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`
          <>
          <img src={`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`} alt="..." 
              height ="50"
              width="50"
              style={{borderRadius: "50%"}}
              /> 
              <span className = "text-white ">{admin.fullname} </span>
          </>
      )    
    }
    if(!isAdminImage){

      var isTranlatorImage = this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${admin.profile}`)

      if(isTranlatorImage){
        return (
          <>
            <img src={`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`} alt="..." 
                height ="50"
                width="50"
                style={{borderRadius: "50%"}}
            /> 
            <span className = "text-white ">{admin.fullname} </span>
          </>
        )
      }else {
        return (
          <>
            <i className="fas fa-user-circle fa-2x"></i>
            <span className = "text-white ">{admin.fullname} </span>
          </>

        )

      }

    }


  }


  getTranslatorDetails = async (translatorId)=>{
    this.setState({loader:true})
    await this.props.viewTranslator(translatorId)
    if(this.props.translator.success){
        this.props.translator.data.languages = this.props.translator.data.languages.map(lang => lang.language)

        this.setState({
            translator:this.props.translator.data,
            loader:false,
        })
    }else{
        this.setState({loader:false})
    }
  }

  render(){
    const { admin, translator, loader , isAdmin ,  isTranslatorAdmin} = this.state

    return (
      <div className="sidebar" data-image={this.props.image} data-color={this.props.color}>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url(" + this.props.image + ")",
          }}
        />
        <div className="sidebar-wrapper">
          <div className="logo d-flex align-items-center justify-content-start">
            <a href="/" className="simple-text logo-mini mx-1">
              <div className="logo-img">
                <img
                  src={require("assets/img/siteLogo.png")}
                  alt="..."
                />
              </div>
            </a>
          </div>
          <div className="sidebar-profile">
          <Nav>
            
            <li key='DASHBOARD_INDEX'>
            <NavLink
              to='/'
              className="nav-link"
              id="dashboard-class"
              
            >
              <i className='fas fa-th' />
              <p>Dashboard</p>
            </NavLink>
          </li>
          <li key='Project'>
            <NavLink
              to='/projects'
              className="nav-link"
              activeClassName="active"
               id="project-class"
            >
              <i className='fas fa-list' />
              <p>Projects / Tasks</p>
            </NavLink>
          </li>
          
          <li key='LIST_TRANSLATOR'>
            <NavLink
              to='/translators'
              className="nav-link"
              activeClassName="active"
               id="translator-class"
            >
              <i className='fas fa-users' />
              <p>Translators</p>
            </NavLink>
          </li>
          <li key='ADD_TRANSLATOR'>
            <NavLink
              to='/chat'
              className="nav-link"
              activeClassName="active"
            >
              <i className='fas fa-user' />
              <p>Chat</p>
            </NavLink>
          </li>
          {/* <li key='REPORTS'> */}
            {/* <NavLink to='/reports' className="nav-link"activeClassName="active"id="reports-class"> */}
              {/* <i className='fas fa-chart-bar' /> */}
              {/* <p>Reports</p> */}
            {/* </NavLink> */}
          {/* </li> */}

          <li key='SEARCH'>
            <NavLink
              to='/search'
              className="nav-link"
              activeClassName="active"
            >
              <i className="fa fa-search" aria-hidden="true"/>
              <p>Search</p>
            </NavLink>
         </li>
          </Nav>
          <div className="profile-sec">
          {  ( !loader && admin != 'undefined') ?
            <span className="no-icon">
              {isAdmin ? 
              // (this.ImageExist(`${process.env.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`) ? <><img src={`${process.env.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`} alt="..." 
              // height ="50"
              // width="50"
              // style={{borderRadius: "50%"}}
              // /> 
              // <span className = "text-white ">{admin.fullname}</span></>
              
              // : <i className="fas fa-user-circle fa-2x"></i>) 
              this.getProfileData()
              
              : ''} 

              {/* {isTranslatorAdmin ? (this.ImageExist(`${process.env.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${admin.profile}`) ? <><img src={`${process.env.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${admin.profile}`} alt="..." 
              height ="50"
              width="50"
              style={{borderRadius: "50%"}}
              /> 
              <span className = "text-white ">{admin.fullname} </span></>
              
              : <i className="fas fa-user-circle fa-2x"></i>) : ''}  */}

{/* 
              {isAdmin ? (this.ImageExist(`${process.env.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${admin.profile}`) ? <img src={`${process.env.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${admin.profile}`} alt="..." 
                    height ="35"
                    width="35"
                    style={{borderRadius: "50%"}}
                    /> 
                    
                    
                    : <i className="fas fa-user-circle fa-2x"></i>) : ''}  */}


              

              <Badge variant="success" className="online1"></Badge>
              <div className = "online-text">Online </div>
              
            </span>
            : <div style={{ textAlign:'center',display:'none' }} className="col-sm-12 col-sm-offset-5"> 
              </div>
          }
          </div>
          </div>
        </div>

      </div>
    );
  }
}

// export default Sidebar;
const mapStateToProps = state => {
  const { admin, translator } = state
  return {
      admin: admin.admin,
      translator: translator.viewTranslator
  }
}

const disptachActions = {
  getAdmin, 
  viewTranslator
}

export default connect(mapStateToProps, disptachActions)(Sidebar)
