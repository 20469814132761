import React, { Component } from "react";
import { Timeline } from 'rsuite';
import { connect } from 'react-redux';
import { Badge, Button, Card, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip, ListGroup, Media } from "react-bootstrap";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { isLoggedIn, decodedToken, isTranslatorLoggedIn, decodedTranslatorToken } from '../../helpers/decode-token';
import { socket } from '../../actions/fetchActions';
import { viewTranslatorSide } from '../../actions/translatorActions';
class TranslatorDashboard extends Component {

  constructor(props){
    super(props)

    this.state = {
        translatorId:null,
        showMessage:'',
        errors: {},
        disable: false,
        countries:{},
        fields:null,
        loader:true,
        projects:[],
    }

    this.initialState = JSON.parse(JSON.stringify(this.state));
}

  componentDidMount(){

    this.setState({loader:true})
    // if(localStorage.getItem('translatorProjectId') ){
    //     var projectId = localStorage.getItem('translatorProjectId');
    //     window.location.replace(`/project-detail/${projectId}`)
    // }
    if(decodedTranslatorToken){
      this.setState({translatorId:decodedTranslatorToken.userId})
      this.getTranslatorDetails(decodedTranslatorToken.userId);

    }
    socket.emit('come_online', {userId: decodedTranslatorToken.userId, type: 'translator'}) ;
  }

  getTranslatorDetails = async (translatorId)=>{
      this.setState({loader:true})
      await this.props.viewTranslatorSide(translatorId)
      if(this.props.viewTranslatordetail.success){
          this.props.viewTranslatordetail.data.languages = this.props.viewTranslatordetail.data.languages.map(lang => lang.language)

          this.setState({
              fields:this.props.viewTranslatordetail.data,
              projects:this.props.viewTranslatordetail.projects,
              loader:false,
          })
      }else{
          this.setState({loader:false})
      }
  }

  convertDate = ( TZdate ) =>{
    var dateFormat = moment()
    let date = new Date( TZdate )
    return(
      date= dateFormat.format('LL')
     ) 
    var d = new Date(date);
}

  convertDueDate = ( TZdate ) =>{
      var dateFormat = moment()
      let date = new Date( TZdate )
      return(
        date= dateFormat.format('LL')
      ) 
      var d = new Date(date);
      
  }

convertDueDateText = ( TZdate,status) =>{
    let date2 = new Date( TZdate )
    var date1 = new Date();
    var diffTime = Math.abs(date2 - date1);
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    var string = '';
    if(diffDays > 0){
      string = '<span class="text-green d-block">'+diffDays+'Days till Due<span>';
    }
    if(diffDays == 0){
      string = '<span class="text-due d-block">Due Today</span>';
    }
    if(diffDays < 0){
      diffDays = Math.abs(diffDays)
      string = '<span class="text-red d-block">'+diffDays+'Days Overdue</span>';
    }

    if(!status){
     
      string = '<span class="text-green d-block">Completed</span>';
    }

    return string;
}

showImage = ( translation_tasks ) =>{

  var string = '';
  if(translation_tasks.length > 1){
    string = <img src={require("assets/img/project-1.jpg")} alt="..." className="mr-3" />;
    return string;
  }

  if(translation_tasks[0] == "Script"){
    string = <img src={require("assets/img/project-2.jpg")} alt="..." className="mr-3" />;
    return string;
  }  

  if(translation_tasks[0] == "Consideration Pathway"){
    string = <img src={require("assets/img/project-3.jpg")} alt="..." className="mr-3" />;
    return string;
  }   

  if(translation_tasks[0] === "Headline/CTA" || translation_tasks[0] === "General Document"){
    string = <img src={require("assets/img/project-4.jpg")} alt="..." className="mr-3" />;
    return string;
  } 

    
}


  render() {
    const { countries, errors, disable, fields, loader, projects } = this.state;
    if(projects.length > 0){
      return (
        <>
        <Container fluid className="p-sm-0">
          <Row className="mb-4">
          <Col sm={12} className="p-1">
            <div className="headTag d-flex align-items-center ">
              <h3>Projects / Tasks</h3>
              
            </div>
          </Col>
          <Col md={12} className="bg-white">
              <div className="projectTable">
                  { projects.length > 0 ?
                  <>
                  <div className="table-responsive">
                      <Table>
                          <thead>
                              <tr>
                                <th>Project Name</th>
                                <th>Tasks</th>
                                <th>Due Date</th>
                             </tr>
                          </thead>
                          <tbody>

                          {projects.map(( t, i ) => {
                              return (
                              <tr  key={i}>
                                  <td>
                                  <Link to={`/project-detail/${t._id}`} >
                                  <Media className="align-items-center">
                                      {(this.showImage(t.translation_tasks))}
                                      <Media.Body>
                                        <h5>{t.projectname}</h5>
                                        <p>Convention Name</p>
                                        <p>Created on {this.convertDate( t.createdAt )}</p>
                                      </Media.Body>
                                  </Media>
                                  </Link>
                                  </td>
                                  <td>{( t.translation_tasks.join('/ ') )} </td>
                                  <td>
                                  <p><strong> {this.convertDueDate(t.due_date)}</strong>{ReactHtmlParser(this.convertDueDateText(t.due_date, t.status))}</p>
                                  </td>    
                              </tr>
                              )
                              })}
                                  
                          </tbody>
                          </Table> 
                  </div>
                  </>
                  :
                  <>
                  <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" >
                      <h4 style={{textTransform:'uppercase', textAlign:'center'}}>
                          Sorry, No Prjoects to show
                      </h4>
                  </div>
                  </>
                  }
              </div>
          </Col>
          </Row>
        </Container>
        </>
      )
    }else{

      return (
        <>
          <Container fluid className="p-sm-0">
            <Row className="mb-4">
              <Col lg="12" sm="12" >
                <Card className="card-stats">
                  <Card.Body>
                    <div className="numbers">
                      <Card.Title as="h4" className="text-center"> <span className="ml-4">Nothing to Show</span></Card.Title>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    }
  }
}



const mapStateToProps = state => {
  const { translator } = state
  return {
    viewTranslatordetail : translator.viewTranslator,
  }
}
const dispatchAction = { viewTranslatorSide }
export default connect(mapStateToProps, dispatchAction)(TranslatorDashboard);


