// ---------------------->>  Admin  <<----------------------- ///////

export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const ADMIN_FORGOT_PASSWORD = "ADMIN_FORGOT_PASSWORD";
export const GET_ADMIN = 'GET_ADMIN';
export const UPDATE_ADMIN_PROFILE = 'UPDATE_ADMIN_PROFILE';

// ---------------------->>  TRANSLATOR  <<----------------------- ///////

export const LIST_TRANSLATOR = 'LIST_TRANSLATOR';
export const TRANSLATOR_ADD = 'TRANSLATOR_ADD';
export const ACTIVE_INACTIVE_TRANSLATOR = "ACTIVE_INACTIVE_TRANSLATOR";
export const DELETE_TRANSLATOR = 'DELETE_TRANSLATOR';
export const LIST_COUNTRIES = 'LIST_COUNTRIES';
export const VIEW_TRANSLATOR = 'VIEW_TRANSLATOR';
export const UPDATE_TRANSLATOR = 'UPDATE_TRANSLATOR';
export const TRANSLATOR_LOGIN = 'TRANSLATOR_LOGIN';
export const TRANSLATOR_LOGOUT = 'TRANSLATOR_LOGOUT';
export const UPDATE_SCRIPT_LANGUAGE_API = 'UPDATE_SCRIPT_LANGUAGE_API';
export const SUGGESTIONS_SCRIPT='SUGGESTIONS_SCRIPT';
export const UPDATE_PROJECTS='UPDATE_PROJECTS';
export const NOTIFY_STATUS = 'NOTIFY_STATUS';
// export const SCRIPT_SUGGESTIONS='SCRIPT_SUGGESTIONS'

// ---------------------->>  Projects  <<----------------------- ///////


export const ALL_LANGUAGES_SUGGESTIONS = 'ALL_LANGUAGES_SUGGESTIONS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const LIST_PROJECT = 'LIST_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const ACTIVE_INACTIVE_PROJECT = "ACTIVE_INACTIVE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_API = "UPDATE_PROJECT_API";
export const VIEW_PROJECT = "VIEW_PROJECT";
export const ALL_USERS = 'ALL_USERS';
export const ALL_DASHBOARD_DETAIL = 'ALL_DASHBOARD_DETAIL';
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const SPREAD_SHEET_CONTENT = 'SPREAD_SHEET_CONTENT';
export const FETCH_PROJECT = 'FETCH_PROJECT';

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const LIST_ADMIN = 'LIST_ADMIN';
export const LIST_TOTAL_TRANSLATOR = 'LIST_TOTAL_TRANSLATOR';
export const SCRIPT_LIST = 'SCRIPT_LIST';
export const SINGLE_SCRIPT='SINGLE_SCRIPT';
export const UPDATE_SCRIPT_LANGUAGE='UPDATE_SCRIPT_LANGUAGE';
export const SCRIPT_SUGGESTIONS='SCRIPT_SUGGESTIONS';
export const SEARCH_SCRIPT='SEARCH_SCRIPT';
export const GOOGLE_DRIVE='GOOGLE_DRIVE';
export const DOWNLOAD_FILE='DOWNLOAD_FILE';
export const NOTIFY_COMPLETE_TRANSLATION = 'NOTIFY_COMPLETE_TRANSLATION'













