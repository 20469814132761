import React, {Component} from 'react';
import { Button, Card, Container, Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import { listCountries, addTranslator } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { toast } from'react-toastify';
import swal from 'sweetalert';
import Autosuggest from 'react-autosuggest';

import * as check from '../../helpers/validation';

const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
toast.configure()

class AddTranslator extends Component{

    constructor(props){
        super(props)

        this.state = {
            showMessage:'',
            errors: {},
            disable: false,
            total_languages_suggestions:[],
            countries:{},
            fields: {
                fullname:'',
                email:'',
                profile:'',
                userAvatar:require("assets/img/placeholder.png").default,
                cellphone:'',
                country:'',
                language_suggestion:'',
                languages:[],
                formData: new FormData(),
            },
        }

        this.initialState = JSON.parse(JSON.stringify(this.state));
    }

    componentDidMount(){
        this.getCountries();
    }

    

    handleOnChange = (e, parameter) => {
        let fields = this.state.fields
        let errors = this.state.errors
        errors[parameter] = ''
        const value = parameter === 'profile' ? e.target.files[0] : e.target.value;
        fields[parameter] = value;

        this.state.fields['formData'].set(parameter, value);
        if(parameter === 'profile'){
            fields['userAvatar'] = URL.createObjectURL(e.target.files[0])
        }
        this.setState({ fields, errors })
    }

    getCountries = async () => {
        await this.props.listCountries()
        if(Object.keys(this.props.countries).length >= 0){
            var array = (this.props.countries);
            array = Object.values(array);

            array = array.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            this.setState({
                countries: array
            })
        }else{
        }
    }

    resetForm = () => {
        this.setState({...this.initialState})
   }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        
        if(!fields["fullname"]){
            formIsValid = false;
            errors["fullname"] = " Full Name is required";
        }

        if(typeof fields["fullname"] !== "undefined" && !fields["fullname"] === false){
            if(!fields["fullname"].match(/^[a-zA-Z\s]+$/)){
               formIsValid = false;
               errors["fullname"] = "Enter Only letters";
            }
        }
        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Email is required";
        }

        if(typeof fields["email"] !== "undefined" && !fields["email"] === false){
            var re = /\S+@\S+\.\S+/;
            if (!re.test(fields["email"])) {
               formIsValid = false;
               errors["email"] = "Email is not valid";
            }
        }

        if(!fields["country"]){
            formIsValid = false;
            errors["country"] = "Country is required";
        }

        if(fields["languages"].length == 0){
            formIsValid = false;
            errors["languages"] = "At least enter one language";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    onHandleProjectChange = (event, { newValue, method }) => {
        let fields = this.state.fields
        fields['language_suggestion'] = newValue
        this.setState({ fields })
    };

    renderSuggestion = (suggestion, { query }) => {
        return (
            <div>
                {suggestion.name}
            </div>
            
        )
    }

    getSuggestionValue = (suggestion) => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors['languages'] = ''
        this.setState({ errors })
        if (suggestion.name < 1) return '';

        if(suggestion.name.trim().length < 1) return '';
            let languageFound = fields['languages'].find(t => t.toLowerCase() === suggestion.name.toLowerCase())
            if(languageFound){
                errors['languages'] = `Can't add duplicate languages`
                this.setState({ errors })
                return '';
            }

            fields['languages'].push(suggestion.name);
            errors['languages'] = ''
           
            this.setState({fields, errors, disable: false});
        
        return '';
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            total_languages_suggestions: []
        });
    };

    onSuggestionsFetchRequested = async ({ value }) => {

        await this.props.listCountries();
        var all_languages = [];

        all_languages = this.props.total_languages_suggestions;

        const escapedValue = this.escapeRegexCharacters(value.trim());

        var array = Object.values(all_languages);
        array.push({ name: "Khmer", native: "Cambodia" })
        array.push({name : "Urdu-Roman" , native : "رومن اردو" })
        if (escapedValue === '') {
            all_languages = [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');
        

        this.setState({
            total_languages_suggestions: array.filter((c, i) => regex.test(c.name))
        });
    };


    handleRemoveLanguageOnClick = (tag) => {
        let fields = this.state.fields;
        let languages = fields['languages'];
        languages = languages.filter(t => t !== tag)
        if(languages.length < 1){
            let errors = this.state.errors
            errors['languages'] = 'At least enter one languages'
            this.setState({ errors })
        }
        fields['languages'] = languages
        this.setState({fields})
    }

    

    handleOnSubmit = async (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            this.setState({ disable: true })
            this.state.fields['formData'].set('languages', this.state.fields['languages']);

            await this.props.addTranslator(this.state.fields['formData']);
            if(this.props.translatorAdded.success){
                this.setState({ disable: false })
                swal("Good job!", 'Translator added and account setup link is sent successfully !', "success")
                .then(async (willDelete) => {
                    if (willDelete) {
                        this.props.history.push('/translators')
                    }
                });
                this.resetForm()
            }else{
                this.setState({ disable: false })
                toast.dismiss()
                toast.error( this.props.translatorAdded.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }
        }
    }

    render(){
        const { countries, errors, disable, total_languages_suggestions } = this.state;
        const inputProps = {
            placeholder: `Add Language`,
            value: this.state.fields['language_suggestion'],
            onChange: this.onHandleProjectChange
        };
        return (
            <Container fluid className="p-sm-0">
                <Row className="mb-4">
                  <Col sm={12} className="p-0">
                    <div className="headTag d-flex align-items-center">
                      <h3>Add Translator <span>Translator User Overview</span></h3>
                    </div>
                  </Col>
                  <Col sm={12} className='background-add-translater'>
                    <Card>
                      <Card.Body>
                        <Form onSubmit={this.handleOnSubmit}>
                            <Row>
                                <Col md={3} className="img-add-translate">
                                    <label className="imageUpload" htmlFor="imageUpload">
                                        <img src={this.state.fields['userAvatar'] ? this.state.fields['userAvatar'] : require("assets/img/placeholder.png") } alt="..." />
                                        <input type="file" hidden id="imageUpload" onChange={(e) => this.handleOnChange(e, 'profile')} accept="image/*" />
                                    </label>
                                </Col>
                                <Col md={9}>
                                    <Row>   
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Fullname"  onChange={(e) => this.handleOnChange(e, 'fullname')} value={this.state.fields['fullname']}/> 
                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["fullname"]}</span>                               
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Email Address" onChange={(e) => this.handleOnChange(e, 'email')} value={this.state.fields['email']} />
                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["email"]}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Cell phone" onChange={(e) => this.handleOnChange(e, 'cellphone')} value={this.state.fields['cellphone']} />
                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["cellphone"]}</span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Control as="select" onChange={(e) => this.handleOnChange(e, 'country')} value={this.state.fields['country']}>
                                                    <option value="">Select Country</option>
                                                    {countries.length > 0 &&
                                                     countries.map((c, i) => {
                                                        return (
                                                            <option key={i} value={c.name}>
                                                                {c.name}
                                                            </option>
                                                         )
                                                     })
                                                     
                                                    }
                                                    
                                                </Form.Control>     
                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["country"]}</span>                          
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                        <div className="form-group languages-lebal">
                                            <label htmlFor="languages">Languages</label>
                                            {this.state.fields['languages'].length > 0 &&
                                            <ul>
                                                {this.state.fields['languages'].map((t, i) => (
                                                     <li key={i}>
                                                            <span style={{display:'inline'}} className="mr-2"> {t} </span>
                                                            <span style={{display:'inline', cursor:'pointer'}} className="fa fa-times" onClick={() => this.handleRemoveLanguageOnClick(t)}></span>
                                                    </li>
                                                ))}
                                            </ul>
                                            }
                                            <div className="inputs">
                                                <Autosuggest 
                                                    suggestions={total_languages_suggestions}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                    getSuggestionValue={this.getSuggestionValue}
                                                    renderSuggestion={this.renderSuggestion}
                                                    inputProps={inputProps}
                                                /> 
                                            </div>
                                            <small style={{ color:'#db1313'}}> { errors['languages'] || '' } </small>
                                        </div>
                                        </Col>

                                    </Row>
                                    <div className='submit-btn-edit-translate'>
                                    <Button variant="primary" type="submit" disabled={ disable }>
                                        {disable ? 'Wait..':'Submit'}
                                    </Button>
                                    </div>  
                                </Col>
                            </Row>
                        </Form>            
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
        )
    }
}

const mapStateToProps = state => {
  const { translator } = state
  return {
    total_languages_suggestions: translator.countries.languages,
    countries: translator.countries.data,
    translatorAdded : translator.add
  }
}
const dispatchAction = { listCountries, addTranslator }
export default connect(mapStateToProps, dispatchAction)(AddTranslator);
