import { ALL_LANGUAGES_SUGGESTIONS, ADD_PROJECT, LIST_PROJECT, DELETE_PROJECT, ACTIVE_INACTIVE_PROJECT, UPDATE_PROJECT, VIEW_PROJECT, ALL_USERS, SPREAD_SHEET_CONTENT,SCRIPT_LIST,FETCH_PROJECT,SINGLE_SCRIPT, UPDATE_SCRIPT_LANGUAGE,UPDATE_PROJECT_API,SCRIPT_SUGGESTIONS,SEARCH_SCRIPT,GOOGLE_DRIVE,DOWNLOAD_FILE,NOTIFY_COMPLETE_TRANSLATION} from './types';
import { apiRequest, translatorApiRequest, adminApiRequest, checkTokenExpire,formDataPost} from './fetchActions';
import { authHeader } from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()



export const fetchAllLanguageSuggestions = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/all-language-suggestions`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: ALL_LANGUAGES_SUGGESTIONS, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const addProject = ( data ) => async dispatch => {
   
    try {
        const result = await formDataPost(`v1/create-project`,  data);
        checkTokenExpire(result);
        await dispatch({ type: ADD_PROJECT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const viewSpreadSheetContent = (scriptId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/spread-sheet-content/${scriptId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: SPREAD_SHEET_CONTENT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const listProject = (page, per_page) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/projects?page=${page}&per_page=${per_page}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: LIST_PROJECT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const deleteProject = ( projectId ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
        }
        const result = await apiRequest(`v1/project/${projectId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: DELETE_PROJECT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const activeInactiveProject = (projectId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
        }
        const result = await apiRequest(`v1/project/change-status/${projectId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: ACTIVE_INACTIVE_PROJECT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
       
    }
}


export const updateProject = (projectId, data ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`v1/project/${projectId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: UPDATE_PROJECT_API, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const viewProject = ( projectId ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
        }
        const result = await apiRequest(`v1/project/${projectId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: VIEW_PROJECT, payload: result })

    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const fetchAllUsers = () => async dispatch => {
    try {
        var err = "";
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await adminApiRequest(`v1/users?searchAdmin=`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: ALL_USERS, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const viewProjectEmail = ( projectId ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8"
            },
        }
        const result = await translatorApiRequest(`v1/project/${projectId}`, requestOptions);
        await dispatch({ type: VIEW_PROJECT, payload: result })

    } catch (error) {
        toast.dismiss()
        toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
    }
}

export const fetchAllLanguageSuggestionsEmail = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8"
            },
        }
        const result = await translatorApiRequest(`v1/all-language-suggestions`, requestOptions);
        await dispatch({ type: ALL_LANGUAGES_SUGGESTIONS, payload: result })
    } catch (error) {
        toast.dismiss()
        toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
    }
}

export const fetchAllUsersEmail = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8"
            },
        }
        const result = await translatorApiRequest(`v1/users?searchAdmin=`, requestOptions);
        
        await dispatch({ type: ALL_USERS, payload: result })
    } catch (error) {
        
        toast.dismiss()
        toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
    }
}

export const updateTranslatorProject = ( data, projectId ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
            body: JSON.stringify(data)
        }
        const result = await translatorApiRequest(`v1/project/${projectId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: UPDATE_PROJECT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const scriptList = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/scripts`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: SCRIPT_LIST, payload: result })
    } catch (error) {
        if(error && error.status === 401){
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}


export const fetchProject = () => async dispatch => {
    try {
        const requestProject = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/project-lang`, requestProject);
        checkTokenExpire(result);
        await dispatch({ type: FETCH_PROJECT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const singleScript=(project_Id)=>async dispatch=>{
    try {
        const requestScript = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/single_project/${project_Id}`, requestScript);
        checkTokenExpire(result);
        await dispatch({ type: SINGLE_SCRIPT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

 export const updateScriptLanguage=(projectId,data)=>async dispatch=>{
    // console.log("dataActions",data)
    try {
        const requestScriptUpdate = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
            body: JSON.stringify(data)
        }
        // console.log("requestScriptUpdate", requestScriptUpdate)
        const result = await apiRequest(`v1/update-script-translation/${projectId}`, requestScriptUpdate);
        // console.log("result",result)
        checkTokenExpire(result);
        await dispatch({ type:  UPDATE_SCRIPT_LANGUAGE, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const scriptSuggestions = (language, statement) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/script_suggestion?language=${language}&statement=${statement}`, requestOptions);
        
        checkTokenExpire(result);
        await dispatch({ type: SCRIPT_SUGGESTIONS, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}


export const searchScript = (statement) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/search_script?statement=${statement}`,requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: SEARCH_SCRIPT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const exportToGoogleDrive=(data)=>async dispatch=>{
    try {
        const requestScriptUpdate = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`v1/exportToGoogleDrive`, requestScriptUpdate);
        checkTokenExpire(result);
        await dispatch({ type: GOOGLE_DRIVE, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const downloadFile=(data)=>async dispatch=>{
    try {
        const requestScriptUpdate = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`v1/downloadFile`, requestScriptUpdate);
        checkTokenExpire(result);
        await dispatch({ type: DOWNLOAD_FILE, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }    
}


export const sendTranslationNotification = (projectId , data ) => async dispatch => {
    // console.log( "projectId" ,projectId)
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
            body: JSON.stringify(data)
        }    
        const result = await apiRequest(`v1/send_Translation_notification/${projectId}`, requestOptions);
        // console.log("result471",result)
        checkTokenExpire(result);
        await dispatch({ type: NOTIFY_COMPLETE_TRANSLATION , payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
       
    }
}


        


    


 