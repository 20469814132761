import React, { Component } from "react";
import { Button, Card, Container, Row, Col, Form, Media, Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import Autosuggest from 'react-autosuggest';
import { fetchAllLanguageSuggestions, updateProject, viewProject, fetchAllUsers } from '../../actions/projectActions';
import { decodedToken } from '../../helpers/decode-token';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import NotFound from '../Errors/404'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import "excel2json-wasm";
import ScrollToBottom from 'react-scroll-to-bottom';
import appConfig from "appConfig";


class EditProject extends Component {

    constructor(props) {
        super(props)

        this.state = {
            projectId: props.match.params.projectId,
            showMessage: '',
            errors: {},
            disable: false,
            total_languages_suggestions: [],
            fields: {
                projectname: '',
                due_date: new Date(),
                translation_tasks: [],
                language_suggestion: '',
                languages: [],
                activities: '',
                comments: '',
                scriptFile: '',
                edit_translator: 'admin',
                each_translation_status: []
            },
            loader: true,
            error: false,
            comment: '',
        }

        this.initialState = JSON.parse(JSON.stringify(this.state));
    }

    componentDidMount(){
        this.getProjectDetails(this.state.projectId);
    }



    getProjectDetails = async (projectId) => {

        let fields = this.state.fields;
        this.setState({ loader: true })
        await this.props.viewProject(projectId)
        if (this.props.viewProjectdetail.success) {
            fields['projectname'] = this.props.viewProjectdetail.data.projectname;
            fields['due_date'] = new Date(this.props.viewProjectdetail.data.due_date);
            fields['translation_tasks'] = this.props.viewProjectdetail.data.translation_tasks;
            fields['languages'] = (this.props.viewProjectdetail.project_languages);
            fields['comments'] = (this.props.viewProjectdetail.data.comments);
            fields['activities'] = this.props.viewProjectdetail.data.activities;
            fields['scriptFile'] = this.props.viewProjectdetail.data.script;
            fields['each_translation_status'] = this.props.viewProjectdetail.data.each_translation_status;
            this.setState({ fields })
            fields['']
            this.setState({activities:this.props.viewProjectdetail.data.activities})
            await this.props.fetchAllUsers();
            if (this.props.total_users.response) {
                let fields = this.state.fields;
                var total_users = this.props.total_users.users;

                // fields['activities'].forEach((element, index) => {
                //     total_users.forEach((element1, index1) => {

                //         if (element.userId == element1._id) {
                //             element.user = element1;
                //             element.createdAt = this.timeSince(this.props.viewProjectdetail.data.createdAt);
                //             element = this.props.viewProjectdetail.success
                //             return this.props.viewProjectdetail.success;
                //         }
                //         this.setState({ activities: this.props.viewProjectdetail.data.activities })
                //     });

                // });

            }
            
        } else {
            if (this.props.viewProjectdetail.message == "Server encountered error") {
                this.setState({
                    error: true
                })
            }
            this.setState({ loader: false })
        }
    }

    onSuggestionsFetchRequested = async ({ value }) => {

        await this.props.fetchAllLanguageSuggestions();
        var all_languages = [];
        if (this.props.total_languages_suggestions.success) {

            all_languages = this.props.total_languages_suggestions.languages;
        }


        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            all_languages = [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');

        this.setState({
            total_languages_suggestions: all_languages.filter(p => regex.test(p.language) && p.translatorId !== null)
        });
    };

    timeSince = (date) => {

        date = new Date(date);

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            total_languages_suggestions: []
        });
    };

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestionValue = (suggestion) => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors['languages'] = ''
        this.setState({ errors })
        if (suggestion.language < 1) return '';

        if (suggestion.language === "English") {
            errors['languages'] = `English is already Present`
            this.setState({ errors })
            return '';
        }

        if (suggestion.language.trim().length < 1) return '';
        let languageFound = fields['languages'].find(t => typeof t.language === 'object' ? t.language.language.toLowerCase() === suggestion.language.toLowerCase() : t.language.toLowerCase() === suggestion.language.toLowerCase())
        if (languageFound) {
            errors['languages'] = `Can't add duplicate languages`
            this.setState({ errors })
            return '';
        }
        fields['languages'].push(suggestion);
        errors['languages'] = ''
        const translateStatus = new Object();
        translateStatus.language = suggestion.language;
        translateStatus.languageId = suggestion._id
        translateStatus.status = false;
        fields['each_translation_status'].push(translateStatus)
        this.setState({fields, errors, disable: false});
        
        return '';
    }

    renderSuggestion = (suggestion, { query }) => {
        return (
            <Media className="align-items-center justify-content-between">
                <span>
                    <i class="far fa-check-circle"></i> {suggestion.language}
                </span>
                <Media.Body>
                    <div className="projectTitle d-flex align-items-center">
                        <span className="projectIcon mr-3">
                            <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/translator_profile/` + `${suggestion.translatorId.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}` + `/translator_profile/` + `${suggestion.translatorId.profile}` : require("assets/img/default-avatar.png")}
                                onError={(e) => {
                                    {
                                        e.target.onerror = null;
                                        e.target.src = require("assets/img/default-avatar.png");
                                    }
                                }}
                                alt="Profile" />
                            <Badge variant="success"></Badge>
                        </span>
                        <h3>{suggestion.translatorId.fullname} <small className="d-block text-gray font-italic">{suggestion.translatorId.country}</small></h3>
                    </div>
                </Media.Body>
            </Media>
        )
    }

    ImageExist = (url) => {
        var img = new Image();
        img.src = url;

        if (img.src.indexOf('null') == -1) {

            return true;
        }
        return false;
    }

    onHandleProjectChange = (event, { newValue, method }) => {
        let fields = this.state.fields
        fields['language_suggestion'] = newValue
        this.setState({ fields })
    };

    handleRemoveLanguageOnClick = (tag) => {
        let fields = this.state.fields;
        let languages = fields['languages'];
        let eachTranslationStatus = fields['each_translation_status']
        languages = languages.filter(t => t.language.language !== tag.language.language)
        eachTranslationStatus = eachTranslationStatus.filter(t => t.language !== tag.language.language)
        if(languages.length < 1){
            let errors = this.state.errors
            errors['languages'] = 'At least enter one languages'
            this.setState({ errors, disable: true })
        }
        fields['languages'] = languages
        fields['each_translation_status'] = eachTranslationStatus
        this.setState({ fields })
    }

    subDays = (days) => {
        var d = new Date();
        return d;
    }

    handleCheckbox = (e) => {
        let fields = this.state.fields
        const value = e.target.value;
        if (e.target.checked) {

            fields['translation_tasks'].push(value);
        } else {
            if (fields['translation_tasks'].indexOf(value) != -1) {
                fields['translation_tasks'] = fields['translation_tasks'].filter(item => item !== value)
            }
        }
        this.setState({ fields })
    }

    checkedBoxChecking = (value) => {
        let fields = this.state.fields;
        var checked = false;
        if (fields['translation_tasks'].indexOf(value) != -1) {
            checked = true;
        }
        return checked;

    }

    handleOnChange = (e, parameter) => {
        let fields = this.state.fields
        let errors = this.state.errors
        errors[parameter] = ''
        fields[parameter] = e.target.value;
        this.setState({ fields, errors })
    }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;

        if (!fields["projectname"]) {
            formIsValid = false;
            errors["projectname"] = " Project is required";
        }

        if (!fields["due_date"]) {
            formIsValid = false;
            errors["due_date"] = "Due Date is required";
        }

        if (fields["translation_tasks"].length == 0) {
            formIsValid = false;
            errors["translation_tasks"] = "At least One Select Translation task";
        }

        if (fields["languages"].length == 0) {
            formIsValid = false;
            errors["languages"] = "At least enter one language";
        }



        this.setState({ errors: errors });
        return formIsValid;
    }

    handleOnSubmit = async (e) => {
        e.preventDefault();
        const check = ['undefined', undefined, 'null', null, 0, '0', '']
        const { comment, fields } = this.state;
        if (this.handleValidation()) {
            this.setState({ disable: true })

            if (!check.includes(comment)) {
                const user = new Object();
                user.userDetail = decodedToken;
                user.activity = this.state.comment;
                user.createdAt = moment().format()
                fields['activities'].push(user)
                this.setState({ fields })
            }

            let data ={
                ...this.state.fields,
                script: this.state.fields['scriptFile'],
            }
            await this.props.updateProject(this.state.projectId, data);
            if (this.props.projectUpdated.success) {
                this.setState({ disable: false })
                this.setState({ comment: '' })
                swal("Good job!", this.props.projectUpdated.message, "success")
                    .then(async (willDelete) => {
                        if (willDelete) {
                        }
                    });
            } else {
                this.setState({ disable: false })
                toast.dismiss()
                toast.error(this.props.projectUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }
        }
    }


    ImageExist = (url) => {
        var img = new Image();
        img.src = url;

        if (img.src.indexOf('null') == -1) {

            return true;
        }
        return false;
    }


    handleComments = (e) => {

        const { value } = e.target;

        this.setState({comment : value})


    }

    render(){
        const { total_languages_suggestions, errors, disable, fields, loader,error } = this.state
        const inputProps = {
            placeholder: `Enter Language To Translate`,
            value: this.state.fields['language_suggestion'],
            onChange: this.onHandleProjectChange
        };
        if (!error) {

            return (
                <>
                    <Container fluid className="p-sm-0">
                        <Row className="mb-4">
                            <Col sm={12} className="p-0">
                                <div className="headTag d-flex align-items-center">
                                    <h3>Projects <span>Project Panel Overview</span></h3>
                                    <Button variant="primary" className="ml-4 border-0 p-0" onClick={() => this.props.history.push('/add-projects')}><span><i class="fas fa-plus-square"></i></span>New Project</Button>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <Card className="projectTable border-0 rounded-0">
                                    <Card.Body>
                                        <Row>
                                            <Col md={6}>

                                                <h5 className="mt-0">Project Details</h5>
                                                <Form className="form-detail" onSubmit={this.handleOnSubmit}>
                                                    <Form.Group>
                                                        <Form.Control type="text" placeholder="Project Name" onChange={(e) => this.handleOnChange(e, 'projectname')} value={this.state.fields['projectname']} />
                                                        <span style={{ color: "red", fontSize: '12px' }}>{this.state.errors["projectname"]}</span>
                                                    </Form.Group>
                                                    <span style={{ color: "red", fontSize: '12px' }}>{this.state.errors["translation_tasks"]}</span>
                                                    <Form.Group className="autosuggest-input">
                                                        {/* <Form.Label><i class="fas fa-plus"></i> Add Language</Form.Label>   */}
                                                        <Autosuggest
                                                            suggestions={total_languages_suggestions}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                            getSuggestionValue={this.getSuggestionValue}
                                                            renderSuggestion={this.renderSuggestion}
                                                            inputProps={inputProps}
                                                        />
                                                    </Form.Group>
                                                    <small style={{ color: '#db1313' }}> {errors['languages'] || ''} </small>
                                                    <Form.Label>Languages</Form.Label>
                                                    {fields['languages'].length > 0 &&
                                                        fields['languages'].map((t, i) => (

                                                            <Media className="align-items-center justify-content-between" key={i}>
                                                                <span>
                                                                    <i class="far fa-check-circle"></i> {typeof t.language === 'object' ? t.language.language : t.language}
                                                                </span>
                                                                <Media.Body>
                                                                    <div className="projectTitle add-language d-flex align-items-center justify-content-end">

                                                                        <span className="projectIcon mr-3">
                                                                            <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/translator_profile/` + `${t.translatorId.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}` + `/translator_profile/` + `${t.translatorId.profile}` : require("assets/img/default-avatar.png")}
                                                                                onError={(e) => {
                                                                                    {
                                                                                        e.target.onerror = null;
                                                                                        e.target.src = require("assets/img/default-avatar.png");
                                                                                    }
                                                                                }}

                                                                                alt="Profile"
                                                                            />
                                                                            <Badge variant={t.translatorId.online_status ? "success" : "secondary"}></Badge>
                                                                        </span>
                                                                        <h3>{t.translatorId.fullname} <small className="d-block text-gray font-italic">{t.translatorId.country}</small></h3>
                                                                        <span style={{ cursor: 'pointer' }} className="fa fa-times ml-3" onClick={() => this.handleRemoveLanguageOnClick(t)}></span>
                                                                    </div>
                                                                </Media.Body>
                                                            </Media>
                                                        ))}
                                                    <Form.Group className="mt-3">
                                                        <Form.Group>
                                                            <Form.Label> Add Comments</Form.Label>
                                                            <Form.Control as="textarea" rows={5} placeholder="Add a comment or post an update…" onChange={(e) => this.handleComments(e)} value={this.state.comment} />
                                                        </Form.Group>
                                                    </Form.Group>
                                                    <Form.Group className="update-btn-sec">
                                                        {this.state.fields['projectname'] && this.state.fields['due_date'] && this.state.fields['translation_tasks'].length && this.state.fields['languages'].length > 0 ?
                                                            <>
                                                                <Button style={{ backgroundColor: '#C93D3A', border: 'none', color: 'white' }} type="submit" disabled={this.state.disable}>
                                                                    {this.state.disable ? 'Wait...' : 'Update'}
                                                                </Button>
                                                            </>
                                                            :
                                                            <>
                                                                <Button style={{ backgroundColor: '#C93D3A', border: 'none', color: 'white' }} type="submit" disabled>Update
                                                                </Button>
                                                            </>
                                                        }
                                                        <Button onClick={() => this.props.history.push(`/edit-projects-script/${this.state.projectId}`)} style={{ backgroundColor: '#C93D3A', border: 'none', color: 'white' }} type="submit">
                                                            Click to Edit Script
                                                        </Button>
                                                    </Form.Group>
                                                </Form>
                                            </Col>

                                            <Col sm={6} className='comment-secion-main'>
                                                {/* <div> */}
                                                <ScrollToBottom className="bottam_scroll">
                                                    <h5 className="mb-4"> Activities </h5>
                                                    {fields.activities.length > 0 ?
                                                        <div className="align-items-center justify-content-between mb-3" >

                                                            <div>
                                                                {fields['comments'] ?
                                                                    <>
                                                                        <div className="projectTitle align-items-center d-flex mt-3">
                                                                            { fields.activities[0].userDetail
                                                                              ?
                                                                              <>
                                                                                <span className="projectIcon-se-wraper">
                                                                                   
                                                                                    <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0]?.userDetail?.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0]?.userDetail?.profile}` : require("assets/img/default-avatar.png")} />    
                                                                                
                                                                                </span>
                                                                                <strong className="main-comment-text text-dark">
                                                                                    {fields.activities[0].userDetail.fullname}
                                                                                </strong>
                                                                                <p className="comment-main-text-edit-project m-0">
                                                                                    {fields['comments']}
                                                                                </p>
                                                                                <h6 className="heading-text">{this.timeSince(fields.activities[0].createdAt)} Ago</h6>
                                                                            </>
                                                                          : 

                                                                            <>
                                                                                <span className="projectIcon-se-wraper">
                                                                                  
                                                                                    <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields?.activities[0]?.user?.profile? fields?.activities[0]?.user?.profile : null}`) ? `${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0].user.profile}` : require("assets/img/default-avatar.png")} />    
                                                                                
                                                                                </span>
                                                                                <strong className="main-comment-text text-dark">
                                                                                    {typeof fields.activities[0].user === 'object' ? fields?.activities[0]?.user?.fullname : fields?.activities[0]?.user?.fullname}
                                                                                </strong>
                                                                                <p className="comment-main-text-edit-project m-0">
                                                                                    {fields['comments']}
                                                                                </p>
                                                                                <h6 className="heading-text">{typeof fields.activities[0] === 'object' ? fields.activities[0].createdAt : fields.activities[0].createdAt} Ago</h6>
                                                                               
                                                                            </>

                                                                            
                                                                            }
                                                                            {/* <span className="projectIcon-se-wraper">
                                                                                {this.ImageExist(`${process.env.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0].userDetail.profile}`) ? <img src={`${process.env.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0].userDetail.profile}`} alt="..." /> : <img src={require("assets/img/user-icon.png").default} alt="..." />
                                                                                    }   
                                                                                <img src={this.ImageExist(`${process.env.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0]?.userDetail?.profile}`) ? `${process.env.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0]?.userDetail?.profile}` : require("assets/img/default-avatar.png")} />    
                                                                                
                                                                            </span>
                                                                            <strong className="main-comment-text text-dark">
                                                                                {fields.activities[0].userDetail.fullname}
                                                                            </strong>
                                                                            <p className="comment-main-text-edit-project m-0">
                                                                                {fields['comments']}
                                                                            </p>
                                                                            <h6 className="heading-text">{this.timeSince(fields.activities[0].createdAt)} Ago</h6> */}
                                                                        </div>

                                                                    </>
                                                                : null

                                                                }
                                                            </div>

                                                        </div>
                                                    :
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <div className="projectTitle d-flex align-items-center">
                                                                <p className="m-0">No Activity Present </p>
                                                            </div>

                                                        </div>

                                                    }
                                                    {fields.activities.length > 0 ?
                                                        fields['activities']?.map((item, i) => {
                                                            return (

                                                                <>
                                                                    <ul>

                                                                        <li className="main-comment-list-wraper">
                                                                            <span className="projectIcon-main-wraper mr-3">
                                                                            
                                                                                <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${item.user ? item?.user?.profile : item?.userDetail?.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${item.user ? item?.user?.profile : item?.userDetail?.profile}` : require("assets/img/default-avatar.png")} />

                                                                                <strong className="text-dark main-comment-text">{item.user ? item?.user?.fullname : item?.userDetail?.fullname}</strong>
                                                                                <p className="comment-main-text-edit-project">{item.activity}</p>

                                                                                <h6 className="heading-text">{ item.user ?  item?.createdAt :  this.timeSince(item?.createdAt)} Ago</h6>
                                                                            </span>
                                                                        </li>

                                                                    </ul>
                                                                            
                                                                </>
                                                            )
                                                        })
                                                        
                                                    : null

                                                        
                                                    }
                                                </ScrollToBottom>

                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </>
            )
        } else {
            return (
                <NotFound />
            )
        }
    }   
}


const mapStateToProps = state => {
    const { project } = state
    return {
        total_languages_suggestions: project.all_language_suggestions,
        viewProjectdetail: project.viewProject,
        projectUpdated: project.updateProjectApi,
        total_users: project.all_users,

    }
}
const dispatchAction = { fetchAllLanguageSuggestions, updateProject, viewProject, fetchAllUsers }
export default connect(mapStateToProps, dispatchAction)(EditProject);