import React, { Component }from 'react';
import { Navbar, Form, Container, Nav, Dropdown, Button, Alert, Row, Col , Badge, Card, Table } from "react-bootstrap";
import { socket } from '../../actions/fetchActions';
import {connect} from 'react-redux';
import Loader from 'react-loader-spinner';
import { fetchNotifications } from '../../actions/notificationAction';
import ReactNotifications from 'react-browser-notifications';
import ReactPaginate from 'react-paginate';
import { isLoggedIn, decodedToken, isTranslatorLoggedIn, decodedTranslatorToken } from '../../helpers/decode-token';



class NotificationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page:1,
            errors: {},
            per_page:10,
            total_pages:null,
            loader:false,
            searchInput:'',        
            notifications: [],
            isOpen: false,
            data:{}
        };
    }

    componentDidMount(){
        socket.emit('JoinRecieveNotification', {userId: decodedTranslatorToken.userId}) ;
        this.getNotify();
        this.getNotifications(this.state.page, this.state.per_page, this.state.searchInput);
        this.onEventClearNotifications()
        this.onEventReadNotifications()

    }

    getNotifications = async ( page, per_page, search ) => {
        this.setState({ loader: true })
        await this.props.fetchNotifications(decodedTranslatorToken.userId, page, per_page, search )

        if(this.props.notifications.response){

            this.setState({
                notifications: this.props.notifications.notifications,
                total_pages: this.props.notifications.meta.total_pages,
                loader: false
            })
        }
    }

    getNotify = () => {
        socket.on('onNotify', data => {
          
            this.setState({
              isOpen: true,
              data:data
            }, () => {
              this.getNotifications(this.state.page, this.state.per_page, this.state.searchInput);
              this.n.show();
            })
        })
    }

    setCloseToast = (event) => {
      window.focus()
      this.n.close(event.target.tag);
      this.setState({
        isOpen: false,
        data:{},
      })

    }

    ReadNotifications = () => {
      socket.emit('read-notifications', { user: decodedTranslatorToken.userId, notifications: this.state.notifications.map(n => n._id ) })
    }

    ClearNotifications = () => {
      socket.emit('clear-notifications', { user: decodedTranslatorToken.userId, notifications: this.state.notifications.map(n => n._id ) })
    }

    onReadSingleNotification = (notification) => {
      if(notification && notification._id){
        socket.emit('read-notifications', { user: decodedTranslatorToken.userId, notifications: notification._id.split() })
      }
    }

    onDeleteSingleNotification = (notification) => {
      if(notification && notification._id){
        socket.emit('clear-notifications', { user: decodedTranslatorToken.userId, notifications: notification._id.split() })
      }
    }

    onEventClearNotifications = () => {
      socket.on('onClear', data => {
        if(data && data.response){
          this.getNotifications(this.state.page, this.state.per_page, this.state.searchInput)
        }
      })
    }

    

    onEventReadNotifications = () => {
      socket.on('onRead', data => {
        if(data && data.response){
          this.getNotifications(this.state.page, this.state.per_page, this.state.searchInput)
        }
      })
    }

    convertDate = (date) => {
      var d = new Date(date);
      var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
      var monthName = d.toLocaleString('default', { month: 'short' })
      return  (monthName) + " " + d.getDate() +  year
    }

    convertTime = (date) => {
      var d = new Date(date);
      return  d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    }


    render(){
        const { sidebarOpen, data, isOpen, notifications, loader, total_pages   } = this.state

        const paginate = e => {   
            this.setState({
                page:e.selected + 1
            }, ()=> {
                this.getNotifications( this.state.page, this.state.per_page, this.state.searchInput )
            })
        };
         return(
             <>
            <Container fluid className="p-sm-0 mt-4">
                <Row className="mb-4">
                  <Col sm={12} className="p-0">
                    <div className="headTag d-flex align-items-center">
                      <h3>Notification <span>Translator User Overview</span></h3>
                      
                    </div>
                  </Col>
                  { loader ?
                    <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" > 
                        <Loader className="align-self-center" type="Audio" color="#000" height={30} width={30} />
                    </div>
                    :   
                      notifications.length > 0 ?
                        <>
                          <Col sm={12}>
                            <Card className="projectTable usersTable border-0 rounded-0 p-2">
                              <Card.Body className="table-responsive">
                              <ul class="list-group">

                                {notifications.map(( notify, i ) => {
                                    return (
                                      <li class="list-group-item d-flex justify-content-between align-items-center" key ={i}>
                                        
                                        {notify.message}
                                        <span>
                                          <small> 
                                            { this.convertTime(notify.time)} | {this.convertDate(notify.time)} 
                                          </small>
                                          <small style={{display:'block'}}>
                                              <span style={{display: 'inline', cursor:'pointer'}} className="mr-1" onClick={() => !notify.read ? this.onReadSingleNotification(notify) : null}> 
                                                <i className={`${notify.read ? 'fa fa-check-circle' : 'fa fa-eye'} mr-1`}/> 
                                                  {notify.read ? 'Marked as read' : 'Mark as read'}
                                              </span>
                                              <span style={{display: 'inline', cursor:'pointer'}} onClick={() => this.onDeleteSingleNotification(notify)}> 
                                                <i className="fa fa-times mr-1"/> 
                                                Opt out
                                              </span>
                                          </small>
                                        </span> 

                                      </li>
                                      
                                      )
                                })}                
                              </ul>
                              </Card.Body>
                            </Card>
                          </Col>
                            
                        </>
                        :
                        <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" >
                          <p style={{textTransform:'uppercase', textAlign:'center'}}>
                              Sorry, No Notifications to show
                          </p>
                        </div>
                }
                { (notifications.length > 0 && total_pages > 1 ) &&
                  <section className="pagination col-sm-12 col-sm-offset-5" style={{ textAlign:'right' }}>
                      <ReactPaginate
                          previousLabel={'<<'}
                          nextLabel={'>>'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={total_pages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={paginate}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                      />
                  </section>}
                    
                </Row>
              </Container>

             </>

         )

    }


}


const mapStateToProps = state => {
    return {
        notifications: state.notifications.notifications
    }
  }
  
  const dispatchState = { 
    fetchNotifications 
  }
  export default connect(mapStateToProps, dispatchState)(NotificationList)


