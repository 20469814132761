import { combineReducers } from "redux";
import translatorReducers from "./translatorReducers";
import adminReducers from "./adminReducers";
import projectReducers from "./projectReducers";
import dashboardReducers from "./dashboardReducers";
import notificationReducers from "./notificationReducers";
import chatReducers from "./chatReducers";

export default combineReducers({
    translator: translatorReducers,
    admin: adminReducers,
    project: projectReducers,
    dashboard: dashboardReducers,
    notifications: notificationReducers,
    messages:chatReducers,
})