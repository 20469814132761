import React, { Component } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { adminLogin, resetPassword, socialLogin } from '../../actions/adminActions';
import { translatorLogin  , viewTranslator , updateTranslator  } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { isLoggedIn, isTranslatorLoggedIn } from '../../helpers/decode-token';
import  jwt_decode  from 'jwt-decode';
// import { Modal } from "react-responsive-modal";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig  } from "../../authConfig";
import { Modal , Form } from 'react-bootstrap';
import { listCountries} from '../../actions/translatorActions';
import Autosuggest from 'react-autosuggest';



const atob = require('atob');
class Login extends Component{
    constructor(props){
        super(props);

        this.state = {
            fields: {
                languages : [] ,
                country : '' , 
                language_suggestion : '' ,
                intialLogin : '',
                translatorId : ''
            },
            errors: {},
            remember:false,
            disable: false,
            disable_translator: false,
            resetPassword: false,
            user_type:null,
            flag :  1,
            showModel : false ,
            countries:{},
            total_languages_suggestions : [] , 
            disable : false 
          
        }
        this.PublicClientApplication = new PublicClientApplication(msalConfig);
    }

    componentDidMount(){
        this.PublicClientApplication.handleRedirectPromise().then(async (tokenResponse) => {
            console.log("tokenResponse" , tokenResponse)
            if(tokenResponse !== null){
                const DecodeToken = jwt_decode(tokenResponse.idToken)
                const name = tokenResponse.account.idTokenClaims.preferred_username.split('.')
                var obj= {
                    email: DecodeToken.email ,
                    fullname: DecodeToken.preferred_username,
                    uniqueId: DecodeToken.aud,
                    signupType:'Azure',
                    role : this.checkRole(DecodeToken.roles[0])
                }
                console.log("obj" , obj);
                await this.props.socialLogin(obj);
                if(this?.props?.admin && this?.props?.admin.success){
                    toast.dismiss()
                    toast.success(this.props.admin.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
                }else{
                    toast.dismiss()
                    toast.error(this.props.admin.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
                }
            }
        }).catch((error) => {
            console.log("error" , error)
            toast.dismiss()
            toast.error('Oops, Please try again later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
        });
    }



    checkRole = (role) =>{
        console.log("role" , role)
        const check = ['translator' , 'admin']
        if(!check.includes(role)){
            return 'translator'
        }else{
            return role 
        }

    }


    ssoLogin = async () =>{
        try {
            await this.PublicClientApplication
            .loginRedirect({
                ...msalConfig.auth, 
                scopes:["user.read"],
            })
        } catch (err) {
            toast.dismiss()
            toast.error('Please try again, later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
        }
    }

    render(){
            const {showModel , countries , total_languages_suggestions} = this.state ; 
            const inputProps = {
                placeholder: `Add Language`,
                value: this.state.fields['language_suggestion'],
                onChange: this.onHandleProjectChange
            };
            return(
                <Container className="h-100">
                    <Row className="justify-content-md-center align-items-center h-100">
                        <Col sm={7}>
                            <div className="bg-light p-4 shadow-lg text-center">
                                <div className="logo-box d-flex align-items-center justify-content-center mb-4">
                                    <img className="loginLogo" src="image/cv-black.png" width="80" height="50"/>
                                    <span className="loginLogoText" style={{ textTransform:'uppercase' }}>Global</span>
                                </div>
                                <Button onClick={()=> this.ssoLogin()}>
                                    <span className="mr-3">
                                        <img src="image/microsoft.png" width="30" height="30" />
                                    </span>
                                    Login in with Microsoft
                                </Button>   
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
    }
}

const mapStateToProps = state => {
    
    const { admin, translator } = state
    return { 
        admin: admin.admin, 
        translator: translator.translator ,
        countries: translator.countries.data,
        total_languages_suggestions: translator.countries.languages,
        viewTranslatordetail : translator.viewTranslator,
        translatorUpdated : translator.translatorUpdated,
    }
}


const dispatchState = { adminLogin, resetPassword, translatorLogin, socialLogin , listCountries , viewTranslator , updateTranslator}

export default connect(mapStateToProps, dispatchState)(Login);  