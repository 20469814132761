
import appConfig from 'appConfig';

const Environment = {
    googlePickerClientId : appConfig.REACT_APP_GOOGLE_PICKER_CLIENT_KEY,
    googlePickerApiKey : appConfig.REACT_APP_GOOGLE_PICKER_DEV_KEY,
    slackWebHookLink : appConfig.REACT_APP_SLACK_WEBHOOK_LINK
}


export const ENV = Environment;