import React, { Component } from "react";
import { Button, Card, Container, Row, Col, Form, Media, Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import { fetchAllLanguageSuggestions, viewProjectEmail, fetchAllUsersEmail,} from '../../actions/projectActions';
import { updateProjects } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { toast } from'react-toastify';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "excel2json-wasm";
import { decodedTranslatorToken } from '../../helpers/decode-token';
import ReactHtmlParser from 'react-html-parser';

import * as check from '../../helpers/validation';
import { FormGroup } from "rsuite";
import appConfig from "appConfig";

const incrementString = (value) => {
    let carry = 1;
    let res = '';
  
    for (let i = value.length - 1; i >= 0; i--) {
      let char = value.toUpperCase().charCodeAt(i);
  
      char += carry;
  
      if (char > 90) {
        char = 65;
        carry = 1;
      } else {
        carry = 0;
      }
  
      res = String.fromCharCode(char) + res;
  
      if (!carry) {
        res = value.substring(0, i) + res;
        break;
      }
    }
  
    if (carry) {
      res = 'A' + res;
    }
  
    return res;
  }

class TranslatorProjectDetail extends Component{

    constructor(props){
        super(props)

        this.state = {
            projectId:props.match.params.projectId,
            showMessage:'',
            errors: {},
            disable: false,
            total_languages_suggestions:[],
            fields: {
                projectname:'',
                due_date: '',
                translation_tasks:[],
                language_suggestion:'',
                languages:[],
                activities:[],
                // spread_sheet:{},
                comments:'',
                edit_translator:'translator',
            },

            comment : '' ,
           
            // spreadsheet: {},
            loader:true,
        }

        this.initialState = JSON.parse(JSON.stringify(this.state));
    }

    componentDidMount(){
        this.getProjectDetails(this.state.projectId);
        // this.getScriptContent(this.state.projectId);
    }
    getProjectDetails = async (projectId)=>{

        let fields = this.state.fields;
        this.setState({loader:true})
        await this.props.viewProjectEmail(projectId)
        if(this.props.viewProjectdetail.success){
            fields['projectname'] = this.props.viewProjectdetail.data.projectname;
            fields['due_date'] = this.props.viewProjectdetail.data.due_date;
            fields['translation_tasks'] = this.props.viewProjectdetail.data.translation_tasks;
            fields['languages'] = this.props.viewProjectdetail.project_languages.filter(p => p.translatorId._id === decodedTranslatorToken.userId);
            fields['script'] = this.props.viewProjectdetail.data.script;
            fields['comments'] = (this.props.viewProjectdetail.data.comments);
            fields['activities'] = this.props.viewProjectdetail.data.activities;
            await this.props.fetchAllUsersEmail();
            if(this.props.total_users.response){
                let fields = this.state.fields;
                var total_users = this.props.total_users.users;
                fields['activities'].forEach((element, index) => {
                    total_users.forEach((element1, index1) => {

                        if(element.userId == element1._id){
                            element.user = element1;

                            element.createdAt = this.timeSince(this.props.viewProjectdetail.data.createdAt);
                            return; 
                        } 
                    });
                    
                });
            }

                // fields['spread_sheet'] = this.props.viewProjectdetail.data.spread_sheet;
            this.setState({
                fields,
                loader:false,
            },

            //  () => {
            //     this.spreadSheetChange();
            // }
            
            )
        }else{
            this.setState({loader:false})
        }
    }

    // getScriptContent =async (projectId) => {
    //     let fields = this.state.fields;
    //     await this.props.singleScript(projectId)
    //     console.log('this.props.scriptData.success',this.props.scriptDataSingle.success)
    //     if(this.props.scriptDataSingle.success){
    //       fields['projectname'] = this.props.scriptDataSingle.data.projectname;
    //       fields['due_date'] = new Date(this.props.scriptDataSingle.data.due_date);
    //       fields['translation_tasks'] = this.props.scriptDataSingle.data.translation_tasks;
    //       fields['languages'] = (this.props.scriptDataSingle.data.languages);
    //       fields['comments'] = (this.props.scriptDataSingle.data.comments);
    //       fields['activities'] = this.props.scriptDataSingle.data.activities;
    //       fields['scriptFile'] = this.props.scriptDataSingle.data.script;
    //       this.setState({fields});
    //     }else{
    //         toast.dismiss()
    //         toast.error( this.props.scriptDataSingle.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    //     }
    // }

    // spreadSheetChange = () => {
    //     const spreadsheet = new dhx.Spreadsheet("spreadsheet",{
    //         exportModulePath:
    //             "https://cdn.dhtmlx.com/libs/json2excel/1.0/worker.js",
    //         importModulePath:
    //             "https://cdn.dhtmlx.com/libs/excel2json/1.0/worker.js",
    //         menu: true,
    //         multiSheets: false,
    //     });


    //     this.setState({ spreadsheet })        
    //     spreadsheet.parse(this.state.fields['spread_sheet']);

    //     spreadsheet.lock("A1:Z1");
    //     spreadsheet.lock("A1:A200");
    //     spreadsheet.setStyle("A1:Z1",{background:"black",color:"white"});

    //     setInterval(function(){
            
    //         if(!Array.isArray(spreadsheet.serialize())){  
    //             var state1 = spreadsheet.serialize();
    //             let fields = this.state.fields;
    //             fields['spread_sheet'] = state1;
    //             this.setState({ fields });
    //         }
    //         var send_languages = this.state.fields['languages'].map(x => typeof x.language === 'object' ? x.language.language : x.language);
    //         var state2 = spreadsheet.serialize();

            
    //         var state_man = state2.sheets[0].data;
    //         var pos = 0;
    //         if(state_man.length == 0 && send_languages.length > 0 ){
    //             spreadsheet.unlock("B1");
    //             spreadsheet.setValue("B1",send_languages[pos]);
    //             spreadsheet.lock("B1");

    //         }else{
    //             state_man.forEach(function(entry,i) {
    //                 var len_cell = Array.from(entry.cell);
    //                 if(entry.cell.includes("1") && len_cell.length == 2){
    //                     var location = entry.cell.charAt(0);
    //                     var location2 = incrementString(location);
    //                     spreadsheet.unlock(location2+"1");
    //                     spreadsheet.setValue(location2+"1",send_languages[pos]);
    //                     spreadsheet.lock(location2+"1");
    //                 }
    //                 pos++;
                    
    //             });
    //         } 
    //     }.bind(this), 1000);
    //     spreadsheet.events.on("afterValueChange", (cell, value) => {
    //         let fields = this.state.fields;
    //         fields['spread_sheet'] = spreadsheet.serialize();

    //         this.setState({ fields });
    //     });

        
    // }

    onSuggestionsFetchRequested = async ({ value }) => {

        await this.props.fetchAllLanguageSuggestions();
        var all_languages = [];
        if(this.props.total_languages_suggestions.success){

            all_languages = this.props.total_languages_suggestions.languages;
        }

        const escapedValue = this.escapeRegexCharacters(value.trim());
    
        if (escapedValue === '') {
            all_languages = [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');
        this.setState({
            total_languages_suggestions: all_languages.filter(p => regex.test(p.language) && p.translatorId !== null)
        });
    };

   

    timeSince = (date) => {

        date = new Date(date);

        var seconds = Math.floor((new Date() - date) / 1000);
      
        var interval = seconds / 31536000;
      
        if (interval > 1) {
          return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
          return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            total_languages_suggestions: []
        });
    };

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestionValue = (suggestion) => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors['languages'] = ''
        this.setState({ errors })
        if (suggestion.language < 1) return '';

        if (suggestion.language === "English"){
            errors['languages'] = `English is already Present`
            this.setState({ errors })
            return '';
        }

        if(suggestion.language.trim().length < 1) return '';

            let languageFound = fields['languages'].find(t => typeof t.language === 'object' ? t.language.language.toLowerCase() === suggestion.language.toLowerCase() : t.language.toLowerCase() === suggestion.language.toLowerCase())
            if(languageFound){
                errors['languages'] = `Can't add duplicate languages`
                this.setState({ errors })
                return '';
            }

            fields['languages'].push(suggestion);
            errors['languages'] = ''
            this.setState({fields, errors, disable: false});
        
        return '';
    }

    renderSuggestion = (suggestion, { query }) => {
        return (
            <Media className="align-items-center justify-content-between">
                <span>
                    <i class="far fa-check-circle"></i> {suggestion.language}
                </span>
                <Media.Body>
                    <div className="projectTitle d-flex align-items-center">
                        <span className="projectIcon mr-3">
                            <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${suggestion.translatorId.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${suggestion.translatorId.profile}`  : require("assets/img/user-icon.png")}  alt="..." />
                            <Badge variant="success"></Badge>
                        </span>
                        <h3>{suggestion.translatorId.fullname} <small className="d-block text-gray font-italic">{suggestion.translatorId.country}</small></h3>
                    </div>
                </Media.Body>
            </Media>
        )
    }

    ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;
      
      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

    onHandleProjectChange = (event, { newValue, method }) => {
        let fields = this.state.fields
        fields['language_suggestion'] = newValue
        this.setState({ fields })
    };

    handleRemoveLanguageOnClick = (tag) => {
        let fields = this.state.fields;
        let languages = fields['languages'];
        languages = languages.filter(t => t.language.language !== tag.language.language)
        if(languages.length < 1){
            let errors = this.state.errors
            errors['languages'] = 'At least enter one languages'
            this.setState({ errors, disable: true })
        }
        fields['languages'] = languages
        this.setState({fields})
    }

    subDays = (days) => {
        var d = new Date();
        return d;
    }

    handleCheckbox = (e) => {
        let fields = this.state.fields
        const value = e.target.value;
        if(e.target.checked){

            fields['translation_tasks'].push(value);
        }else{
            if(fields['translation_tasks'].indexOf(value) != -1){
                fields['translation_tasks'] = fields['translation_tasks'].filter(item => item !== value)
            }
        }
       
        this.setState({ fields })
    }

    checkedBoxChecking = (value) => {
        let fields = this.state.fields;
        var checked = false;
        if(fields['translation_tasks'].indexOf(value) != -1){
            checked = true;
        }
        return checked;
        
    }

    handleOnChange = (e, parameter) => {
        let fields = this.state.fields
        let errors = this.state.errors
        errors[parameter] = ''
        fields[parameter] = e.target.value;
        this.setState({ fields, errors })
    }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        
        if(!fields["projectname"]){
            formIsValid = false;
            errors["projectname"] = " Project is required";
        }    
        
        if(!fields["due_date"]){
            formIsValid = false;
            errors["due_date"] = "Due Date is required";
        }
             

        if(fields["translation_tasks"].length == 0){
            formIsValid = false;
            errors["translation_tasks"] = "At least One Select Translation task";
        }

        if(fields["languages"].length == 0){
            formIsValid = false;
            errors["languages"] = "At least enter one language";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleOnSubmit = async (e) => {
        e.preventDefault();
        const check = ['undefined', undefined, 'null', null, 0, '0', '']
        const {comment , fields } = this.state ; 
        if(this.handleValidation()){
            this.setState({ disable: true })
            if( !check.includes(comment) ) {
                // const userComments = fields['activities'][1]
                // if(check.includes(userComments)){
                //   const comments = [];
                //   fields['activities'].push({userComments:comments})
               
                //   fields['activities'][1].userComments.push({userDetail : decodedTranslatorToken , comment : this.state.comment , createdAt : moment().format()})
                // }else{
                //     fields['activities'][1].userComments.push({userDetail : decodedTranslatorToken , comment : this.state.comment , createdAt : moment().format() })
                // }
                const user = new Object();
                user.userDetail = decodedTranslatorToken;
                user.activity = this.state.comment;
                user.createdAt = moment().format()
                fields['activities'].push(user)
                this.setState({fields})
            }



            // const comments = [];
            // fields['activities'].push({comment:comments})

            // console.log("activity", fields['activities'] )
            let data ={
                ...this.state.fields,
                script: this.state.fields['scriptFile'],
            }
            await this.props.updateProjects(this.state.projectId,data);
           
            if(this.props.updateProject.success){
                this.setState({ disable: false })
                this.setState({ comment : ''})
                swal("Good job!", this.props.updateProject.message, "success")
                .then(async (willDelete) => {
                    if (willDelete) {
                    }
                });
            }else{
                this.setState({ disable: false })
                toast.dismiss()
                toast.error( this.props.updateProject.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }
        }
    }

    // exportXlsx  = () => {
    //     var arr = this.state.spreadsheet.serialize();
    //     if(!Array.isArray(arr)){
    //         this.state.spreadsheet.export.xlsx();
    //     }
    //     return false;
        

    // }


    // importXlsx  = () => {
    //     this.state.spreadsheet.load("", "xlsx");
    // }

    editFullScreen = async() => {
        this.props.history.push(`/translator-script/${this.state.projectId}`)
        // await this.props.updateTranslatorProject(this.state.fields,this.state.projectId);
        // if(this.props.projectUpdated.success){
        // }else{
        //     toast.dismiss()
        //     toast.error( this.props.projectUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        // }
    }



    ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;

      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

    convertDueDate = ( TZdate ) =>{
        var date = TZdate.replace(/["]/g, '')
        date = moment(date).format('MMMM Do YYYY')
        return(date) 
    }


    handleComments = (e) =>{
        const {value} = e.target;
        this.setState({comment : value})
      
    }

    render(){
        const { errors, disable, fields, loader } = this.state
        const inputProps = {
            placeholder: `Add Language`,
            value: this.state.fields['language_suggestion'],
            onChange: this.onHandleProjectChange
        };
        return (    
            <>
              <Container fluid className="p-sm-0">
                <Row className="mb-4 align-items-center">
                  <Col sm={12} className="mt-4 col-sm-offset-4">
                    <div className="headTag d-flex align-items-center">
                      <h3>Projects <span>Project Panel Overview</span></h3>
                    </div>
                  </Col>
                    {   loader ?
                            <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5"> 
                                <Loader type="Audio" color="#000" height={30} width={30} />
                            </div>
                            : 
                            <Col sm={12}   >
                                <Card className="projectTable border-0 rounded-0">
                                <Card.Body>
                                    <Row>
                                        <Col md={6} >   
                                            <h5 className="mt-0">Project Details</h5>
                                            <Form className="form-detail" onSubmit={this.handleOnSubmit}>
                                                <FormGroup>
                                                <p className="demo-date">
                                                    <span> Project Name</span>
                                                    <span className="projectNamemain"><strong>{fields['projectname']}</strong></span>

                                                </p>    
                                                </FormGroup>
                                                <Form.Group>
                                                    <p className="demo-date">
                                                        <span>Due Date</span>
                                                            <i class="far fa-calendar mr-2"></i>
                                                            <span>{this.convertDueDate(fields['due_date'])}</span>   
                                                            {/* <span> {()=>this.convertDueDate(fields['due_date'])}</span> */}
                                                            {/* <DatePicker 
                                                            selected={fields['due_date']} 
                                                            onChange={date => {
                                                                fields['due_date'] = date;
                                                                this.setState({fields});
                                                            }} 
                                                            minDate={this.subDays()}
                                                            dateFormat="MM/dd/yyyy"
                                                            disabled 
                                                        />  */}
                                                    </p>
                                                  
                                                </Form.Group>
                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["translation_tasks"]}</span>  
                                               
                                                <small style={{ color:'#db1313'}}> { errors['languages'] || '' } </small>
                                                <Form.Group>
                                                    {/* <Form.Label>Activity</Form.Label> 
                                                    {fields['activities'].length > 0 ?
                                                        fields['activities'].map((t, i) => {
                                                        return (
                                                            t.user !== undefined ? 
                                                            <>
                                                            <div className="d-flex align-items-center justify-content-between mb-3" key={i}>
                                                                <div className="projectTitle d-flex align-items-center">
                                                                    <span className="projectIcon mr-3">
                                                                    { this.ImageExist(`${process.env.REACT_APP_SERVER_PATH}`+`/profile/`+`${t.user.profile}`) ? <img src={`${process.env.REACT_APP_SERVER_PATH}`+`/profile/`+`${t.user.profile}`} alt="..." /> : <img src={require("assets/img/user-icon.png").default} alt="..." />
                                                                        }
                                                                        
                                                                        
                                                                    </span>
                                                                    <p className="m-0"><strong className="text-dark">{t.user.fullname}</strong> {t.activity}</p>
                                                                    <h6 className="heading-text">{t.createdAt} Ago</h6>
                                                                </div>
                                                                
                                                            </div> 
                                                            </>
                                                            :
                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                <div className="projectTitle d-flex align-items-center">
                                                                    <p className="m-0">No Activity Present </p>
                                                                </div>
                                                            </div>

                                                        )
                                                        })
                                                        : 
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <div className="projectTitle d-flex align-items-center">
                                                                
                                                                <p className="m-0">No Activity Present </p>
                                                            </div>
                                                            
                                                        </div>
                                                    } */}
                                                </Form.Group>
                                                {/* <Form.Group>
                                                    <Form.Label>Comment</Form.Label>  
                                                    <p><span>{}</span></p>
                                                </Form.Group> */}
                                                <Form.Group>
                                                    <Form.Label> Add Comments</Form.Label>  
                                                    <Form.Control as="textarea" rows={5} placeholder="Add a comment or post an update…" onChange={this.handleComments} value={this.state.comment} />
                                                </Form.Group>
                                                <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["comments"]}</span> 
                                                <div className="edit-project-btnMain-wraper">
                                                <Button  type="submit"style={{backgroundColor:'#C93D3A',border:'none',color:'white'}} 
                                                disabled={ disable }
                                                >
                                                { disable ? 'Wait...': 'Submit Project'}
                                                </Button>
                                               
                                                    {/* // to="/translator-script"
                                                    onClick = {() => this.editFullScreen()}
                                                > */}
                                            <Button variant="primary" className="ml-2 py-1 px-3" onClick = { this.editFullScreen}> Translate Now </Button>
                                                
                                                </div>
                                          
                                            </Form>
                                        </Col>
                                    <Col sm={6} className='comment-secion-main'>
                                        <div>
                                            <h5 className="mb-4"> Activities </h5>
                                            {fields.activities.length > 0 ?
                                                        <div className="align-items-center justify-content-between mb-3" >

                                                            <div>
                                                                {fields['comments'] ?
                                                                    <>
                                                                        <div className="projectTitle align-items-center d-flex mt-3">
                                                                            <span className="projectIcon-se-wraper">
                                                                                {/* {this.ImageExist(`${process.env.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0].userDetail.profile}`) ? <img src={`${process.env.REACT_APP_SERVER_PATH}` + `/profile/` + `${fields.activities[0].userDetail.profile}`} alt="..." /> : <img src={require("assets/img/user-icon.png").default} alt="..." />
                                                                                    } */}
                                                                                <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${fields.activities[0].userDetail.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${fields.activities[0].userDetail.profile}`  : require("assets/img/default-avatar.png")}/>
   
                                                                            </span>
                                                                            <strong className="main-comment-text text-dark">
                                                                                {fields.activities[0].userDetail.fullname}
                                                                            </strong>
                                                                            <p className="comment-main-text-edit-project m-0">
                                                                                {fields['comments']}
                                                                            </p>
                                                                            <h6 className="heading-text">{this.timeSince(fields.activities[0].createdAt)} Ago</h6>
                                                                        </div>

                                                                    </>
                                                                : null

                                                                }
                                                            </div>

                                                        </div>
                                            :
                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                    <div className="projectTitle d-flex align-items-center">
                                                        <p className="m-0">No Activity Present </p>
                                                    </div>

                                                </div>

                                            }
                                            {fields.activities.length > 0 ?
                                                fields['activities']?.map((item, i) => {
                                                    if(!item.user ){
                                                        return (
                                                            <ul>
                                                                
                                                                <li className="main-comment-list-wraper">
                                                                    <span className="projectIcon-main-wraper mr-3">
                                                                        <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${item?.userDetail?.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${item?.userDetail?.profile}`  : require("assets/img/default-avatar.png")}/>
                                                                        <strong className="text-dark main-comment-text">{item?.userDetail?.fullname}</strong>
                                                                        <p className="comment-main-text-edit-project">{item.activity}</p>
                                                                        <h6 className="heading-text">{this.timeSince(item?.createdAt)} Ago</h6>
                                                                    </span>
                                                                </li>
                                                                    
                                                            </ul>
                                                        )

                                                    }
                                                })
                                            :null   
                                            }           
                                        </div>
                                           
                                    </Col>
                                </Row>
                                    
                                </Card.Body>
                                </Card>
                            </Col>
                    }
                </Row>
              </Container>
            </>
        )
    }
}


const mapStateToProps = state => {
  const { project,translator } = state
  return {
    total_languages_suggestions: project.all_language_suggestions,
    viewProjectdetail : project.viewProject,
    projectUpdated : project.projectUpdated,
    total_users: project.all_users,
    updateProject:translator.updateProject
    

               
    // scriptDataSingle:project.single_script_data
    
  }
}
const dispatchAction = { fetchAllLanguageSuggestions, viewProjectEmail, fetchAllUsersEmail,updateProjects}
export default connect(mapStateToProps, dispatchAction)(TranslatorProjectDetail);