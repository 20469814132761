import appConfig from 'appConfig';
console.log("auth" , appConfig)

export const msalConfig = {
  auth: {
    clientId: appConfig.REACT_APP_AZURE_CLIENT_ID,
    authority: appConfig.REACT_APP_AZURE_AUTHORITY_URI,
    redirectUri: appConfig.REACT_APP_AZURE_CALLBACK_URI, 
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};