import React, {Component} from 'react';
import { Button, Card, Container, Row, Col, Form, Media, Badge } from "react-bootstrap";
import { listTotalTranslator } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { toast } from'react-toastify';
import swal from 'sweetalert';
import { socket } from '../../actions/fetchActions';
import { isLoggedIn, decodedToken } from '../../helpers/decode-token';
import appConfig from 'appConfig';

import ChatBox  from './ChatBox';
var moment = require('moment'); 
class TranslatorChat extends Component{
    constructor( props ){
        super( props );
        this.state = {
            translators:[],
            page:1,
            current_number:'',
            total_number:'',
            errors: {},
            per_page:10,
            total_pages:null,
            translatorLoading:false,
            searchInput:'',
            searchTranslator:'',
            translatorId:null,
            translatorDetails:null,
            room:null,
        } 
    }

    componentDidMount(){
        const { page, total_pages, per_page, searchTranslator, room, translatorId  } = this.state;
        var adminId = decodedToken.userId;
        this.getTranslator( page, per_page, searchTranslator  );
        if(window.location.pathname !== '/' && window.location.pathname !== '/chat'){

            this.onTranslatorLogin();
            this.onTranslatorLogout();
        }

        socket.on('message', message => {            

            if(message.receiverId == adminId){
                var receiverId = adminId;
                var type = "admin"
                if(receiverId !== null){

                    socket.emit('change-message-status', {room, receiverId,type});
                }
                this.getTranslator( page, per_page, searchTranslator  );
            }
        });

        socket.on('change-status-response', message => {

            if(message.receiverId == adminId){
                
                this.getTranslator( page, per_page, searchTranslator  );
            }

        });
    }

    getTranslator = async ( page, per_page, searchTranslator  ) => {
        this.setState({ translatorLoading: false })
        var adminId = decodedToken.userId;
        await this.props.listTotalTranslator(searchTranslator, adminId)
        if(this.props.translators.response){
            var translators = this.props.translators.translators;
            translators.sort(function(a, b) {
                if(a.chat_room !== undefined && b.chat_room !== undefined){
                    var keyA = new Date(a.chat_room.createdAt);
                    var keyB = new Date(b.chat_room.createdAt);
                    // Compare the 2 dates
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                }else if(a.chat_room === undefined){
                    return 1;
                }else if(b.chat_room === undefined){
                    return -1;
                }
                return 0;
            });


            this.setState({
                translators: translators,
                translatorLoading: false
            })

            if(this.props.match.params.translatorId !== undefined && window.location.path !=='/' && window.location.path !=='/chat'){
                let translators = this.state.translators;
                var translatorDetails = translators.find(t => t._id == this.props.match.params.translatorId)
                this.setState({ searchTranslator:'',  translatorId: this.props.match.params.translatorId, translatorDetails: translatorDetails})
                this.selectTranslator(this.props.match.params.translatorId, translatorDetails)   
            }            
           
        }else{
            this.setState({ translatorLoading: false })
            toast.dismiss()
            toast.error( this.props.translators.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    handleOnChange = (e, parameter) => {
        let fields = this.state.fields
        
        this.setState({searchTranslator: e.target.value})
        const { page, total_pages, per_page, searchTranslator  } = this.state;
        this.getTranslator( page, per_page, e.target.value  );
    }

    ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;
      
      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

    selectTranslator = (translatorId, translatorDetails) => {

        this.props.history.push(`/chat/${translatorId}`)
        this.setState({translatorId: translatorId, translatorDetails: translatorDetails})

        var adminId = decodedToken.userId;

        socket.emit('join_room', { translatorId, adminId }, (error) => {
            if(error) {
                alert(error);
            }
        });

        socket.on("roomData", room  => {
            
            if(room.adminId == adminId ){

                this.setState({ room : room.room })
            }
        });
    }

    onTranslatorLogin = () => {
        socket.on('onTranslatorLogin', data => {
            if(data && data.response && window.location.pathname !== '/' && window.location.pathname !== '/chat'){
                const { page, total_pages, per_page } = this.state;
                this.getTranslator( page, per_page, '' );
            }
        })
      }
  
    onTranslatorLogout = () => {
        socket.on('onTranslatorLogout', data => {
          if(data && data.response){
            const { page, total_pages, per_page } = this.state;
            this.getTranslator( page, per_page, '' );
          }
        })
    }

    componentDidUpdate = async (prevProps) => {
        //Typical usage, don't forget to compare the props
        if (this.props.translatorId !== prevProps.translatorId && window.location.path !=='/' && window.location.path !=='/chat') {
            let translators = this.state.translators;
            var adminId = decodedToken.userId;
            var translatorDetails = translators.find(t => t._id == this.props.translatorId)
            this.selectTranslator(this.props.translatorId, translatorDetails) 
            socket.on('message', message => {

                if(message.receiverId == adminId){
                    var receiverId = adminId;
                    var type = "admin"
                    if(receiverId !== null){
    
                        socket.emit('change-message-status', {room, receiverId,type});
                    }
                    this.getTranslator( page, per_page, searchTranslator  );
                }
            }); 

            socket.on('change-status-response', message => {
    
                if(message.receiverId == adminId){
                    
                    this.getTranslator( page, per_page, searchTranslator  );
                }
    
            });
           
        }
    }

    timeSince = (date) => {

        let new_date = new Date(date);

        var seconds = Math.floor((new Date() - new_date) / 1000);
      
        var interval = seconds / 31536000;
      
        if (interval > 1) {
       
            return moment(date).format('DD-MM-YYYY') ;
        }
        interval = seconds / 2592000;
        if (interval > 1) {
        
            return moment(date).format('DD-MM-YYYY') ;
        }
        interval = seconds / 86400;
        if (interval > 1) {
       
            return moment(date).format('DD-MM-YYYY') ;
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) + " minutes ago";
        }
        if(seconds == -1){
            seconds = 1;
        }
        return Math.floor(seconds) + " seconds ago";
    }

      componentWillUnmount(){
        
        const { room, total_pages, per_page, searchTranslator  } = this.state;
      }

    render(){
        const { translators, translatorLoading, page, total_pages, per_page, current_number, total_number, translatorId, room, translatorDetails } = this.state;
        var adminId = decodedToken.userId;
        return (
            <Container fluid className="p-sm-0">
                <Row className="mb-4">
                <Col sm={12} className="p-0">
                    <div className="headTag d-flex align-items-center">
                      <h3>Chats<span>Chats Overview</span></h3>
                    </div>
                  </Col>
                    <Col sm={12}>
                        <Card className="border-0 rounded-0 p-2 chatWidget">
                            <Row>
                                <Col sm={12} lg={4}>
                                    
                                    <Form.Group>
                                        <Form.Control type="text" placeholder="Search here" onChange={(e) => this.handleOnChange(e, 'searchTranslator')} value={this.state.searchTranslator} />
                                        <i className="fas fa-search"></i>
                                    </Form.Group>
                                    <div className="chatList">
                                    { translatorLoading ?
                                        <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" > 
                                            <Loader className="align-self-center" type="Audio" color="#000" height={30} width={30} />
                                        </div>
                                        :  
                                        translators.length > 0 ?
                                        <>
                                        {translators.map(( t, i ) => {
                                            
                                            return (
                                            <Media className={t._id == translatorId ? "selected-translator" : ""} key={i}>
                                                
                                                <Link onClick={() => {
                                                    this.selectTranslator(t._id, t);
                                                    
                                                    }}>
                                                  <span className="userIcon">
                                                    <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${t.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${t.profile}`  : require("assets/img/default-avatar.png")} 
                                                    onError={(e)=>{{
                                                    e.target.onerror = null;
                                                    e.target.src= require("assets/img/default-avatar.png");
                                                    }}}
                                                    alt="Profile" 
                                                    width={35}
                                                    height={35}
                                                    style={{borderRadius: "50%"}}
                                                    />
                                                    
                                                    <Badge variant={t.online_status ? "success" : "secondary"}></Badge>
                                                  </span>
                                                  
                                                </Link>
                                                <Media.Body onClick={() => this.selectTranslator(t._id,t)}style={{cursor:'pointer'}}>
                                                    <h5>{t.fullname}</h5>
                                                    {   t.chat_room ?
                                                        <span><i class="fas fa-check mr-1" style={{ color:t.chat_room ? t.chat_room.read ? '#2bb0a2' : 'black': '#fdfdfd',cursor:'pointer'}} ></i>{t.chat_room ? t.chat_room.message : ''} </span>
                                                        :''
                                                    }
                                                    
                                                </Media.Body>
                                            </Media>
                                            )
                                        })}
                                        </>
                                        :
                                        <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" >
                                            <p style={{textTransform:'uppercase', textAlign:'center'}}>
                                                Sorry, No Translators to show
                                            </p>
                                            </div>
                                    }
                                    </div>
                                </Col>
                                <Col sm={12} lg={8}>
                                    <div className="chatBox">
                                        {translatorDetails !== null && translatorDetails !== undefined && room !== null? <ChatBox translatorDetails={translatorDetails} room={room} senderId={adminId} receiverId={translatorId} getTranslator={this.getTranslator} type='admin' /> : ''}
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
  const { translator } = state
  return {
      translators: translator.listAllTranslator
  }
}
const dispatchAction = { listTotalTranslator }
export default connect(mapStateToProps, dispatchAction)(TranslatorChat);