import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom"; 

import  Dashboard from './components/Dashboard/Dashboard';
import Project from './components/Project&Task/Project';
// import ProjectDetails from './components/Project&Task/ProjectDetails';
// import AddProject from './components/Project&Task/AddProject';
import AddProjects from './components/Project&Task/AddProjects';
import EditProjectScript from './components/Project&Task/EditProjectScript';
// import AddProjectWithScript from './components/Project&Task/AddProjectWithScript';
import EditProject from './components/Project&Task/EditProject';
// import EditProjects from './components/Project&Task/EditProjects';
// import EditFullScreen from './components/Project&Task/EditFullScreen';
import ListTranslator from './components/Translator/ListTranslator';
import AddTranslator from './components/Translator/AddTranslator';
import EditTranslator from './components/Translator/EditTranslator';
import ViewTranslator from './components/Translator/ViewTranslator';
import TranslatorChat from './components/Translator/TranslatorChat';
// import Report from './components/Report/Report';
import SearchPage from './components/Search/SearchPage';
import MyProfile from './components/MyProfile/MyProfile';
import AdminNotificationList from './components/Notification/AdminNotificationList';
import NotFound from './components/Errors/404'
import { isLoggedIn, isTranslatorLoggedIn } from './helpers/decode-token';

const ROUTES = [
  
  {
    
    path:'/', key:"DASHBOARD", component: props => {
        if(isTranslatorLoggedIn){
          return <Redirect to="/translator-dashboard"/>
        }

        if(!isTranslatorLoggedIn){
          if(!isLoggedIn){
            return <Redirect to="/login"/>
          }
        } 
        return <RenderRoutes {...props} />
    },
    
    routes: [
        { path: "/", key: "DASHBOARD_INDEX", exact: true, component:Dashboard },
        { path: "/projects", key: "Project", exact: true, component:Project },
        // { path: "/projectdetail", key: "ProjectDetail", exact: true, component:ProjectDetails },
        // { path: "/add-project", key: "ADD_PROJECT", exact: true, component:AddProject },
        { path: "/add-projects", key: "ADD_PROJECTS", exact: true, component:AddProjects },
        { path: "/edit-projects-script/:project_Id", key: "EDIT_PROJECT_SCRIPT", exact: true, component:EditProjectScript },
        // { path: "/r-project/:scriptId/:token", key: "ADD_PROJECT_WITH_SCRIPT", exact: true, component:AddProjectWithScript },
        { path: "/edit-project/:projectId", key: "EDIT_PROJECT", exact: true, component:EditProject },
        // { path: "/edit-projects/:projectsId", key: "EDIT_PROJECTS", exact: true, component:EditProjects },
        // { path: "/edit-screen/:projectId", key: "EDIT_SCREEN", exact: true, component:EditFullScreen },
        { path: "/translators", key: "LIST_TRANSLATOR", exact: true, component:ListTranslator },
        { path: "/add-translator", key: "ADD_TRANSLATOR", exact: true, component:AddTranslator },
        { path: "/edit-translator/:translatorId", key: "EDIT_TRANSLATOR", exact: true, component:EditTranslator },
        { path: "/view-translator/:translatorId", key: "VIEW_TRANSLATOR", exact: true, component:ViewTranslator },
        // { path: "/reports", key: "REPORTS", exact: true, component:Report },
        { path: "/search", key: "SEARCH", exact: true, component:SearchPage },
        { path: '/my-profile', key: 'MY_PROFILE', exact: true, component: MyProfile},
        { path: '/admin-notification-list', key: 'ADMIN_NOTIFICATION_LIST', exact: true, component: AdminNotificationList},
        { path: '/chat', key: 'TRANSLATOR_CHAT', exact: true, component: TranslatorChat},
        { path: '/chat/:translatorId', key: 'SINGLE_TRANSLATOR_CHAT', component: TranslatorChat},
    ]
  }
];
export default ROUTES;

function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        render={props => <route.component {...props} routes={route.routes} />}
      />
    );
}


export function RenderRoutes({ routes }) {
    return (
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <Route render={ props => <NotFound />} />
      </Switch>
    );
}