import  { useEffect,useState} from 'react';
import useDrivePicker from 'react-google-drive-picker'
import { Button} from "react-bootstrap";
import {ENV} from "../../env";





function GooglePicker(props) {
  const [openPicker, authResponse] = useDrivePicker(); 
  const[file,setFile]=useState('');
   
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    openPicker({
      clientId : ENV.googlePickerClientId ,
      developerKey : ENV.googlePickerApiKey,
      // clientId : '178351986596-4i1pq2vte28jufsg7lq2183taalndfmv.apps.googleusercontent.com' ,
      // developerKey : 'AIzaSyCkutQRSwZhkLBChrDuJbJHzZk-VoDdHhs',
      viewId : "SPREADSHEETS",
      // token: token, // pass oauth token in case you already have one
      showUploadView : true,
      showUploadFolders : true,  
      supportDrives : true,  
      multiselect : false,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        // console.log("data", data)
        if (data.action === 'cancel') {
          
        }
      
        props.importGoogleFile(data)   
      },
    })  
    
  }


  
  return (
    <div>
        <Button className='google-picker' onClick={() => handleOpenPicker()}><span><img src={require("assets/img/google23.png")}></img></span> Import Scripts From Google Drive </Button>
    </div>
  );
}

export default GooglePicker;