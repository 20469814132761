import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Form, Container, Nav, Dropdown, Button, Badge,Card, Row, Col, Table } from "react-bootstrap";
import { logout } from '../../actions/adminActions';
import { translatorLogout } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import { isLoggedIn, decodedToken, isTranslatorLoggedIn, decodedTranslatorToken } from '../../helpers/decode-token';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom';
import Notification from '../Notification/Notification';
import { socket } from '../../actions/fetchActions';

import routes from "routes.js";
import { getAdmin } from '../../actions/adminActions';
import { viewTranslator } from '../../actions/translatorActions';
import { toast } from'react-toastify';
import appConfig from 'appConfig';

class Header extends Component {
  constructor(props){

    super(props)
    
    this.state = {
        admin:'',
        translator:'',
        loader:true,
        isAdmin : false ,
        isTranslatorAdmin : false
    }

  }

  componentDidMount(){
    if(isLoggedIn){
      this.fetchUser()
    }
    if(isTranslatorLoggedIn){

      this.getTranslatorDetails(decodedTranslatorToken.userId);
    }
    window.addEventListener('unload',(event) =>{
      
      if(isLoggedIn){
        socket.emit('come_offline', {userId: decodedToken.userId, type: 'admin' }) ;
      }else{
        socket.emit('come_offline', {userId: decodedTranslatorToken.userId, type: 'translator' }) ;
      }
    });
  }

  fetchUser = async () => {
      this.setState({loader:true})
      await this.props.getAdmin(decodedToken.userId)
      if(this.props.admin.success){
          this.setState({
              admin: this.props.admin.user,
              loader:false,
              isAdmin : true
          })
      }else{
         await this.props.viewTranslator(decodedToken.userId)
         if(this.props.translator.success){
            this.setState({
              admin: this.props.translator.data,
              loader:false,
              isTranslatorAdmin : true
            })
          }
            
      }
  }

  getTranslatorDetails = async (translatorId)=>{
    this.setState({loader:true})
    await this.props.viewTranslator(translatorId)
    if(this.props.translator.success){
        this.props.translator.data.languages = this.props.translator.data.languages.map(lang => lang.language)

        this.setState({
            translator:this.props.translator.data,
            loader:false,
            isTranslator : true
        })
    }else{
        this.setState({loader:false})
    }
  }

  
  mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Dashboard";
  };
  ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;
     
      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

  onTranslatorLogout = () => {
    socket.on('onTranslatorLogout', data => {
      if(data && data.response){
        const { page, total_pages, per_page } = this.state;
        this.getTranslator( page, per_page, '' );
      }
    })
  }
  componentWillUnmount(){
    window.removeEventListener('unload',(event) =>{
      socket.disconnect();

    });
  }

  renderToAccount = () =>{
    this.props.history.push('/my-profile');
  }


  render(){
    const { admin, translator, loader  , isAdmin , isTranslatorAdmin } = this.state
    return (
      <Navbar expand="lg">
        <Container fluid>
          <div className="d-flex dashboard-mobile justify-content-center align-items-center ">
            <Button
              variant="dark"
              className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle"
              onClick={this.mobileSidebarToggle}
            >
              <i className="fas fa-ellipsis-v"></i>
            </Button>
            <Navbar.Brand
              href="#home"
              onClick={(e) => e.preventDefault()}
              className="mr-2"
            >
              {this.getBrandText()}
            </Navbar.Brand>

          </div>
            <Nav className="my-nav-sec" navbar>
            {isLoggedIn ? 
              <Nav.Item>
                {/* <Form.Control type="search" className="searchInput" placeholder="Search projects..abc" /> */}
              </Nav.Item>
              : ''}


              {(isLoggedIn || isTranslatorLoggedIn  ) && <Notification userId={ isLoggedIn ? decodedToken.userId  : decodedTranslatorToken.userId } />}

                
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  aria-expanded={false}
                  aria-haspopup={true}
                  as={Nav.Link}
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  variant="default"
                >
                {  ( !loader && admin != 'undefined') ?
                  <span className="no-icon1 profile-img-badge">

                    {isAdmin ? (this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`) ? <img src={`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`}  alt="..." 
                    height ="35"
                    width="35"
                    style={{borderRadius: "50%"}}
                    /> 
                    
                    : <i className="fas fa-user-circle fa-2x"></i>) 
                    :  ''
                    } 
           

                    {isTranslatorLoggedIn ? (this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${translator.profile}`) ? <img src={`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${translator.profile}`} alt="..." 
                    height ="35"
                    width="35"
                    style={{borderRadius: "50%"}}
                    /> 
                    
                    : <i className="fas fa-user-circle fa-2x"></i>) : ''} 
                    

                    {isTranslatorAdmin ? (this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${admin.profile}`) ? <img src={`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${admin.profile}`} alt="..." 
                    height ="35"
                    width="35"
                    style={{borderRadius: "50%"}}
                    /> 
                    
                    : <i className="fas fa-user-circle fa-2x"></i>) : ''} 

                    <Badge variant="success" className="online"></Badge>
                  
                  </span>
                  : <div style={{ textAlign:'center',visibility:'hidden' }} className="col-sm-12 col-sm-offset-5"> 
                      <Loader type="Audio" color="#000" height={30} width={30} />
                    </div>
                }
                </Dropdown.Toggle>
                <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                  {isLoggedIn && 
                    <Dropdown.Item onClick={this.renderToAccount}>
                      <i className="fas fa-cog"></i> Account
                    </Dropdown.Item>

                    
                  }
                  <Dropdown.Item
                    href="#"
                    onClick={()=> {
                      if(isLoggedIn){
                        socket.emit('come_offline', {userId: decodedToken.userId, type: 'admin' }) ;
                        this.props.logout();
                      }else{
                        socket.emit('come_offline', {userId: decodedTranslatorToken.userId, type: 'translator' }) ;
                        this.props.translatorLogout();
                      }
                      window.location.reload();
                  }}
                  >
                    <i className="fas fa-sign-out-alt"></i> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  const { admin, translator } = state
  return {
      admin: admin.admin,
      translator: translator.viewTranslator
  }
}

const dispatchState = { logout, 
                        translatorLogout,
                        getAdmin, 
                        viewTranslator }

export default connect(mapStateToProps, dispatchState)(withRouter(Header));