import React, { Component }from 'react';
import { Navbar, Form, Container, Nav, Dropdown, Button, Alert } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { socket } from '../../actions/fetchActions';
import {connect} from 'react-redux';
import { fetchNotifications } from '../../actions/notificationAction';
import ReactNotifications from 'react-browser-notifications';
import { isLoggedIn } from '../../helpers/decode-token';



class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
          notifications: [],
          sidebarOpen: false,
          isOpen: false,
          data:{}
        };
    }

    componentDidMount(){
        socket.emit('JoinRecieveNotification', {userId: this.props.userId}) ;
        this.getNotify();
        this.getNotifications();
        this.onEventClearNotifications()
        this.onEventReadNotifications()

    }

    getNotifications = async () => {
        await this.props.fetchNotifications(this.props.userId, 1 , 10)
        if(this.props.notifications.response){
            this.setState({
                notifications: this.props.notifications.notifications.slice(0,5)
            })
        }
    }

    getNotify = () => {
        socket.on('onNotify', data => {
            this.setState({
              isOpen: true,
              data:data
            }, () => {
              this.getNotifications();
              this.n.show();
            })
        })
    }

    setCloseToast = (event) => {
      window.focus()
      this.n.close(event.target.tag);
      this.setState({
        isOpen: false,
        data:{},
      })

    }

    ReadNotifications = () => {
      socket.emit('read-notifications', { user: this.props.userId, notifications: this.state.notifications.map(n => n._id ) })
    }

    ClearNotifications = () => {
      socket.emit('clear-notifications', { user: this.props.userId, notifications: this.state.notifications.map(n => n._id ) })
    }

    onReadSingleNotification = (notification) => {
      if(notification && notification._id){
        socket.emit('read-notifications', { user: this.props.userId, notifications: notification._id.split() })
      }
    }

    onDeleteSingleNotification = (notification) => {
      if(notification && notification._id){
        socket.emit('clear-notifications', { user: this.props.userId, notifications: notification._id.split() })
      }
    }

    onEventClearNotifications = () => {
      socket.on('onClear', data => {
        if(data && data.response){
          this.getNotifications()
        }
      })
    }

    

    onEventReadNotifications = () => {
      socket.on('onRead', data => {
        if(data && data.response){
          this.getNotifications()
        }
      })
    }

    convertDate = (date) => {
      var d = new Date(date);
      var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
      var monthName = d.toLocaleString('default', { month: 'short' })
      return  (monthName) + " " + d.getDate() +  year
    }

    convertTime = (date) => {
      var d = new Date(date);
      return  d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    }


    render(){
        const { sidebarOpen, data, isOpen, notifications } = this.state
         return(
             <>
             {
               isOpen ?

               <ReactNotifications
                  onRef={ref => (this.n = ref)} // Required
                  title="New Project Arrived" // Required
                  body={data.message}
                  icon="devices-logo.png"
                  tag="abcdef"
                  onClick={event => this.setCloseToast(event)}
                />
                : ''
             }

                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle
                    as={Nav.Link}
                    data-toggle="dropdown"
                    id="dropdown-67443507"
                    variant="default"
                    className="mt-3"
                  >
                    <i class="far fa-bell"></i>
                    <span className="notification">{this.state.notifications.length}</span>
                  </Dropdown.Toggle>
                
                <Dropdown.Menu>
                {this.state.notifications && this.state.notifications.length > 0 
                  ?
                  <Dropdown.Item
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      className="mt-0"
                    >
                      <button className="btn-mark" type="button" onClick={() => this.ReadNotifications()}>
                        <i className="fa fa-envelope-open-o"/> Mark as read
                      </button>
                  </Dropdown.Item>
                  : '' }
                {this.state.notifications && this.state.notifications.length > 0 
                  ?
                  this.state.notifications.map((notify, idx) => {
                    return(
                     
                      <div className={`${notify.read ? `message`: `unreadMessage`} message`} key={idx}> 
                        {notify.message}
                        <span>
                          <small> 
                            { this.convertTime(notify.time)} | {this.convertDate(notify.time)} 
                          </small>
                          <small style={{display:'block'}}>
                              <span style={{display: 'inline', cursor:'pointer'}} className="mr-1" onClick={() => !notify.read ? this.onReadSingleNotification(notify) : null}> 
                                <i className={`${notify.read ? 'fa fa-check-circle' : 'fa fa-eye'} mr-1`}/> 
                                  {notify.read ? 'Marked as read' : 'Mark as read'}
                              </span>
                          </small>
                        </span>
                        </div>
                      )
                    })
                    :
                    
                    <Dropdown.Item
                      href="#"
                      onClick={(e) => e.preventDefault()}
                    >
                      No new notification
                    </Dropdown.Item>
                  }

                  {this.state.notifications && this.state.notifications.length > 0 
                  ?
                  <Dropdown.Item
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >

                    <button className="btn-clear" type="button" onClick={() => this.ClearNotifications()}>Clear All</button>
                    
                    {isLoggedIn ? <Link to="/admin-notification-list" className="btn-clear ml-1">View All </Link> : <Link to="/notification-list" className="btn-clear ml-1">View All </Link>}
                  </Dropdown.Item>
                  : '' }
                </Dropdown.Menu>
              </Dropdown>

             </>

         )

    }


}


const mapStateToProps = state => {
    return {
        notifications: state.notifications.notifications
    }
  }
  
  const dispatchState = { 
    fetchNotifications 
  }
  export default connect(mapStateToProps, dispatchState)(Notification)


