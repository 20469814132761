import { FETCH_MESSAGES, DELETE_MESSAGE } from './types';
import { apiRequest, checkTokenExpire } from './fetchActions';
import { authHeader } from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()

export const fetchMessages = (room) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        const result = await apiRequest( `v1/chat-messages/${room}`, requestOptions );
        await dispatch({ type: FETCH_MESSAGES, payload: result })
    } catch (error) {
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
}

export const deleteMessage = ( messageId ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
        }
        const result = await apiRequest(`v1/delete-message/${messageId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: DELETE_MESSAGE, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}