import React, { Component } from "react";
import { Timeline } from 'rsuite';
import { connect } from 'react-redux';
import { Badge, Card, Container, Row, Col, ListGroup } from "react-bootstrap";
import { StackedHorizontalBarChart } from 'react-stacked-horizontal-bar-chart';
import {CanvasJSChart } from 'canvasjs-react-charts';
import { fetchAllDashboardDetail } from '../../actions/dashboardActions';
import Loader from 'react-loader-spinner';
import Avatar from 'react-avatar';
import { toast } from'react-toastify';
import {decodedToken } from '../../helpers/decode-token';
import { socket } from '../../actions/fetchActions';
import appConfig from 'appConfig';


class Dashboard extends Component {
  constructor(props){
    super(props)
    this.state = {
        showMessage:'',
        errors: {},
        disable: false,
        rows:[],
        total_projects:'',
        active_projects:'',
        total_translators:'',
        active_translators:'',
        translation_per_country:[],
        translation_types:[],
        open_projects:[],
        open_translators:[],
        total_activities:[],
        loader:true,
        active_translations :[],
    }

  }

  componentDidMount(){
    this.getProject( );
    socket.emit('come_online', {userId: decodedToken.userId, type: 'admin'}) ;
  }


  getProject = async () => {
      this.setState({ loader: true })
      await this.props.fetchAllDashboardDetail()
      if(this.props.all_detail.success){
          var capitals = this.props.all_detail.translation_per_country.map(function(obj) {
              obj['label'] = obj['_id']; // Assign new key
              obj['y'] = obj['count'];
              delete obj['_id']; // Delete old key
              delete obj['count']; 
              return obj;
          })

          var total = 0;
          for (var i=0; i<this.props.all_detail.translation_types.length; i++) {
              total += this.props.all_detail.translation_types[i].count;
          }


          var capitals1 = this.props.all_detail.translation_types.map(function(obj) {
            obj['name'] = obj['_id']; // Assign new key
            if(total == 0){
              total = 1;
            }
            obj['y'] = obj['count']* 100/total;
            delete obj['_id']; // Delete old key
            delete obj['count']; 
            return obj;
          })

          this.setState({
            total_projects: this.props.all_detail.total_projects,
            active_projects: this.props.all_detail.active_projects,
            translation_completed: this.props.all_detail.active_projects,
            active_translations: this.props.all_detail.active_translations,
            total_translators: this.props.all_detail.total_translators,
            active_translators: this.props.all_detail.active_translators,
            translation_per_country: capitals.slice(0,5),
            translation_types: capitals1,
            open_projects: this.props.all_detail.open_projects,
            open_translators: this.props.all_detail.open_translators,
            total_activities:this.props.all_detail.total_activities,
            loader: false
          })
      }else{
          this.setState({ loader: false })
          toast.dismiss()
          toast.error( this.props.all_detail.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
      }
  }

  splitArray = (word) => {
    var res = word.split(" ");
    return word.split(" ")[0];
  }

  ImageExist = (url) => 
  {
    var img = new Image();
    img.src = url;
    
    if(img.src.indexOf('null') == -1 ){

      return true;
    }
    return false;
  }

  timeSince = (date) => {

    date = new Date(date);

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }


  eachTranslationStatus = (eachTranslations ) => {
    const translations = [] ;
    eachTranslations.forEach((item) =>{
        item.each_translation_status.forEach(((obj) =>{
          translations.push(obj)
        }))
       
    })
    return translations
  }   


  render() {

    const { total_projects, active_projects, total_translators, active_translators, translation_per_country,translation_types, open_projects, open_translators ,total_activities, disable, loader, translation_completed, active_translations } = this.state ;
    var completedTranslation = '';
    var activeTranslation = '';
    const options = {
			animationEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			data: [{
				type: "column", //change type to bar, line, area, pie, etc
				dataPoints: translation_per_country,
			}]
		}

    const containerProps = {
      width: "100%",
      height: "450px",
      margin: "auto"
    };

    const options1 = {
			animationEnabled: true,
			subtitles: [{
				verticalAlign: "center",
				fontSize: 11,
				dockInsidePlotArea: true
			}],
			data: [{
				type: "doughnut",
        radius: "80%",
        indexLabelPlacement: "inside",
        indexLabelFontSize: 14,
				indexLabel: "{name}: {y}",
				yValueFormatString: "#,###'%'",
				dataPoints: translation_types 
			}]
		}
    if(active_translations?.length > 0){
       completedTranslation = this.eachTranslationStatus(active_translations);
       completedTranslation = completedTranslation.filter((item , ind)=>{
                                if(item.status === true){
                                  return (item)
                                }   
                              })
        activeTranslation =  this.eachTranslationStatus(active_translations);      
        activeTranslation = activeTranslation.filter ((active , ind) => {
                              if(active.status ===false ){
                                  return (active)
                              }
                            })
    }                           

    return (
      <>
        <Container fluid className="p-sm-0">

        {   loader ?  
            <Row className="mb-4">
              <Col sm={12} className="p-0">
                <div className="headTag">
                  <h3>Welcome to Translate <span>Translations Project Manager</span></h3>
                </div>
                <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5"> 
                    <Loader type="Audio" color="#000" height={30} width={30} />
                </div>
              </Col>
            </Row> 
                :
              <>
              <Row className="mb-4">
                <Col sm={12} className="p-0">
                  <div className="headTag">
                    <h3>Welcome to Translate <span>Translations Project Manager</span></h3>
                  </div>
                </Col>
                <Col lg="4" className="mb-3 mb-lg-0">
                  <Card className="card-stats">
                    <Card.Body>
                      <div className="text-center icon-project">
                        <span>P</span>
                      </div>
                      <div className="numbers">
                        <Card.Title as="h4">{total_projects} <span>Total Projects</span></Card.Title>
                        <p className="card-category">{active_projects} Open</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" className="mb-3 mb-lg-0">
                  <Card className="card-stats">
                    <Card.Body>
                      <div className="text-center icon-mail">
                        <i class="fas fa-envelope"></i>
                      </div>
                      <div className="numbers">
                        <Card.Title as="h4">{completedTranslation.length > 0 ? completedTranslation.length : 0} <span>Translations Completed</span></Card.Title>
                        <p className="card-category">{activeTranslation.length > 0 ? activeTranslation.length : 0 } Active</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-stats">
                    <Card.Body>
                      <div className="text-center icon-friends">
                        <i class="fas fa-user-friends"></i>
                      </div>
                      <div className="numbers">
                        <Card.Title as="h4">{total_translators} <span>Translators</span></Card.Title>
                        <p className="card-category">{active_translators} Active</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="4">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-2">Projects Monitor </Card.Title>
                      <p> All Time</p>
                    </Card.Header>
                    <Card.Body>
                      <div className="border-bottom pb-3 mb-3">
                        <Row>
                        <div className="project-section">
                          <div className="project-moniter">
                            <p>5</p>
                            <span>On Time</span>
                            </div>
                          <div className="project-moniter montier-section">
                            <p>10</p>
                            <span>Late</span>
                          </div>
                          </div>
                        </Row>
                        
                        <StackedHorizontalBarChart
                          height= {50}
                          rangesPoints={[0, 10, 20]}
                          backgroundColors={['#4F81BD', '#C0504D']}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
    
                <Col md="4">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-2">Translation Per Country</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
                        
                        <CanvasJSChart containerProps={containerProps}  options = {options}/>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
    
                <Col md="4">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-2">Translation Types</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
                        
                        <CanvasJSChart options = {options1}/>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
    
              </Row>
  
              <Row className="mb-4">
                <Col md="6">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-2">Open Projects <Badge variant="danger">{active_projects}</Badge></Card.Title>
                    </Card.Header>
                    <Card.Body>
                      
                    {open_projects.length > 0 
                        ?
                        open_projects.map((t, i) => (
                          <>
                          <div className="user-detail-section d-flex align-items-center justify-content-between border-bottom pb-3 mb-3" key={i}>
                            <div className="projectTitle d-flex align-items-center">
                              <span className="projectIcon mr-3">
                              <Avatar name={this.splitArray(t.projectname)}   size={50} round="20px"/>
                                
                              </span>
                              <h3>{(t.projectname)} <small className="d-block text-gray">{t.translation_tasks.length} tasks, 3 issues</small></h3>
                            </div>
                            <div className="manageActions">
                              <ListGroup className="d-flex align-items-center">
                                <ListGroup.Item onClick = {() => this.props.history.push(`/edit-project/${t._id}`)}>Manage</ListGroup.Item>
                                <ListGroup.Item onClick = {() => this.props.history.push(`/edit-project/${t._id}`)}><i class="fas fa-pencil-alt"></i></ListGroup.Item>
                              </ListGroup>
                            </div>
                          </div>
                            </>
                          ))
                          :
                          <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">

                            <p>There is no Open Projects </p>
                          </div>
                          }
                      
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-2">Activity <Badge variant="warning">{total_activities.length}</Badge></Card.Title>
                      <p> 10 members update their activities.</p>
                    </Card.Header>
                    <Card.Body>
                    {total_activities.length > 0 
                        ?
                        total_activities.map((t, i) => (
                          
                          <>
                          
                          <div className="mb-4"  key={i}>
                            <div className="projectTitle d-flex align-items-center">
                              <span className="projectIcon hLine mr-3">
                                { t?.activityCreated 
                                  ?
                                    <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${t?.userDetail?.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${t?.userDetail?.profile}` : require("assets/img/default-avatar.png")}
                                    height ="50"
                                    width="50"
                                    style={{borderRadius: "50%"}}
                                    />
                                  :
                                    <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${t?.userId?.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}` + `/profile/` + `${t?.userId?.profile}` : require("assets/img/default-avatar.png")}
                                    height ="50"
                                    width="50"
                                    style={{borderRadius: "50%"}}
                                    />  

                                
                                }
                              </span>
                              {t?.activityCreated
                                ? 
                                  <h3>
                                    <strong>{t?.userDetail?.fullname}</strong><small>{t?.activity.replace(/This/g ,'The')} {t?.projectId.projectname}</small><small className="text-gray">{this.timeSince(t?.activityCreated)} ago</small> 
                                  </h3> 
                                :
                                <h3>
                                  <strong>{t?.userId?.fullname}</strong><small>{t?.activity.replace(/this/g ,'The')}</small><small className="text-gray">{this.timeSince(t?.createdAt)} ago</small> 
                                </h3> 


                              }
                              {/* <h3>
                                <strong>{t?.userDetail?.fullname}</strong><small>{t.activity === 'Created This Project' ? `Created New Project  ${t.projectId.projectname}` : t.activity }</small><small className="text-gray">{this.timeSince(t?.activityCreated)} ago</small> 
                                <span className="d-block">{t.activity.replace(/This/g , 'The')} <a href="#">{t.projectId.projectname}</a></span>
                              </h3>  */}
                            </div>
                          </div>
                          </>
                        ))
                        :
                        <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
                          <p>There is no Activity Present </p>
                        </div>
                        }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md="4">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-2">Active Translators</Card.Title>
                    </Card.Header>
                    <Card.Body className="mobile-view">
                    {open_translators.length > 0 
                        ?
                        open_translators.map((t, i) => (
                          <>
                          <div className="d-flex justify-content-between mb-3"  key={i}>
                            <div className="projectTitle d-flex align-items-center">
                              <span className="projectIcon mr-3">
                              <img  src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${t.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${t.profile}`  : require("assets/img/default-avatar.png")} 
                               onError={(e)=>{{
                                e.target.onerror = null;
                                e.target.src= require("assets/img/default-avatar.png");
                                 }}}
                                  alt="Profile" 
                                  height ="50"
                                  width="50"
                                  style={{borderRadius: "50%"}}
                                  />
                                
                                <Badge variant={t.online_status ? "success" : "secondary"}></Badge>
                              </span>
                              <h3>{t.fullname} <small className="d-block text-gray font-italic">{t.country} Partner</small></h3>
                            </div>
                          </div>
                          </>
                        ))
                        : 
                        <>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <p> No Active Translator Present</p>
                          </div>
                        </>
                    }

                      
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-2">Active Tasks</Card.Title>
                      <small className="text-muted">20 finished, 5 remaining</small>
                    </Card.Header>
                    <Card.Body>
                      <Timeline>
                        <Timeline.Item>16:27:41 Your order starts processing</Timeline.Item>
                        <Timeline.Item>16:28:43 Your order to be ready for delivery</Timeline.Item>
                        <Timeline.Item>16:28:45 Your parcel has been out of the library</Timeline.Item>
                        <Timeline.Item>02:34:41 Send to Shanghai Hongkou Company</Timeline.Item>
                        <Timeline.Item>15:05:29 Sending you a piece</Timeline.Item>
                      </Timeline>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4" className="mb-2">TBD?</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              </>
        }

          
        </Container>
      </>
    )
  }
}

const mapStateToProps = state => {
const { dashboard } = state
return {
  all_detail: dashboard.all_detail  
}
}
const dispatchAction = { fetchAllDashboardDetail }
export default connect(mapStateToProps, dispatchAction)(Dashboard);
