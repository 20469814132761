
const DEVELOPMENT = {
    REACT_APP_BASE_URL :'http://team.web1experts.com:5050/translator/admin/api' ,

    REACT_APP_SERVER_PATH :'https://api.scriptlibrary.cvglobal.co' ,

    REACT_APP_TRANSLATOR_BASE_URL :'http://team.web1experts.com:5050/translator/api' ,

    REACT_APP_ADMIN_BASE_URL : 'http://team.web1experts.com:5050/admin/api' ,

    REACT_APP_AZURE_CLIENT_ID :'ef277d37-0203-4e00-ab6f-a63e8bd42e0b' ,

    REACT_APP_AZURE_AUTHORITY_URI : 'https://login.microsoftonline.com/75e1f802-7066-4973-a5b9-b531e2e277ed' ,

    REACT_APP_AZURE_CALLBACK_URI : 'http://localhost:3001/login' ,

    REACT_APP_SLACK_WEBHOOK_LINK : 'https://hooks.slack.com/services/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' ,

    REACT_APP_GOOGLE_PICKER_CLIENT_KEY : '592083190699-kblouis81364vgbdm3kph1u3ilsh5ggo.apps.googleusercontent.com' ,

    REACT_APP_GOOGLE_PICKER_DEV_KEY :  'AIzaSyDz68R4nwvGj--g2TyUYVw3fAB-zb1HEEM' ,
}

const PRODUCTION = {
    REACT_APP_BASE_URL :'https://api.scriptlibrary.cvglobal.co/translator/admin/api' ,

    REACT_APP_SERVER_PATH :'https://api.scriptlibrary.cvglobal.co' ,

    REACT_APP_TRANSLATOR_BASE_URL :'https://api.scriptlibrary.cvglobal.co/translator/api' ,

    REACT_APP_ADMIN_BASE_URL : 'https://api.scriptlibrary.cvglobal.co/admin/api' ,

    REACT_APP_AZURE_CLIENT_ID :'ef277d37-0203-4e00-ab6f-a63e8bd42e0b' ,

    REACT_APP_AZURE_AUTHORITY_URI : 'https://login.microsoftonline.com/75e1f802-7066-4973-a5b9-b531e2e277ed' ,

    REACT_APP_AZURE_CALLBACK_URI : 'https://translate.scriptlibrary.cvglobal.co/login' ,

    REACT_APP_SLACK_WEBHOOK_LINK : 'https://hooks.slack.com/services/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' ,

    REACT_APP_GOOGLE_PICKER_CLIENT_KEY : '592083190699-kblouis81364vgbdm3kph1u3ilsh5ggo.apps.googleusercontent.com' ,

    REACT_APP_GOOGLE_PICKER_DEV_KEY :  'AIzaSyDz68R4nwvGj--g2TyUYVw3fAB-zb1HEEM' ,
}


const ENVIRONMENT = PRODUCTION
   

module.exports = ENVIRONMENT