import React, { Component } from "react";
import { Button, Card, Container, Row, Col, Form, Media, Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import Autosuggest from 'react-autosuggest';
import { fetchAllLanguageSuggestions, addProject,downloadFile} from '../../actions/projectActions';
import { socket } from '../../actions/fetchActions';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { decodedToken } from '../../helpers/decode-token';
import Dropzone from "react-dropzone";
import * as check from '../../helpers/validation';
import srtParser2 from "srt-parser-2";
// import GooglePicker from 'react-google-picker';
import GooglePicker from "./GooglePicker";
import axios from "axios";
import moment from "moment";
import appConfig from 'appConfig';

class AddProjects extends Component {

    constructor(props) {

        super(props)

        this.ChildElement = React.createRef();
        this.state = {
            showMessage: '',
            errors: {},
            disable: false,
            total_languages_suggestions: [],
            rows: [],
            edit_full_screen: false,
            send_languages: [],
            fields: {
                projectname: '',
                due_date: new Date(),
                translation_tasks: [],
                language_suggestion: '',
                languages: [],
                activities: [],
                scriptFile:'',// is used to store uploaded File from drag and drop
                each_Translation_Status :[],
                comments: '',
                googlePicker:{
                    fileId:'',
                    fileName:'' ,
                },
            },
            scriptPreview:'',
            showIcon: false

        }
    }


    onSuggestionsFetchRequested = async ({ value }) => {

        await this.props.fetchAllLanguageSuggestions();
        var all_languages = [];
        if (this.props.total_languages_suggestions.success) {

            all_languages = this.props.total_languages_suggestions.languages;
        }
        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            all_languages = [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');

        this.setState({
            total_languages_suggestions: all_languages.filter(p => (regex.test(p.language) && p.translatorId !== null))
        });
      
    };
  

    onSuggestionsClearRequested = () => {
        this.setState({
            total_languages_suggestions: []
        });
    };

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestionValue = (suggestion) => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors['languages'] = ''
        this.setState({ errors })
        if (suggestion.language < 1) return '';
        if (suggestion.language === "English") {
            errors['languages'] = `English is already Present`
            this.setState({ errors })
            return '';
        }

        if (suggestion.language.trim().length < 1) return '';

        let languageFound = fields['languages'].find(t => t.language.toLowerCase() === suggestion.language.toLowerCase())
        if (languageFound) {
            errors['languages'] = `Can't add duplicate languages`
            this.setState({ errors })
            return '';
        }

        fields['languages'].push(suggestion);
        errors['languages'] = '';
        var send_languages = fields['languages'].map(x => x.language)
        // this.setState({ fields, errors, disable: false, send_languages });

        // const language = {language:suggestion.language , status:false}
        const translateStatus= new Object();
        translateStatus.language = suggestion.language;
        translateStatus.languageId = suggestion._id
        translateStatus.status = false;
        // language.language = suggestion;
        // language.status = false;
        fields['each_Translation_Status'].push(translateStatus)
        this.setState({ fields, errors, disable: false, send_languages });

        return '';
    }

    renderSuggestion(suggestion) {
        return <span>{suggestion.text}</span>;
      }

    renderSuggestion = (suggestion, { query }) => {
        if (suggestion.translatorId !== null) {

            return (
                <Media className="align-items-center justify-content-between">
                    <span>
                        <i className="far fa-check-circle circle-left"></i> {suggestion.language}
                    </span>
                    <Media.Body>

                        <div className="projectTitle add-language d-flex align-items-center">
                            <span className="projectIcon mr-3">
                                <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/translator_profile/` + `${suggestion.translatorId.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}` + `/translator_profile/` + `${suggestion.translatorId.profile}` : require("assets/img/default-avatar.png")} 
                                onError={(e)=>{{
                                e.target.onerror = null;
                                e.target.src= require("assets/img/default-avatar.png");
                                }}}
                                alt="Profile" />
                                <Badge variant="success"></Badge>
                            </span>
                            <h3>{suggestion.translatorId.fullname} <small className="d-block text-gray font-italic">{suggestion.translatorId.country}</small></h3>
                        </div>
                    </Media.Body>
                </Media>
            )
        }
    }

    ImageExist = (url) => {
        var img = new Image();
        img.src = url;
        if (img.src.indexOf('null') == -1) {
            return true;
        }
        return false;
    }

    onHandleProjectChange = (event, { newValue, method }) => {
        let fields = this.state.fields
        fields['language_suggestion'] = newValue
        this.setState({ fields })
    };

    handleRemoveLanguageOnClick = (tag) => {
        let fields = this.state.fields;
        let languages = fields['languages'];
        let eachTranslationStatus = fields['each_Translation_Status']
        languages = languages.filter(t => t.language !== tag.language)
        eachTranslationStatus = eachTranslationStatus.filter(t => t.language !== tag.language)

        if (languages.length < 1) {
            let errors = this.state.errors
            errors['languages'] = 'At least enter one languages'
            this.setState({ errors, disable: true })
        }
        fields['languages'] = languages
        fields['each_Translation_Status'] = eachTranslationStatus
        var send_languages = fields['languages'].map(x => x.language)
        this.setState({ fields, send_languages })
    }

    subDays = () => {
        var d = new Date();
        return d;
    }

    handleCheckbox = (e, parameter) => {
        let fields = this.state.fields
        const value = e.target.value;
        if (e.target.checked) {

            fields['translation_tasks'].push(value);
        } else {
            if (fields['translation_tasks'].indexOf(value) != -1) {
                fields['translation_tasks'] = fields['translation_tasks'].filter(item => item !== value)
            }
        }

        this.setState({ fields })
    }

    handleOnChange = (e, parameter) => {
        let fields = this.state.fields
        let errors = this.state.errors
        errors[parameter] = ''
        fields[parameter] = e.target.value;
        this.setState({ fields, errors })
    }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;

        if (!fields["projectname"]) {
            formIsValid = false;
            errors["projectname"] = " Project is required";

        }

        if (!fields["due_date"]) {
            formIsValid = false;
            errors["due_date"] = "Due Date is required";
        }

        if (fields["translation_tasks"].length == 0) {
            formIsValid = false;
            errors["translation_tasks"] = "At least One Select Translation task";
        }

        if (fields["languages"].length == 0) {
            formIsValid = false;
            errors["languages"] = "At least enter one language";
        }
        this.setState({ errors: errors});
        return formIsValid;
    }
    /////////////////////////////upload file start //////////////////////////////////////
    checkextension = (file) => {
        if (/\.(tsv|xlsx|xls|csv|doc|txt|docx|srt)$/i.test(file.name.toLowerCase()) === false) { return false };
        return true;
    }

    checkextensionSrt=(file)=>{
        if (/\.(srt)$/i.test(file.name.toLowerCase()) === 
        false) { return false };
        return true;
    }

    handleDrop = acceptedfile => {
        // console.log("acceptedfile", acceptedfile)
        const file = acceptedfile[0];
        let fields = this.state.fields
        let preview = this.state.scriptPreview;
        preview = URL.createObjectURL(file);
        this.setState({ scriptPreview: preview })
        let isFileValid = this.checkextension(file)
        // console.log("isFileValid" , isFileValid)

        // fields['scriptFile']= file;

        fields['scriptFile']= file;
        // console.log("scriptFile" ,fields['scriptFile'] )
        if (file && isFileValid) {
            this.setState({ fields });
        }else{
            // console.log("file is not valid")
        }   

        if(this.state.fields['scriptFile'] !== ''){
            this.setState({showIcon:true})
        }
    }
    handleDeleteScriptFile = () => {
        let fields = this.state.fields
        fields['scriptFile']=''
        this.setState({scriptPreview:'',fields , showIcon : false})
    }

    handleDeleteGoogleFile = () => {
        let fields =this.state.fields
        fields['googlePicker'].fileId= '';
        fields['googlePicker'].fileName= '';
        this.setState({showIcon : false , fields})
    }


    handleGoogleDrive=async(file)=>{     

        // console.log("googleImportFile", file )
        if( file.action === 'cancel' ){
            // console.log(' Google Drive Picker: Cancelled by user')
            return;
        }else if(file.action === 'loaded'){
            // console.log(' Google Drive Picker: Loaded')
        }else if(file.action === 'picked'){
            let fields = this.state.fields
            fields['googlePicker'].fileId= file.docs[0].id;
            fields['googlePicker'].fileName=file.docs[0].name
            this.setState({fields})  
    
            if(fields['googlePicker'].fileId && fields['googlePicker'].fileName){
                this.setState({showIcon: true})
    
                let data = {
                    googlePicker: this.state.fields['googlePicker']
                  }
               const res= await this.props.downloadFile(data)
               
            }
        }else{
            console.log( ' Google Drive Handler:: No Actions' )
        }
    }

    ////////////////////////////////end upload file ///////////////////////////////////////////////////////

    handleOnSubmit = async (e) => {
        e.preventDefault();
        const fields = this.state.fields;
        if (this.handleValidation) {
            this.setState({ disable: true })
            const formData = new FormData()
            const user = new Object();
            const person = new Object();
            person.userDetail = decodedToken;
            person.activity = 'Created This Project';
            person.createdAt = moment().format()
            let found = fields['activities'].find(t => t.userDetail.userId.toLowerCase() === person.userDetail.userId)
            if (!found) {
                fields['activities'].push(person);
                this.setState({ fields });
            }
            for (const [key, value] of Object.entries(fields)) {
                if (typeof value === 'object' && key !== 'scriptFile') {
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value)
                }   
            }
            await this.props.addProject(formData);
                if (this.props.projectAdded.success) {
                    this.setState({ disable: false })
                    this.sendNotification();
                    swal("Good job!", this.props.projectAdded.message,"success")
                        .then(async (willDelete) => {
                            if (willDelete) {
                                this.props.history.push(`/edit-projects-script/${this.props.projectAdded.project._id}`)
                            }
                        });
                } else {
                    this.setState({ disable: false })
                    toast.dismiss()
                    toast.error(this.props.projectAdded.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
                }
        }
    }

    sendNotification = async () => {
        let fields = this.state.fields;
        const result = [];
        for (var i = 0; i < fields['languages'].length; i++) {
            if (result.indexOf(fields['languages'][i].translatorId._id) == -1) {
                result.push(fields['languages'][i].translatorId._id);
            }
        }
        socket.emit('Send_Notification', { senderId: decodedToken.userId, room: result, message: `${decodedToken.fullname} have Assigned project "${fields["projectname"]}" to You` });
    }

    render() {
        const { total_languages_suggestions, errors, disable, fields, rows, edit_full_screen, alert } = this.state
        const inputProps = {
            placeholder: `Enter Language To Translate`,
            value: this.state.fields['language_suggestion'],
            onChange: this.onHandleProjectChange
        };

        if (edit_full_screen) {
            return (
                <Container fluid className="p-sm-0">
                    <Row className="mb-4">
                        <Col sm={12} className={edit_full_screen ? "p-0 d-none" : "p-0"} >
                            <div className="headTag d-flex align-items-center">
                                <h3>Projects <span>Project Panel Overview</span></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        } else {
            return (
                <Container fluid className="p-sm-0">
                    <Row className="mb-4">
                        <Col sm={12} className="p-0">
                            <div className="headTag d-flex align-items-center">
                                <h3> Add Projects <span>Project Panel Overview</span></h3>
                            </div>
                        </Col>
                        <Container fluid className="add-project-bg-color">
                            <Col sm={12} className="main-wraper">
                                <Card className="projectTable border-0 rounded-0">
                                    <Card.Body>
                                        <Container className="add-project-bg-color">
                                            <Row className="main-row-layout">
                                                <Col md={12} className="project-heading">
                                                    <Form className="form-detail" onSubmit={this.handleOnSubmit}>
                                                        <div className="colomn-wrap">
                                                            <h5 className="mt-0">Project Details </h5>
                                                            <Form.Group>
                                                                <Form.Control type="text" placeholder="Project Name" onChange={(e) => this.handleOnChange(e, 'projectname')} value={this.state.fields['projectname']} />
                                                                <span style={{ color: "red", fontSize: '12px' }}>{this.state.errors["projectname"]}</span>
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <p className="date-section-m">
                                                                    <span className="due-span">Due Date</span>
                                                                        <div className="date-timer-addproject">
                                                                        <i class="far fa-calendar mr-2"></i>
                                                                        <DatePicker className="date-deu" selected={fields['due_date']} onChange={date => {
                                                                            fields['due_date'] = date;
                                                                            this.setState({ fields });
                                                                        }}
                                                                            minDate={this.subDays}
                                                                            dateFormat="MM/dd/yyyy"
                                                                        /> 
                                                                        </div>
                                                                </p>
                                                                <p className="date-section-m tasks-add">
                                                                    <span className="due-span">Translation Tasks</span>
                                                                    <span className="d-md-flex flex-wrap checkbox-select">
                                                                    <Form.Check type="checkbox" label="Script" onChange={(e) => this.handleCheckbox(e, 'Script')} value="Script" />
                                                                    <Form.Check type="checkbox" onChange={(e) => this.handleCheckbox(e, 'Consideration Pathway')} value="Consideration Pathway" label="Consideration Pathway" />
                                                                    <Form.Check type="checkbox" onChange={(e) => this.handleCheckbox(e, 'Headline/CTA')} value="Headline/CTA" label="Headline/CTA" />
                                                                    <Form.Check type="checkbox" onChange={(e) => this.handleCheckbox(e, 'General Document')} value="General Document" label="General Document" />
                                                                    </span>
                                                                </p>    
                                                            </Form.Group>
                                                            <span style={{ color: "red", fontSize: '12px' }}>{this.state.errors["translation_tasks"]}</span>
                                                            <Form.Group className="autosuggest-input">
                                                                <Form.Label className="select-lang">Select Languages</Form.Label>
                                                                <Autosuggest
                                                                    suggestions={total_languages_suggestions}
                                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                                    getSuggestionValue={this.getSuggestionValue}
                                                                    renderSuggestion={this.renderSuggestion}
                                                                    inputProps={inputProps}
                                                                />
                                                            </Form.Group>
                                                            <small style={{ color: '#db1313' }}> {errors['languages'] || ''} </small>
                                                            <Form.Group>
                                                                {fields['languages'].length > 0 &&
                                                                    fields['languages'].map((t, i) => (
                                                                    <Media className="align-items-center justify-content-between" key={i}>
                                                                        <span> 
                                                                            <i class="far fa-check-circle mr-2" />
                                                                            {t.language}
                                                                        </span>
                                                                        <Media.Body>
                                                                            <div className="projectTitle add-language d-flex align-items-center">
                                                                                <span className="projectIcon mr-3">
                                                                                    <img 
                                                                                        src={
                                                                                            (t.translatorId.profile && this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}` + `/translator_profile/` + `${t.translatorId.profile}`) )
                                                                                            ? `${appConfig.REACT_APP_SERVER_PATH}` + `/translator_profile/` + `${t.translatorId.profile}` 
                                                                                            : require("assets/img/default-avatar.png")
                                                                                        }
                                                                                        onError={(e)=>{{
                                                                                            e.target.onerror = null;
                                                                                            e.target.src= require("assets/img/default-avatar.png");
                                                                                        }}}
                                                                                    alt="profile" />
                                                                                    <Badge variant={t.translatorId.online_status ? "success" : "secondary"}></Badge>
                                                                                </span>
                                                                                <h3>{t.translatorId.fullname} <small className="d-block text-gray font-italic">{t.translatorId.country}</small></h3>
                                                                            </div>
                                                                        </Media.Body>
                                                                        <span style={{ display: 'inline', cursor: 'pointer' }} className="fa fa-times" onClick={() => this.handleRemoveLanguageOnClick(t)}></span>
                                                                    </Media>
                                                                ))}
                                                            </Form.Group>
                                                        </div>
                                                        <Form.Group>
                                                            <Form.Label>Activity</Form.Label> 
                                                            {fields['activities'].length > 0 
                                                            ?   fields['activities'].map((t, i) => {
                                                            return (
                                                                t.user !== undefined ? 
                                                                <>
                                                                <div className="d-flex align-items-center justify-content-between mb-3" key={i}>
                                                                    <div className="projectTitle d-flex align-items-center">
                                                                        <span className="projectIcon mr-3">
                                                                        { this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${t.user.profile}`) ? <img src={`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${t.user.profile}`} alt="..." /> : <img src={require("assets/img/default-avatar.png")} alt="..." />
                                                                            }
                                                                            
                                                                            
                                                                        </span>
                                                                        <p className="m-0"><strong className="text-dark">{t.user.fullname}</strong> {t.activity}</p>
                                                                        <h6 className="heading-text">{t.createdAt} ago</h6>
                                                                    </div>
                                                                    
                                                                </div> 
                                                                </>
                                                                :
                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                    <div className="projectTitle d-flex align-items-center">
                                                                        <p className="m-0">No Activity Present </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                                })
                                                            :   <div className="d-flex align-items-center justify-content-between mb-3">
                                                                    <div className="projectTitle d-flex align-items-center">
                                                                        
                                                                        <p className="m-0">No Activity Present </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="comment-section">
                                                        <Form.Group>
                                                            <Form.Label>Comments</Form.Label>  
                                                            <Form.Control as="textarea" rows={5} placeholder="Add a comment or post an update…" onChange={(e) => this.handleOnChange(e, 'comments')} value={this.state.fields['comments']} />
                                                            </Form.Group>
                                                            </div>
                                                        </Form.Group>
                                                        <div className="fileDrag upload-file-main-wrap">
                                                            { (this.state.fields.googlePicker.fileId && this.state.fields.googlePicker.fileName) 
                                                                ?   <>
                                                                        <div className="image-drag-drop">
                                                                            <img src={require("assets/img/picker.png")}></img>
                                                                            <p className="fileNameMainWraper">{this.state.fields.googlePicker.fileName}</p>
                                                                        </div>
                                                                        {this.state.showIcon 
                                                                        ?   <div>
                                                                                <span
                                                                                    className="trash_icon"
                                                                                    onClick={() => this.
                                                                                    handleDeleteGoogleFile()}>
                                                                                    <i className="fa fa-times" aria-hidden="true" />
                                                                                </span>
                                                                            </div>
                                                                        :   null
                                                                        }        
                                                                    </>
                                                            
                                                                :
                                                                <>
                                                                    <Dropzone onDrop={this.handleDrop} multiple={false} >
                                                                        {({ getRootProps, getInputProps,isDragAccept, isDragReject,isDragActive}) => {
                                                                            const additionalClass = isDragAccept ? "accept" : isDragReject ? "reject" : '';
                                                                            return(
                                                                            <section>
                                                                                <div {...getRootProps({ className: `dropzone ${additionalClass}` })}>

                                                                                    <input {...getInputProps()} />
                                                                                    {this.state.scriptPreview.length > 0
                                                                                        ?
                                                                                        <>
                                                                                            <div className="image-drag-drop">
                                                                                                <img src={require("assets/img/filePlaceHolder.png")}></img>
                                                                                                <p className="fileNameMainWraper">{this.state.fields['scriptFile'].name}</p>
                                                                                            </div>
                                                                                            {/* <div>
                                                                                            <span
                                                                                                    className="trash_icon"
                                                                                                    onClick={() => this.
                                                                                                    handleDeleteScriptFile()}>
                                                                                                    <i className="fa fa-times" aria-hidden="true" />
                                                                                                </span>
                                                                                            </div> */}
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <svg id="color" viewBox="0 0 24 24">
                                                                                            <path d="m14.25 0h-11.5c-1.52 0-2.75 1.23-2.75 2.75v15.5c0 1.52 1.23 2.75 2.75 2.75h6.59c-.54-1.14-.84-2.41-.84-3.75 0-1.15.22-2.25.64-3.26.2-.51.45-1 .74-1.45.65-1.01 1.49-1.87 2.48-2.54.51-.35 1.05-.64 1.63-.86.93-.39 1.95-.61 3.01-.63v-5.76c0-1.52-1.23-2.75-2.75-2.75z" fill="#eceff1" /><g fill="#90a4ae"><path d="m14 9c0 .05 0 .1-.01.14-.58.22-1.12.51-1.63.86h-8.36c-.55 0-1-.45-1-1s.45-1 1-1h9c.55 0 1 .45 1 1z" /><path d="m9.88 12.54c-.29.45-.54.94-.74 1.45-.04.01-.09.01-.14.01h-5c-.55 0-1-.45-1-1s.45-1 1-1h5c.38 0 .72.22.88.54z" /><path d="m8 6h-4c-.552 0-1-.448-1-1s.448-1 1-1h4c.552 0 1 .448 1 1s-.448 1-1 1z" /></g><path d="m17.25 24c-3.722 0-6.75-3.028-6.75-6.75s3.028-6.75 6.75-6.75 6.75 3.028 6.75 6.75-3.028 6.75-6.75 6.75z" fill="#31cab9" /><path d="m17.25 21c-.552 0-1-.448-1-1v-5.5c0-.552.448-1 1-1s1 .448 1 1v5.5c0 .552-.448 1-1 1z" fill="#fff" /><path d="m20 18.25h-5.5c-.552 0-1-.448-1-1s.448-1 1-1h5.5c.552 0 1 .448 1 1s-.448 1-1 1z" fill="#fff" />
                                                                                            </svg>
                                                                                            <h3 style={{color:"#000"}}>Drag script anywhere to Upload</h3>
                                                                                            <p> Allowed Only ( pdf, doc, docx, xlsx, xls, txt, tsv, csv, srt, rtf )</p>
                                                                                            <span style={{ color: "red", fontSize: '12px' }}>
                                                                                                {this.state.errors["scriptFile"]}
                                                                                            </span>
                                                                                        </>

                                                                                    }

                                                                                </div>
                                                                            </section>
                                                                        )}}
                                                                    </Dropzone>
                                                                    {this.state.showIcon 
                                                                    ?    <div>
                                                                            <span
                                                                                className="trash_icon"
                                                                                onClick={() => this.
                                                                                handleDeleteScriptFile()}>
                                                                                <i className="fa fa-times" aria-hidden="true" />
                                                                            </span>
                                                                        </div>
                                                                    :   null
                                                                    }        
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="button-wraper">
                                                            {this.state.fields['projectname']&&this.state.fields['due_date']&&this.state.fields['translation_tasks'].length&&this.state.fields['languages'].length > 0 ?
                                                            <>
                                                                <Button style={{backgroundColor:'#C93D3A',border:'none',color:'white'}} type="submit" disabled={this.state.disable }>
                                                                { this.state.disable ? 'Wait...': 'Submit Project'}
                                                                </Button>
                                                            </>
                                                            :
                                                            <>
                                                                
                                                                <Button style={{ backgroundColor: '#C93D3A', border: 'none', color: 'white' }} type="submit" disabled>Create Project
                                                                </Button>
                                                            </>
                                                            }
                                                        </div>
                                                    </Form>
                                                </Col>
                                                {/* <div className="google-Drive-btn">
                                                    {this.state.showIcon 
                                                        ?   null 
                                                        :   <>
                                                                <h5> Or </h5>
                                                                <GooglePicker  importGoogleFile = {this.handleGoogleDrive}/>
                                                                <p className="AllowedMainWraper"> (Allowed Only Spreadsheets)</p>
                                                            </>
                                                    }
                                                </div> */}
                                            </Row>
                                        </Container>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Container>
                    </Row>
                </Container>
            )
        }
    }
}


const mapStateToProps = state => {
    const { project } = state
    return {
        total_languages_suggestions: project.all_language_suggestions,
        projectAdded: project.add,
    }
}
const dispatchAction = { fetchAllLanguageSuggestions, addProject,downloadFile}
export default connect(mapStateToProps, dispatchAction)(AddProjects);