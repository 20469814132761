import { ALL_DASHBOARD_DETAIL } from "../actions/types";

const initialState = { all_detail: ''}

export default ( state = initialState, action ) => {

    switch (action.type) {
        case ALL_DASHBOARD_DETAIL:
            return {
                ...state,
                all_detail: action.payload
            }
        default: return state;
    }

}
