import React, {Component} from 'react';
import { Button, Card, Container, Row, Col, Form, Media, Badge, ListGroup, ListGroupItem,TabPane,Tabs, Tab, Dropdown, ButtonGroup } from "react-bootstrap";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {searchScript} from  '../../actions/projectActions';



class SearchPage extends Component{
    constructor(props){
        super(props)
        this.state={
        statement:'',
        statementData:[],
        loading:false
        }
    }

    handleStatement=(e)=>{
     const{value}=e.target
     this.setState({statement:value})
    }

    handleSearchData=async(e)=>{
        e.preventDefault()
        const statement=this.state.statement.trim()
        if(statement !==''){
            this.setState({loading:true})
            await this.props.searchScript(statement)
            if (this.props.scriptData.success){
                this.setState({loading:false})
                this.setState({
                    statementData:this.props.scriptData.searchData
                })
            }else{
                this.setState({loading:false})
                toast.dismiss()
                toast.error(this.props.scriptData.message, { position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true })
            }
        }else{
            alert('please enter the statement to search')
        }
        
    }
    
    render(){
        return(
            <>
                <Container fluid className="p-sm-0">
                    <Row className="mb-4">
                        <Col sm={12} className="p-0">
                             <div className="headTag d-flex align-items-center">
                                <h3>Search<span>Search Translation</span></h3>
                            </div>
                        </Col>
                        <Col sm={12} className="card-main-wraper-search">
                            <Card className="border-0 rounded-0 p-2 chatWidget">
                                <Row className="search-top-main-wrap">
                                    <Col sm={12} lg={12}>
                                        <Form.Group className="search-main-section-w">
                                            <div className='search-icon-m'>
                                            <Form.Control type="text" placeholder="Search here" onChange=
                                            { this.handleStatement} value={this.statement} />
                                            <i className="fas fa-search"></i>
                                            </div>
                                       <div>
                                        {this.state.statement!=''?
                                            <Button type="submit" onClick={this.        handleSearchData}>
                                                {this.state.loading?'Please Wait':'Search'}
                                            </Button>
                                        :
                                        <Button type="submit"style={{
                                        backgroundColor: '#31cab9',
                                        border: 'none',
                                        color:'white' }}disabled
                                        >
                                             Search   
                                        </Button>

                                        }
                                        </div>
                                        </Form.Group>
                                    </Col>   
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <div className='heading-wraper-serch'>
                                        {this.state.statementData.length>0 && Object.keys(this.state.statementData[0]).length > 0?
                                        <>
                                            <h5 className='language-search-m-w'>Language</h5>
                                            <h5>Translation</h5>
                                        </>
                                        :null
                                        }    
                                        </div>
                                        
                                        {this.state.statementData.length>0 && Object.keys(this.state.statementData[0]).length>0?
                                            <>
                                                {this.state.statementData.map((item)=>{
                                                    return  Object.entries(item).map(([key,val])=>{
                                                        return(
                                                            <Media key={key}>
                                                                <Media.Body className="madia-m-w">
                                                                    <h5>{key}</h5>
                                                                    <p>{val}</p>
                                                                </Media.Body>
                                                            </Media>
                                                        )
                                                    })
                                                })}
                                            </>
                                            : 
                                                <>
                                                    <Media>
                                                        <Media.Body>
                                                            <h5 className='text-center'>Sorry,Translation Not Found!</h5>
                                                        </Media.Body>
                                                    </Media>
                                                </>
                                        }
                                    </Col>
                                </Row>
                            </Card>        
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    const{project}= state
    return{
        scriptData:project.searchScriptData
    }
}
const dispatchAction={searchScript}    
export default connect(mapStateToProps,dispatchAction)(SearchPage)