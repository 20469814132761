import React, {Component} from 'react';
import { Badge, Button, Card, Container, Row, Col, Table, Dropdown } from "react-bootstrap";

import { listTranslator, activeInactiveTranslator, deleteTranslator } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { toast } from'react-toastify';
import swal from 'sweetalert';
import { socket } from '../../actions/fetchActions';
import appConfig from 'appConfig';

toast.configure()

class ListTranslator extends Component{

  constructor( props ){
    super( props );
    this.state = {
        translators:[],
        translatorsActiveProjects : [] ,
        eachTranslations : [] ,
        page:1,
        current_number:'',
        total_number:'',
        errors: {},
        per_page:10,
        total_pages:null,
        translatorLoading:false,
        searchInput:'',
      } 
  }

    componentDidMount(){
        const { page, total_pages, per_page } = this.state;
        this.getTranslator( page, per_page,'' );
        this.onTranslatorLogin()
        this.onTranslatorLogout()
    }

    getTranslator = async ( page, per_page, search ) => {
        this.setState({ translatorLoading: true })
        await this.props.listTranslator( page, per_page, search )
        if(this.props.translators.response){
            this.setState({
              translators: this.props.translators.translators,
              total_pages: this.props.translators.meta.total_pages,
              current_number: this.props.translators.translators.length,
              total_number: this.props.translators.meta.total,
              translatorsActiveProjects : this.props.translators.translatorProjects,
              eachTranslations: this.props.translators.eachTranslations , 
              translatorLoading: false
            })
        }else{
            this.setState({ translatorLoading: false })
            toast.dismiss()
            toast.error( this.props.translators.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    onChangeEntries = (e) => {
        this.setState({ per_page: e.target.value, page: 1 }, ()=> this.getNews( this.state.page, this.state.per_page, this.state.searchInput ))
    }

    capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;
      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

    languageObjectConvert = (tag) => {

      var convert = tag.map(lang => lang.language);
      convert  =  convert.toString()
       convert = convert.replace(/,/g, ', ')

      return convert
    }

    translatorsActiveProjects = (id) => {
     if(this.state.translatorsActiveProjects.length > 0){
        var projects = this.state?.translatorsActiveProjects?.filter((item)=>{
          if(item._id == id ){
            return (
              item
            )
          }
        })
       return projects.length
      }else {
        return 0
      }
    }

    completedTranslation = (lang) => {
      const eachTranslation = this.state.eachTranslations ;
      var status = [];
      for (let i = 0; i < lang.length; i++) {
        const translationStatus = lang[i]._id;
        for (let j = 0; j < eachTranslation.length; j++) {
          if (eachTranslation[j].languageId  === translationStatus) {
             status .push(eachTranslation[j].status);
          }
        }
        
      }
      if(status.length > 0 ) {
        status = status.filter((item) => {
          if(item === true ){
            return item
          }
        })
        return status.length
      }else{
        return 0
      }

    }

    onStatusChange = async (id) => {
      swal({
        title: "Are you sure?",
        text: "Staus Will be Changed",
        icon: "warning",
        buttons: ['Cancel', 'Yes'],
        dangerMode: true,
      })
      .then(async (willDelete) => {
        if (willDelete) {
            await this.props.activeInactiveTranslator(id)
            if(this.props.translatorStatus.success){
                
                this.getTranslator(this.state.page, this.state.per_page, this.state.searchInput);
                swal(this.props.translatorStatus.message, {icon: "success"});
            }else{
                swal(this.props.translatorStatus.message, {icon: "error"});
            }
        }
      });
    }


    onDeleteTranslator = ( translatorId, name ) => {
      swal({
        title: "Are you sure?",
        text: `Once Deleted, "${name}" will be permanently delete`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (willDelete) => {
        if (willDelete) {
            await this.props.deleteTranslator(translatorId)
            if(this.props.translatorDeleted.success){
              this.getTranslator(this.state.page, this.state.per_page, this.state.searchInput);
              swal(this.props.translatorDeleted.message, {
                  icon: "success",
              });
            }else{
                swal( this.props.translatorDeleted.message, {
                    icon: "error",
                });
            }
        } else {
            swal("Good job!", `${name}'s Account is Safe`, "success");
        }
      });
    }

    onTranslatorLogin = () => {
      socket.on('onTranslatorLogin', data => {
        if(data && data.response){
          const { page, total_pages, per_page, searchInput} = this.state;
          this.getTranslator( page, per_page, searchInput );
        }
      })
    }

    onTranslatorLogout = () => {
      socket.on('onTranslatorLogout', data => {
        if(data && data.response){
          const { page, total_pages, per_page } = this.state;
          this.getTranslator( page, per_page,'' );
        }
      })
    }

    convertDate = (date) => {
      var d = new Date(date);
      var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
      var monthName = d.toLocaleString('default', { month: 'short' })
      return  (monthName) + " " + d.getDate() + ", "+ d.getFullYear()
    }

    convertTime = (date) => {
      var d = new Date(date);
      return  d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    }

    render(){
        const { translators, translatorLoading, page, total_pages, per_page, current_number, total_number } = this.state;
        const paginate = e => {   
            this.setState({
                page:e.selected + 1
            }, ()=> {
                this.getTranslator( this.state.page, this.state.per_page, this.state.searchInput )
            })
        };
        return (
            <Container fluid className="p-sm-0">
                <Row className="mb-4">
                  <Col sm={12} className="p-0">
                    <div className="headTag d-flex">
                      <h3>Translators <span>Translator User Overview</span></h3>
                      {/* <Button variant="primary" className="ml-4 border-0 p-0" 
                      onClick={ () =>{ 
                          this.props.history.push('/add-translator')
                      }}  >
                      <span><i class="fas fa-plus-square"></i></span>Add Translator</Button> */}
                    </div>
                  </Col>
                  { translatorLoading ?
                    <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" > 
                        <Loader className="align-self-center" type="Audio" color="#000" height={30} width={30} />
                    </div>
                    :   
                      translators.length > 0 ?
                        <>
                          <Col sm={12}>
                            <Card className="projectTable usersTable border-0 rounded-0 p-2">
                              <Card.Body className="table-responsive">
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Country</th>
                                      <th>Languages</th>
                                      <th className="minWidth1">Last Login</th>
                                      <th className="minWidth2">Active Projects</th>
                                      <th className="minWidth3">Complete Translations</th>
                                      <th className="text-center status-table-tran">Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {translators.map(( t, i ) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <div className="translator-section">
                                                <Link to={`/view-translator/${t._id}`} >
                                                  <span className="projectIcon mr-3">
                                                    <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${t.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${t.profile}`  : require("assets/img/default-avatar.png")} 
                                                      onError={(e)=>{{
                                                      e.target.onerror = null;
                                                      e.target.src= require("assets/img/default-avatar.png");
                                                       }}}
                                                    alt="Profile" 
                                                    height ="50"
                                                    width="50"
                                                    style={{borderRadius: "50%"}}
                                                    href={`/view-translator/${t._id}`}
                                                    />
                                                    
                                                    <Badge variant={t.online_status ? "success" : "secondary"}></Badge>
                                                  </span>
                                                  <h3 style={{color:'black'}}>{t.fullname}</h3>
                                                </Link>                                            
                                          </div>
                                        </td>
                                        <td>{ t.country ? this.capitalizeFirstLetter(t.country) : ''}</td>
                                        <td>
                                          <p>{this.languageObjectConvert(t.languages)}</p>
                                        </td>
                                        <td>
                                          {t.online_status ? <h5> Currently Online</h5> : (t.last_login ? <h5>{this.convertDate(t.last_login)} <small className="d-block text-gray">{this.convertTime(t.last_login)}</small></h5> : <h5> Not Available</h5>) }
                                          
                                        </td>
                                        <td className="text-center">
                                          {this.translatorsActiveProjects(t._id)}
                                        </td>
                                        <td className="text-center">
                                          {this.completedTranslation(t.languages)}
                                        </td>
                                        <td className="text-center">
                                          <button type="button" class={t.status ? "btn btn-success" :  "btn btn-primary"} style={{cursor:'pointer'}} onClick={() => this.onStatusChange(t._id)} >{t.status ? "Active" : "Inactive"}</button>
                                        </td>
                                        <td className="text-center">
                                          <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                              {/* <i class="fas fa-ellipsis-v"></i> */}
                                              <span className="edit-icon-project">&#x22EE;</span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#" ><Link to={`/edit-translator/${t._id}`} className="d-block">Edit</Link></Dropdown.Item>
                                              <Dropdown.Item href="#" onClick = { () => this.onDeleteTranslator(t._id, t.fullname)}>
                                                Delete
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#"><Link to={`/view-translator/${t._id}`} className="d-block">View</Link></Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </td>
                                      </tr>
                                      )
                                    })}

                          
                                  </tbody>
                                </Table>                  
                              </Card.Body>
                            </Card>
                          </Col>
                            
                        </>
                        :
                        <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" >
                          <p style={{textTransform:'uppercase', textAlign:'center'}}>
                              Sorry, No Translators to show
                          </p>
                        </div>
                }
                { (translators.length > 0 && total_pages > 1 ) &&
                  <section className="pagination col-sm-12" >
                    <div className="col-sm-6 mt-3 ml-3">
                      <p>Showing {current_number} of {total_number} Translator</p>
                    </div>
                    <div className="col-sm-6">
                      <ReactPaginate
                          previousLabel={'<<'}
                          nextLabel={'>>'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={total_pages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={paginate}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                      />
                    </div>
                  </section>}
                    
                </Row>
              </Container>
        )
    }
}

const mapStateToProps = state => {
  const { translator } = state
  return {
      translators: translator.listAll,
      translatorStatus : translator.status,
      translatorDeleted : translator.delete,
  }
}
const dispatchAction = { listTranslator, activeInactiveTranslator, deleteTranslator }
export default connect(mapStateToProps, dispatchAction)(ListTranslator);
