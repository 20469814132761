import React, { Component } from 'react';
import Layout from './layouts/Layout';
import Login from './components/Login/Login';
import { Route, Switch , Redirect} from 'react-router-dom';
import AccountSetup from './components/FrontEnd/AccountSetup';
import {decodedToken, decodedTranslatorToken } from './helpers/decode-token';
import { socket } from './actions/fetchActions';
const atob = require('atob');
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './App.css';

class App extends Component{
    constructor(props){
      super(props)
      this.state = {
        flag :  1,
        fromLocation:  window.location.href.split('/') ,
       
      }
    }

    componentDidMount(){
      // this.redirectHitFromScript(this.state.fromLocation)
      if(decodedTranslatorToken !== null){
        socket.emit('come_online', {userId: decodedTranslatorToken.userId, type: 'translator'}) ;
      }else if(decodedToken !== null){
        socket.emit('come_online', {userId: decodedToken.userId, type: 'admin'}) ;
      }
    }

    // sleep = (milliseconds) => {
    //   const date = Date.now();
    //   let currentDate = null;
    //   do {
    //     currentDate = Date.now();
    //   } while (currentDate - date < milliseconds);
    // }

    // redirectHitFromScript = (array) => {
    //   let token = null;
    //   if(typeof array[5] !== undefined && array.length === 6){

    //     token = atob(array[5]);
    //     if(token){
    //         localStorage.setItem("scriptDBToken_admin", token);
    //     }
    //   } 
      
    //   if(array[3] == "project-detail" && array.length === 5){
    //     localStorage.setItem("translatorProjectId", array[4]);
    //   }
    // }

    getRoutes = () =>{
      const token = localStorage.getItem('scriptDBToken_admin') || localStorage.getItem('scriptDBToken_translator')
      if(this?.props?.admin || token){
        return <Layout/>
      }else{
        return (
          <Switch>
            <Route exact path='/login' name="Login Page" component={Login} key='LOGIN' />
            <Redirect to="/login" />
          </Switch>
        )
      }
    };

    render(){ 
      return (
        <>
          {this.getRoutes()}
        <React.Suspense>
         <Switch>
             <Route exact path="/account-setup/:token" name="ACCOUNT_SETUP" component={AccountSetup}/>
          </Switch>
        </React.Suspense>
        </>
      )
    }

}



const mapStateToProps = state => {
    
  const {admin} = state
  return { 
      admin: admin.admin, 
  }
}

// export default App;

export default connect(mapStateToProps)(App);  
