import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom"; 


// --------------------- Public Routes --------------------- //
import TranslatorDashboard from './components/FrontEnd/TranslatorDashboard';
import NotificationList from './components/FrontEnd/NotificationList';
import TranslatorProfile from './components/FrontEnd/TranslatorProfile';
import TranslatorProjectDetail from './components/FrontEnd/TranslatorProjectDetail';
import EditTranslatorFullScreen from './components/FrontEnd/EditTranslatorFullScreen';
import TranslateScript from './components/FrontEnd/TranslateScript';
import FrontEndChat from './components/FrontEnd/FrontEndChat';
import NotFound from './components/Errors/404'
import { isLoggedIn, isTranslatorLoggedIn } from './helpers/decode-token';



const TRANSLATOR_ROUTES = [
  {
    path:'/', key:"TRANSLATOR_DASHBOARD1", component: props => {
      if(!isTranslatorLoggedIn){
        if(!isLoggedIn){

          return <Redirect to="/login"/>
        }
      } 
        return <TranslatorRoutes {...props} />
    },
    routes: [
        { path: '/', key: 'TRANSLATOR_DASHBOARD1', exact: true, component: TranslatorDashboard},
        { path: '/notification-list', key: 'NOTIFICATION_LIST', exact: true, component: NotificationList},
        { path: '/translator-profile', key: 'TRANSLATOR_PROFILE', exact: true, component: TranslatorProfile},
        { path: '/project-detail/:projectId', key: 'TRANSLATOR_PROJECT_DETAIL', exact: true, component: TranslatorProjectDetail},
        { path: "/edit-translator-project-screen/:projectId", key: "TRANSLATOR_EDIT_SCREEN", exact: true, component:EditTranslatorFullScreen },
        { path: "/translator-side-chat", key: "TRANSLATOR_SIDE_CHAT", exact: true, component:FrontEndChat },
        { path: "/translator-script/:projectId", key: "TRANSLATOR_SCRIPT", exact: true, component:TranslateScript },
        
    ]
  }
];
export default TRANSLATOR_ROUTES;

function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        render={props => <route.component {...props} routes={route.routes} />}
      />
    );
}


export function TranslatorRoutes({ routes }) {
    return (
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <Route render={ props => <NotFound />} />
      </Switch>
    );
}