import { 
    ADMIN_LOGIN, 
    ADMIN_LOGOUT, 
    ADMIN_FORGOT_PASSWORD,
    UPDATE_ADMIN_PROFILE,
    GET_ADMIN,
    LIST_ADMIN
} from "../actions/types";

const initialState = { admin: '', listAdmin: ''}

export default ( state = initialState, action ) => {

    switch (action.type) {
        case ADMIN_LOGIN:
            return {
                ...state,
                admin: action.payload
            }
        case ADMIN_LOGOUT:
            return {}
        case ADMIN_FORGOT_PASSWORD:
            return {
                ...state,
                admin: action.payload
            }
        case GET_ADMIN:
            return {
                ...state,
                admin: action.payload
            }
        case LIST_ADMIN:
            return {
                ...state,
                listAdmin: action.payload
            }
        case UPDATE_ADMIN_PROFILE:
            return {
                ...state,
                admin: action.payload
            }
        default: return state;
    }

}