import { ACTIVE_INACTIVE_TRANSLATOR, TRANSLATOR_ADD, LIST_TRANSLATOR, DELETE_TRANSLATOR, LIST_COUNTRIES, VIEW_TRANSLATOR, UPDATE_TRANSLATOR, TRANSLATOR_LOGIN,  TRANSLATOR_LOGOUT, LIST_TOTAL_TRANSLATOR,UPDATE_SCRIPT_LANGUAGE_API,SUGGESTIONS_SCRIPT,UPDATE_PROJECTS , NOTIFY_STATUS }from './types';
import { apiRequest, formDataPost, formDataPut, translatorApiRequest, checkTokenExpire, handleError } from './fetchActions';
import { authHeader } from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()

export const addTranslator = ( data ) => async dispatch => {
    try {
        
        const result = await formDataPost(`v1/translator`, data);
        checkTokenExpire(result);
        await dispatch({ type: TRANSLATOR_ADD, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            handleError(error)
        }
    }
}

export const listTranslator = (page, per_page) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/translators?page=${page}&per_page=${per_page}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: LIST_TRANSLATOR, payload: result })
    } catch (error) {
        if(error && error.status === 401){
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}


export const activeInactiveTranslator = (translatorId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
        }
        const result = await apiRequest(`v1/translator/change-status/${translatorId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: ACTIVE_INACTIVE_TRANSLATOR, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const updateTranslator = ( data ) => async dispatch => {
    const check = ['undefined', undefined, 'null', null, 0, '0', '']
     var translatorId =  data.get('_id')

     if (check.includes(translatorId)){
        translatorId =  data.get('translatorId')

     }

    try {
        const result = await formDataPut(`v1/translator/${translatorId}`, data);
        checkTokenExpire(result);
        await dispatch({ type: UPDATE_TRANSLATOR, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            handleError(error)
        }
    }
}

export const deleteTranslator = ( translatorId ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
        }
        const result = await apiRequest(`v1/translator/${translatorId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: DELETE_TRANSLATOR, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const listCountries = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/countries`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: LIST_COUNTRIES, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const viewTranslator = ( translatorId ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
        }
        const result = await apiRequest(`v1/translator/${translatorId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: VIEW_TRANSLATOR, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const translatorLogin = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const translator = await translatorApiRequest("v1/translator/authenticate", requestOptions);
        if(translator.success  && translator.token){
            localStorage.setItem('scriptDBToken_translator', JSON.stringify(translator.token));
        }
        await dispatch({ type: TRANSLATOR_LOGIN, payload: translator })
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
}

export const translatorLogout = () => async dispatch => {
    localStorage.clear();
    dispatch({ type:TRANSLATOR_LOGOUT })
    window.location.replace('/login')
}

export const viewTranslatorAccountSetup = ( token ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8"
            },
        }
        const result = await apiRequest(`v1/account-setup/${token}`, requestOptions);
        await dispatch({ type: VIEW_TRANSLATOR, payload: result })

    } catch (error) {
    
        toast.dismiss()
        toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
    }
}

export const updateTranslatorAccountSetup = ( data ) => async dispatch => {
    try {
        const result = await formDataPut(`v1/account-setup-data/${data.get('_id')}`, data);
        await dispatch({ type: UPDATE_TRANSLATOR, payload: result })
    } catch (error) {
        
        handleError(error)
    }
}

export const updateTranslatorProfile = (formData, userId) => async dispatch => {
    try{
        const result = await formDataPost(`v1/user/${userId}`, formData );
        checkTokenExpire(result);
        await dispatch({ type: UPDATE_ADMIN_PROFILE, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            handleError(error)
        }
    }
}

export const viewTranslatorSide = ( translatorId ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
        }
        
        const result = await translatorApiRequest(`v1/translator/${translatorId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: VIEW_TRANSLATOR, payload: result })

    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const listTotalTranslator = (searchTranslator, adminId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest(`v1/total-translators?searchTranslator=${searchTranslator}&adminId=${adminId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: LIST_TOTAL_TRANSLATOR, payload: result })
    } catch (error) {
        if(error && error.status === 401){
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const updateScriptLanguageAPI=(projectId,data)=>async dispatch=>{
    try {
        const requestScriptUpdate = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
            body: JSON.stringify(data)
        }
        const result = await translatorApiRequest(`v1/update-script-translation/${projectId}`, requestScriptUpdate);
        checkTokenExpire(result);
        await dispatch({ type:  UPDATE_SCRIPT_LANGUAGE_API, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const suggestionsScript = (language, statement) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await translatorApiRequest(`v1/suggestion_script?language=${language}&statement=${statement}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: SUGGESTIONS_SCRIPT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const updateProjects = (projectId, data ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader() 
            },
            body: JSON.stringify(data)
        }
        const result = await translatorApiRequest(`v1/projects/${projectId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: UPDATE_PROJECTS, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}

export const sendStatusChange = ( projectId ,data ) => async dispatch => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": authHeader() 
                },
                body: JSON.stringify(data)
            }
            const result = await translatorApiRequest(`v1/send_Translation_notification/${projectId}`, requestOptions);
            checkTokenExpire(result);
            await dispatch({ type: NOTIFY_STATUS, payload: result })
        }catch (error) {
            if(error && error.status === 401){
                checkTokenExpire(error.data)
            }else{
                toast.dismiss()
                toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
            }
        }
    
}
