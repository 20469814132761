import { 
    ADMIN_LOGIN, 
    ADMIN_LOGOUT, 
    ADMIN_FORGOT_PASSWORD,
    GET_ADMIN,
    UPDATE_ADMIN_PROFILE,
    LIST_ADMIN
} from './types';
import { apiRequest, formDataPost, checkTokenExpire, translatorApiRequest } from './fetchActions';
import {authHeader} from '../helpers/auth-header';
import { toast } from "react-toastify";
import  jwt_decode  from 'jwt-decode';
toast.configure()


export const adminLogin = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const admin = await apiRequest("v1/authenticate", requestOptions);
        if(admin  && admin.success ){
            await dispatch({ type: ADMIN_LOGIN, payload: admin })
            if(admin.token){
                localStorage.setItem('scriptDBToken_admin', JSON.stringify(admin.token));
            }
        }
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
}


export const logout = () => async dispatch => {
   
    localStorage.clear();
    dispatch({ type:ADMIN_LOGOUT })
    window.location.replace('/login')
}

export const getAdmin = (id) => async dispatch => {
    const check = ['undefined', undefined, 'null', null, 0, '0', '']
    const translatorId = id ;
    try{
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
        }
        // var  result = await apiRequest( `v1/user/${id}`, requestOptions );
        var  result = await apiRequest( `v1/translator_admin/${id}`, requestOptions )
        // const result = await translatorApiRequest(`v1/translator/${translatorId}`, requestOptions)

        // if(check.includes(result)){
        //     const translatorId = id 
        //      result = await translatorApiRequest(`v1/translator/${translatorId}`, requestOptions);
        //      console.log("result ---translatore-admin" , result )

        // }
        checkTokenExpire(result);
        await dispatch({ type: GET_ADMIN, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const updateProfile = (formData, userId) => async dispatch => {
    try{
        const result = await formDataPost(`v1/user/${userId}`, formData );
        checkTokenExpire(result);
        await dispatch({ type: UPDATE_ADMIN_PROFILE, payload: result})
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong, Please try again later', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}



export const resetPassword = ( data ) => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify( data )
        }
        const result = await apiRequest(`v1/reset-password/link`, requestOptions);
        await dispatch({ type: ADMIN_FORGOT_PASSWORD, payload: result })
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
}   

export const listTotalAdmin = (searchAdmin, translatorId) => async dispatch => {
    const check = ['undefined', undefined, 'null', null, 0, '0', '']
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await translatorApiRequest(`v1/total-admins?searchAdmin=${searchAdmin}&translatorId=${translatorId}`, requestOptions);
        checkTokenExpire(result);
        await dispatch({ type: LIST_ADMIN, payload: result })
    } catch (error) {
        if(error && error.status === 401){
        }else{
            toast.dismiss()
            toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
        }
    }
}


export const socialLogin = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        // const admin = await apiRequest("v1/admin/ssoLogin", requestOptions);   admin_translator/ssoLogin
        const admin = await apiRequest("v1/admin_translator/ssoLogin", requestOptions);
        if(admin && admin.success){
            if(admin.token){
                const decodeToken = jwt_decode(admin.token) ; 
                if(decodeToken.role[0] === 'admin'){
                    localStorage.setItem('scriptDBToken_admin', JSON.stringify(admin.token));
                }else{
                    localStorage.setItem('scriptDBToken_translator', JSON.stringify(admin.token));
                }
                await dispatch({ type: ADMIN_LOGIN, payload: admin })
                window.location.replace('/')
            }
        }
    }catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
}