export function authHeader() {
    // return authorization header with jwt token
    let token = JSON.parse(localStorage.getItem('scriptDBToken_admin'));
    if (token) {
        return `Bearer ${token}` ;
    } else {
        let token1 = JSON.parse(localStorage.getItem('scriptDBToken_translator'));
        if (token1) {
            return `Bearer ${token1}` ;
        } else {
            return null;
        }
    }
}