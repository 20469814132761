import React, { Component, Fragment } from "react";
import { Button, Card, Container, Row, Col, Table, Media, Dropdown } from "react-bootstrap";
import { listProject, activeInactiveProject, deleteProject , viewProject , updateProject} from '../../actions/projectActions';
import { isLoggedIn, decodedToken, isTranslatorLoggedIn, decodedTranslatorToken } from '../../helpers/decode-token';
import { getAdmin } from '../../actions/adminActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { toast } from'react-toastify';
import swal from 'sweetalert';
import ReactHtmlParser from 'react-html-parser';
import * as check from '../../helpers/validation';
import {ENV} from "../../env";

import axios from 'axios';


toast.configure()


class Project extends Component{

  constructor( props ){
    super( props );
    this.state = {
        projects:[],
        page:1,
        current_number:'',
        total_number:'',
        errors: {},
        per_page:10,
        total_pages:null,
        projectLoading:false,
        searchInput:'',
        admin:'',
        fields: {
          projectname:'',
          due_date:new Date(),
          translation_tasks:[],
          language_suggestion:'',
          languages:[],
          activities:'',
          comments:'',
          scriptFile:'',
          edit_translator:'admin',
          each_translation_status :[]
        }
      } 
    }

    componentDidMount(){
        const { page, total_pages, per_page } = this.state;
        this.getProject( page, per_page );
        this.fetchUser()
    }

    fetchUser = async () => {
      await this.props.getAdmin(decodedToken.userId)
      if(this.props.admin.success){
          this.setState({
              admin: this.props.admin.user,
          })
          
      }else{
          toast.dismiss()
          toast.error(this.props.admin.message, {position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
      }
  }

    getProject = async ( page, per_page, search ) => {
        this.setState({ projectLoading: true })
        await this.props.listProject( page, per_page, search )
        if(this.props.projects.response){
            this.setState({
              projects: this.props.projects.projects,
              total_pages: this.props.projects.meta.total_pages,
              current_number: this.props.projects.projects.length,
              total_number: this.props.projects.meta.total,
              projectLoading: false
            })
        }else{
            this.setState({ projectLoading: false })
            toast.dismiss()
            toast.error( this.props.projects.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }


    getProjectDetails = async (projectId)=>{
      let fields = this.state.fields;
      this.setState({loader:true})
      await this.props.viewProject(projectId)
      // console.log("viewProjectdetail" , this.props.viewProjectdetail)
      if(this.props.viewProjectdetail.success){
          fields['projectname'] = this.props.viewProjectdetail.data.projectname;
          fields['due_date'] = new Date(this.props.viewProjectdetail.data.due_date);
          fields['translation_tasks'] = this.props.viewProjectdetail.data.translation_tasks;
          fields['languages'] = (this.props.viewProjectdetail.project_languages);
          fields['comments'] = (this.props.viewProjectdetail.data.comments);
          fields['activities'] = this.props.viewProjectdetail.data.activities;
          fields['scriptFile'] = this.props.viewProjectdetail.data.script;
          fields['each_translation_status'] = this.props.viewProjectdetail.data.each_translation_status;
          var projectStatus = this.props.viewProjectdetail.data.status
          this.setState({fields})

          if( fields['activities'] ){
            const user = new Object();
            user.userDetail = decodedToken;
            user.activity = projectStatus === true ? ' Activate This Project ' : ' Closed This Project ';
            user.createdAt = moment().format()
            fields['activities'].push(user)
            this.setState({fields})
          }
          let data ={
            ...this.state.fields,
            
          }
          await this.props.updateProject(projectId,data);
          if(this.props.projectUpdated.success){
            this.setState({ disable: false })
            toast.success(this.props.projectUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
          }else{
            this.setState({ disable: false })
            toast.dismiss()
            toast.error( this.props.projectUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
          }


          // console.log("fields['activities']" ,  fields['activities'] ) ;
      }else{
          if(this.props.viewProjectdetail.message == "Server encountered error"){
              this.setState({
                error:true
              })
          }
          this.setState({loader:false})
      }
    }
    

    convertDate = ( TZdate ) =>{
      // console.log("TZdate" , TZdate)

        let date = moment(TZdate).format('MMMM Do YYYY')

        return(
          date 
         ) 
       
    }
                              
    convertDueDate = ( TZdate ) =>{
        var date = TZdate.replace(/["]/g, '')
        date = moment(date).format('MMMM Do YYYY')
        return(date) 
    }

    convertDueDateText = ( TZdate,status) =>{
        var date = TZdate.replace(/["]/g, '')
        let date2 = new Date( date )
        var date1 = new Date();
        var diffTime = Math.abs(date2 - date1);
        var value = date2 - date1;
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        var string = '';
        if(diffDays > 0 && value > 0){
          string = '<span class="text-green d-block">'+diffDays+' Days till Due<span>';
        }
        if(diffDays == 0){
          string = '<span class="text-due d-block">Due Today</span>';
        }
        if(diffDays > 0 && value < 0){
          diffDays = Math.abs(diffDays)
          string = '<span class="text-red d-block">'+diffDays+' Days Overdue</span>';
        }

        if(!status){
         
          string = '<span class="text-green d-block">Completed</span>';
        }

        return string;
    }

    convertTranslator = ( languages ) =>{

        var string = '';
        if(languages.length == 1){
          string = '<h5>'+languages[0]?.translatorId.fullname +'<small class="d-block text-gray">'+languages[0]?.translatorId.country +' Partner</small></h5>';
        }

        if(languages.length > 1){
          var nameArray = languages.map(x => typeof x.language === 'object' ? x.language.language : x.language);
          string = '<h5>'+languages.length +' languages <small class="d-block text-gray">'+nameArray.join(', ') +'</small></h5>';
        }       
        return string;
    }

    showImage = ( translation_tasks ) =>{

        var string = '';
        if(translation_tasks.length > 1){
          string = <img src={require("assets/img/project-1.jpg")} alt="Icon" className="mr-3" />;
          return string;
        }

        if(translation_tasks[0] == "Script"){
          string = <img src={require("assets/img/project-2.jpg")} alt="Script" className="mr-3" />;
          return string;
        }  

        if(translation_tasks[0] == "Consideration Pathway"){
          string = <img src={require("assets/img/project-3.jpg")} alt="CP" className="mr-3" />;
          return string;
        }   

         if(translation_tasks[0] === "Headline/CTA" || translation_tasks[0] === "General Document"){
          string = <img src={require("assets/img/project-4.jpg")} alt="GD" className="mr-3" />;
          return string;
        } 
    }

    onDeleteProject = ( projectId, name ) => {
      swal({
        title: "Are you sure?",
        text: `Once Deleted, "${name}" will be permanently delete`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then(async (willDelete) => {
        if (willDelete) {
            await this.props.deleteProject(projectId)
            if(this.props.projectDeleted.success){
              this.getProject(this.state.page, this.state.per_page);
              swal(this.props.projectDeleted.message, {
                  icon: "success",
              });
            }else{
                swal( this.props.projectDeleted.message, {
                    icon: "error",
                });
            }
        } else {
            swal("Good job!", `${name}'s is Safe`, "success");
        }
      });
    }

    onStatusChange = async (id,projectname,languages) => {
      const webHookURL= ENV.slackWebHookLink;
      const data={
        "text":`Name:${this.state.admin.fullname}\n Email:${this.state.admin.email}\n Project:${projectname} Message:translation complete`
      }
      swal({
        title: "Are you sure?",
        text: "Staus Will be Changed",
        icon: "warning",
        buttons: ['Cancel', 'Yes'],
        dangerMode: true,
      })
      .then(async (willDelete) => {
        if (willDelete) {
            await this.props.activeInactiveProject(id)
            if(this.props.projectStatus.success){
              this.getProjectDetails(id)
              if(this.props.projectStatus.message==="Status is Completed"){
                await axios.post(webHookURL,JSON.stringify(data),{
                  withCredentials:false,
                  transformRequest:[(data,headers)=>{
                    delete headers.post["Content-Type"]
                    return data;
                  }]  
                })
              }  
                this.getProject(this.state.page, this.state.per_page);
                swal(this.props.projectStatus.message, {icon: "success"});
            }else{
                swal(this.props.projectStatus.message, {icon: "error"});
            }
        }
      });
    }

    render(){
        const { projects, projectLoading, page, total_pages, per_page, current_number, total_number } = this.state;
        const paginate = e => {   
            this.setState({
                page:e.selected + 1
            }, ()=> {
                this.getProject( this.state.page, this.state.per_page )
            })
        };
        return (
            <>
              <Container fluid className="p-sm-0">
                <Row className="mb-4">
                  <Col sm={12} className="p-0">
                    <div className="headTag d-flex">
                      <h3>Projects <span>Project Panel Overview</span></h3>
                      <Button variant="primary" className="ml-4 border-0 p-0" onClick={(e) => {
                    this.props.history.push('/add-projects');
                  }}><span><i className="fas fa-plus-square"></i></span>New Project</Button>
                    </div>
                  </Col>
                  { projectLoading ?
                    <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" > 
                        <Loader className="align-self-center" type="Audio" color="#000" height={30} width={30} />
                    </div>
                    : 
                    projects.length > 0 ?
                      <>
                      <Col sm={12}>
                        <Card className="projectTable usersTable border-0 rounded-0">
                          <Card.Body className="table-responsive">
                            <Table>
                              <thead>
                                <tr>
                                  <th>Project Name</th>
                                  <th>Tasks</th>
                                  <th>Due Date</th>
                                  <th>Translator</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                              {projects.map(( t, i ) => {
                                  return (
                                  <tr key={i}>
                                    <td>
                                    <Link to={`/edit-project/${t._id}`} >
                                      <Media className="">
                                        {(this.showImage(t.translation_tasks))}
                                        <Media.Body>
                                          <h5>{t.projectname}</h5>
                                          <p>Convention Name</p>
                                          <p>Created on {this.convertDate( t.createdAt )}</p>
                                        </Media.Body>
                                      </Media>
                                    </Link>  
                                    </td>
                                    <td>{( t.translation_tasks.join('/ ') )} </td>
                                    <td>
                                    <strong> {this.convertDueDate(t.due_date)}</strong>{ReactHtmlParser(this.convertDueDateText(t.due_date, t.status))}
                                    </td>
                                    <td>
                                        { ReactHtmlParser(this.convertTranslator(t.languages))}
                                    </td>
                                    <td>
                                      {t.status ? <h4 className="text-active text-uppercase" style={{cursor:'pointer'}} onClick={() => this.onStatusChange(t._id, t.projectname, t.languages)} >Active</h4>: <h4 className="text-inactive text-uppercase" style={{cursor:'pointer'}} onClick={() => this.onStatusChange(t._id)}>Closed</h4>}
                                      
                                    </td>
                                    <td>
                                      <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                          <span className="edit-icon-project">&#x22EE;</span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item href="#"><Link className="d-block" to={`/edit-project/${t._id}`}>Edit</Link></Dropdown.Item>
                                          <Dropdown.Item href="#" onClick = { () => this.onDeleteProject(t._id, t.projectname)
                                              }>Delete</Dropdown.Item>
                                          
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                  )
                                })}
                              
                              </tbody>
                            </Table>                  
                          </Card.Body>
                        </Card>
                      </Col>
                      </>
                      :
                      <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" >
                        <p style={{textTransform:'uppercase', textAlign:'center'}} >
                            Sorry, No Projects to show
                        </p>
                      </div>
                    }
                    { (projects.length > 0 && total_pages > 1 ) &&
                              <Col md={12}>
                               <section className="pagination">
                                <div className="col-sm-6 mt-3 ml-3 pl-lg-0">
                                  <p>Showing {current_number} of {total_number} Projects</p>
                                </div>
                                <div className="col-sm-6 pr-lg-0">
                                  <ReactPaginate
                                      previousLabel={'Prev'}
                                      nextLabel={'Next'}
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={total_pages}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={3}
                                      onPageChange={paginate}
                                      containerClassName={'pagination'}
                                      subContainerClassName={'pages pagination'}
                                      activeClassName={'active'}
                                  />
                                </div>
                                </section>
                                </Col>}
                </Row>
              </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
const { admin,project } = state
return {
    projects: project.listAll,
    projectStatus : project.status,
    projectDeleted : project.delete,
    admin: admin.admin,
    viewProjectdetail : project.viewProject,
    projectUpdated : project.updateProjectApi,
}
}
const dispatchAction = { listProject, deleteProject, activeInactiveProject, getAdmin , viewProject , updateProject}
export default connect(mapStateToProps, dispatchAction)(Project);

