
import { ALL_LANGUAGES_SUGGESTIONS, ADD_PROJECT, LIST_PROJECT, DELETE_PROJECT, ACTIVE_INACTIVE_PROJECT, UPDATE_PROJECT, VIEW_PROJECT, ALL_USERS, SPREAD_SHEET_CONTENT,SCRIPT_LIST,FETCH_PROJECT,SINGLE_SCRIPT,UPDATE_SCRIPT_LANGUAGE,UPDATE_PROJECT_API,SCRIPT_SUGGESTIONS,SEARCH_SCRIPT,GOOGLE_DRIVE,DOWNLOAD_FILE,NOTIFY_COMPLETE_TRANSLATION} from "../actions/types";
const initialState = { all_language_suggestions: '', add : '', listAll : '', status : '', projectUpdated : '', viewProject : '', all_users : '',project_data:'',single_script_data:'',update_script_data:'',updateProjectApi:'',allScriptSuggestion:'',searchScriptData:'',googleDrive:'',fileDownload:'',completeTranslationStatus:''}

export default ( state = initialState, action ) => {

    switch (action.type) {
        case ALL_LANGUAGES_SUGGESTIONS:
            return {
                ...state,
                all_language_suggestions: action.payload
            }
        case ADD_PROJECT:
            return {
                ...state,
                add: action.payload
            }
        case LIST_PROJECT:
            return {
                ...state,
                listAll: action.payload
            }
        case DELETE_PROJECT:
            return {
                ...state,
                delete: action.payload
            }
        case ACTIVE_INACTIVE_PROJECT:
            return {
                ...state,
                status: action.payload
            }
        case UPDATE_PROJECT:
            return {
                ...state,
                projectUpdated: action.payload
            }
        case VIEW_PROJECT:
            return {
                ...state,
                viewProject: action.payload
            }
        case ALL_USERS:
            return {
                ...state,
                all_users: action.payload
            }
        case SPREAD_SHEET_CONTENT:
            return {
                ...state,
                spread_sheet: action.payload
            }
         case SCRIPT_LIST:
            return {
                ...state,
                scriptlist: action.payload
            }
        case FETCH_PROJECT:
            return {
                ...state,
                project_data:action.payload

            }
        case SINGLE_SCRIPT:
            return{
                ...state,
                single_script_data:action.payload
            }
        case UPDATE_SCRIPT_LANGUAGE:
            return{
                ...state,
                update_script_data:action.payload
            } 
        case UPDATE_PROJECT_API:
            return{
                ...state,
                updateProjectApi:action.payload
            } 
        case SCRIPT_SUGGESTIONS :
            return{
                ...state,
                allScriptSuggestion:action.payload
            } 
        case SEARCH_SCRIPT:
            return{
                ...state,
                searchScriptData:action.payload
            }   
        case GOOGLE_DRIVE:
            return{
                ...state,
                googleDrive:action.payload
            }    
        case DOWNLOAD_FILE:
            return{
                ...state,
                fileDownload:action.payload
            }
        case NOTIFY_COMPLETE_TRANSLATION:
            return{
                ...state,
                completeTranslationStatus:action.payload
            }    
        default: return state;
    }
       

}
