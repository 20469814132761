import React, {Component} from 'react';
import { Table, Media, Dropdown, Badge, Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import { viewTranslator } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { toast } from'react-toastify';
import swal from 'sweetalert';
import ReactHtmlParser from 'react-html-parser';

import * as check from '../../helpers/validation';

const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
toast.configure()

class ViewTranslator extends Component{

    constructor(props){
        super(props)

        this.state = {
            translatorId:props.match.params.translatorId,
            showMessage:'',
            errors: {},
            disable: false,
            countries:{},
            fields:null,
            loader:true,
            projects:[],
        }

        this.initialState = JSON.parse(JSON.stringify(this.state));
    }

    componentDidMount(){

        this.getTranslatorDetails(this.state.translatorId);

    }

    getTranslatorDetails = async (translatorId)=>{
        this.setState({loader:true})
        await this.props.viewTranslator(translatorId)
        if(this.props.viewTranslatordetail.success){
            this.props.viewTranslatordetail.data.languages = this.props.viewTranslatordetail.data.languages.map(lang => lang.language)

            this.setState({
                fields:this.props.viewTranslatordetail.data,
                projects:this.props.viewTranslatordetail.projects,
                loader:false,
            })
        }else{
            this.setState({loader:false})
        }
    }

    ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;
     
      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

    convertDate = ( TZdate ) =>{
        var dateFormat = moment()
        let date = new Date( TZdate )
        return(
          date= dateFormat.format('LL')
         ) 
        var d = new Date(date);
    }
    
      convertDueDate = ( TZdate ) =>{
          var dateFormat = moment()
          let date = new Date( TZdate )
          return(
            date= dateFormat.format('LL')
          ) 
          var d = new Date(date);
          
      }

    convertDueDateText = ( TZdate,status) =>{
        let date2 = new Date( TZdate )
        var date1 = new Date();
        var diffTime = Math.abs(date2 - date1);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        var string = '';
        if(diffDays > 0){
          string = '<span class="text-green d-block">'+diffDays+'Days till Due<span>';
        }
        if(diffDays == 0){
          string = '<span class="text-due d-block">Due Today</span>';
        }
        if(diffDays < 0){
          diffDays = Math.abs(diffDays)
          string = '<span class="text-red d-block">'+diffDays+'Days Overdue</span>';
        }

        if(!status){
         
          string = '<span class="text-green d-block">Completed</span>';
        }

        return string;
    }

    convertTranslator = ( languages ) =>{

        var string = '';
        if(languages.length == 1){
          string = '<h5>'+languages[0].translatorId.fullname +'<small class="d-block text-gray">'+languages[0].translatorId.country +' Partner</small></h5>';
        }

        if(languages.length > 1){
          var nameArray = languages.map(function (el) { return el.language; });
          string = '<h5>'+languages.length +' languages <small class="d-block text-gray">'+nameArray.join(', ') +'</small></h5>';
        }       
        return string;
    }

    render(){
        const { countries, errors, disable, fields, loader, projects, translatorId } = this.state;
       
        return (
            <Container fluid className="p-sm-0">
                <Row className="mb-4">
                    {   loader ?
                            <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5"> 
                                <Loader type="Audio" color="#000" height={30} width={30} />
                            </div>
                            : 
                            <>
                                <Col sm={12} className="p-0">
                                    <div className="headTag d-flex align-items-center">
                                    <h3>`{fields['fullname']}` Translator <span>Translator User Overview</span></h3>
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <Card>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                <Col md={3} className="view-translate-badge">
                                                <label className="imageUpload" for="imageUpload">
                                                <img src={this.ImageExist(`${process.env.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${fields['profile']}`) ? `${process.env.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${fields['profile']}`  : require("assets/img/placeholder.png")}  
                                                onError={(e)=>{{
                                                e.target.onerror = null;
                                                e.target.src= require("assets/img/placeholder.png");
                                                }}}
                                                alt="Profile" />
                                                <Badge variant={fields['online_status'] ? "success" : "secondary"}></Badge>
                                                </label>
                                                <Button variant="primary" className="ml-4 mt-3" onClick={() => this.props.history.push(`/chat/${translatorId}`)} ><img src={require("assets/img/speech-bubble.png")} alt="..." className="mr-2" />Send Message</Button>
                                                </Col>
                                                <Col md={9}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control type="text" placeholder="Fullname"   value={this.state.fields['fullname']} readonly='true' /> 
                                                                                            
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control type="text" placeholder="Email Address"  value={this.state.fields['email']} readonly='true' />
                                                            
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control type="text" placeholder="Cell phone"  value={this.state.fields['cellphone']} readonly='true' />
                                                                
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Control type="text" placeholder="Cell phone"  value={this.state.fields['country']} readonly='true' />
                                                                                        
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="form-group">
                                                                <label htmlFor="languages">Languages</label>
                                                                {fields['languages'].length > 0 &&
                                                                <p>
                                                                    {fields['languages'].map((t, i) => (
                                                                    <strong style={{display:'inline'}} className="mr-2" key={i}> {t} </strong>
                                                                    ))}
                                                                </p>
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="form-group">
                                                                <label htmlFor="languages">Completed Translations</label>
                                                                <h4 className="mt-0">0</h4>
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                                                        </Col>
                                                    </Row>
                                                    
                                                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="mb-5 mt-4">
                                                    <label>Skills / Awards</label>
                                                    <div className="awardSec mb-3">
                                                        <Badge variant="primary"><i class="fas fa-award"></i> 100% Accuracy</Badge>
                                                        <Badge variant="primary" className="mt-2"><i class="fas fa-shield-alt"></i> 24 Hours Turn</Badge>
                                                    </div>
                                                    <div className="awardSec">
                                                        <Badge variant="primary">Video Editing</Badge>
                                                        <Badge variant="primary" className="mt-2">Copywriting</Badge>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="projectTable">
                                                        <h4>Translation History</h4>
                                                        { projects.length > 0 ?
                                                        <>
                                                        <div className="table-responsive">
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                    <th>Project Name</th>
                                                                    <th>Tasks</th>
                                                                    <th>Due Date</th>
                                                                    <th>Translator</th>
                                                                    <th>Status</th>
                                                                    {/* <th></th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                {projects.map(( t, i ) => {
                                                                    return (
                                                                    <tr  key={i}>
                                                                        <td>
                                                                        <Media className="align-items-center">
                                                                            <img src={require("assets/img/project-1.jpg")} alt="..." className="mr-3" />
                                                                            <Media.Body>
                                                                            <h5>{t.projectname}</h5>
                                                                            <p>Convention Name</p>
                                                                            <p>Created on {()=>this.convertDate( t.createdAt )}</p>
                                                                            </Media.Body>
                                                                        </Media>
                                                                        </td>
                                                                        <td>{( t.translation_tasks.join('/ ') )} </td>
                                                                        <td>
                                                                        <p><strong> {()=>this.convertDueDate(t.due_date)}</strong>{ReactHtmlParser(this.convertDueDateText(t.due_date, t.status))}</p>
                                                                        </td>
                                                                        <td>
                                                                        {ReactHtmlParser(this.convertTranslator(t.languages))}
                                                                        </td>
                                                                        <td>
                                                                        {t.status ? <h4 className="text-active text-uppercase" style={{cursor:'pointer'}}  >Active</h4>: <h4 className="text-inactive text-uppercase" style={{cursor:'pointer'}} >Closed</h4>}
                                                                        
                                                                        </td>
                                                                        
                                                                    </tr>
                                                                    )
                                                                    })}
                                                                        
                                                                </tbody>
                                                                </Table> 
                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                        <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5" >
                                                            <h4 style={{textTransform:'uppercase', textAlign:'center'}}>
                                                                Sorry, No Prjoects to show
                                                            </h4>
                                                        </div>
                                                        </>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>            
                                    </Card.Body>
                                    </Card>
                                </Col>
                            </>
                    }
                </Row>
              </Container>
        )
    }
}

const mapStateToProps = state => {
  const { translator } = state
  return {
    viewTranslatordetail : translator.viewTranslator,
  }
}
const dispatchAction = { viewTranslator }
export default connect(mapStateToProps, dispatchAction)(ViewTranslator);
