import  jwt_decode  from 'jwt-decode';

let token = null;
if(typeof localStorage.getItem('scriptDBToken_admin') == 'string'){
    token = (localStorage.getItem('scriptDBToken_admin'));
}else{
    token = JSON.parse(localStorage.getItem('scriptDBToken_admin'))
}


export const isLoggedIn = token ? true : false

export const decodedToken = token ?  jwt_decode(token) : null 

let translator_token = JSON.parse(localStorage.getItem('scriptDBToken_translator')) || null

export const isTranslatorLoggedIn = translator_token ? true : false

export const decodedTranslatorToken = translator_token ?  jwt_decode(translator_token) : null 



