import React, { Component } from 'react';
import { Button, Card, Container, Row, Col, Form, Media, Badge, ListGroup, ListGroupItem, ProgressBar, TabPane, Tabs, Tab, Dropdown, ButtonGroup ,  Tooltip, OverlayTrigger } from "react-bootstrap";
import { viewProjectEmail } from '../../actions/projectActions';
import { updateScriptLanguageAPI,suggestionsScript , sendStatusChange } from '../../actions/translatorActions';
import { updateProjects } from '../../actions/translatorActions';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { decodedToken } from '../../helpers/decode-token';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { socket } from '../../actions/fetchActions';
import 'bootstrap/dist/css/bootstrap.min.css';
import { convertToObject } from 'typescript';
import { decodedTranslatorToken } from '../../helpers/decode-token';
import TimePicker from 'rc-time-picker';
import moment, { lang } from 'moment';
import {ENV} from "../../env";
import axios from 'axios';  


class TranslateScript extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectId: props.match.params.projectId,
      copied: false,
      backupScripts: [],
      row: [],
      input: '',
      output: '',
      options: [],
      selectScript: false,
      TranslateText: '',
      scriptData: [],
      fields: {
        projectname: '',
        due_date: new Date(),
        translation_tasks: [],
        language_suggestion: '',
        languages: [],
        activities: [],
        scriptFile: [],  // is used to store uploaded File from drag and drop
        comments: '',
        each_translation_status : [] , 
      },
      selectText: '',
      tranlatedLanguage: '',
      scriptTranslatedText: '',
      selectedOriginalTextIndex: null,
      UpdateScriptDAta: [],
      isActive: false,
      searchScriptText: '',
      updateScript: [],
      isChecked:[],
      start:'',
      end:'',
      startTime:moment().startOf("day"),
      endTime:moment().startOf("day"),
      boxColor:'',
      status:'',
      suggestion:'',
      selectSuggestion:'',
      isCheckedTime:false
    }
  }

  componentDidMount() {
    this.getProjectDetails(this.state.projectId);
    // this.getScriptContent(this.state.projectId);
  }

  getProjectDetails = async (projectId) => {
    let fields = this.state.fields;
    this.setState({ loader: true })
    await this.props.viewProjectEmail(projectId)
    this.setState({
      scriptData: this.props.viewProjectdetail,
      loader: false,
    })
    if (this.props.viewProjectdetail.success) {
      fields['projectname'] = this.props.viewProjectdetail.data.projectname;
      fields['due_date'] = new Date(this.props.viewProjectdetail.data.due_date);
      fields['translation_tasks'] = this.props.viewProjectdetail.data.translation_tasks;
      fields['languages'] = this.props.viewProjectdetail.project_languages.filter(p => p.translatorId._id === decodedTranslatorToken.userId);
      fields['script'] = this.props.viewProjectdetail.data.script;
      fields['comments'] = (this.props.viewProjectdetail.data.comments);
      fields['activities'] = this.props.viewProjectdetail.data.activities;
      fields['scriptFile'] = this.props.viewProjectdetail.data.script;
      fields['each_translation_status'] = this.props.viewProjectdetail.data.each_translation_status

      // fields['spread_sheet'] = this.props.viewProjectdetail.data.spread_sheet;
      this.setState({
        fields,
        loader: false,
      },

        //  () => {
        //     this.spreadSheetChange();
        // }

      )
    } else {
      this.setState({ loader: false })
    }
  }

  handleSelectedText = async(originalStatement, index,translatedLanguage) => {
    this.setState({ selectedOriginalText: originalStatement, selectedOriginalTextIndex: index})
    if(translatedLanguage){
      this.setState({scriptTranslatedText:translatedLanguage})
    }else{
      this.setState({scriptTranslatedText:''})
    }

    if(index===this.state.boxColor){
      this.setState({boxColor:null})
    }else{
      this.setState({boxColor:index})
    }
    let language=this.state.tranlatedLanguage
    let statement=originalStatement
    if (this.state.tranlatedLanguage){
      if (statement.text) {
        await tthis.props.suggestionsScript(language, statement.text)
        
        if(this.props.fetchScriptSuggestion.success){
            if(this.props.fetchScriptSuggestion.suggestions.length > 0 ){

              this.setState({ suggestion: this.props.fetchScriptSuggestion.suggestions })

            }else{

              this.setState({suggestion : ''})
            }
        }
      }else{

          await this.props.suggestionsScript(language, statement)
          if(this.props.fetchScriptSuggestion.success){
            if(this.props.fetchScriptSuggestion.suggestions.length > 0 ){

              this.setState({ suggestion: this.props.fetchScriptSuggestion.suggestions })

            }else{

              this.setState({suggestion : ''})

            }
          }
      }
       
    }
  }

  handleSelectLanguage = (e) => {
    this.setState({ tranlatedLanguage: e.target.value });
    if(e.target.value){
      this.setState({selectedOriginalText:'',scriptTranslatedText:'',boxColor:''})
    }
  }

  // handleSelectedText = (originalStatement, index) => {
  //   this.setState({ selectedOriginalText: originalStatement, selectedOriginalTextIndex: index })
  // }

  handleTranslateText = (e,) => {
    const { value } = e.target;
    this.setState({ scriptTranslatedText: value })
  }

  submitTranlation = async (e) => {
    e.preventDefault();
    const { fields, selectedOriginalTextIndex, tranlatedLanguage, scriptTranslatedText,startTime,endTime,start,end} = this.state
    // if( startTime!==''&& endTime!=='' &&  startTime<endTime){
      let languages = fields['languages'].map(lang => typeof lang.language === 'object' ? lang.language.language : lang.language)
    let scriptFile = fields['scriptFile']
    let statement = scriptFile[selectedOriginalTextIndex]
    statement[`${tranlatedLanguage}`] = scriptTranslatedText
    if(statement.status){
      statement.status[tranlatedLanguage] = false
    } 
    if(this.state.isCheckedTime){
        if(!statement.time){
          statement.time = {}
          languages.forEach(lkey => {
            if(tranlatedLanguage === lkey){
              statement.time[lkey] = {start:moment(startTime).format("hh:mm:ss"),end:moment(this.state.endTime).format("hh:mm:ss")}
            }else{
              statement.time[lkey] = {start:"00:00:00",end:"00:00:00"}
            }
          })
        }else{
          if(statement.time){
            if(start && end){
            statement.time[tranlatedLanguage]={start:start ,end:end} 
            }else{
            statement.time[tranlatedLanguage]={start:"00.00.00" ,end:"00.00.00"}
            }
          }
        }
        scriptFile[selectedOriginalTextIndex] = statement;
    }else{
      scriptFile[selectedOriginalTextIndex] = statement;
    }
    fields.scriptFile = scriptFile
    this.setState({ fields: fields })
    let data = {
      script: scriptFile
    }
      await this.props.updateScriptLanguageAPI(this.state.projectId, data);
    // }else{
    //      if(startTime==''&& endTime==''){
    //       alert("please select the start and end time of translation")
    //      }
    //      else if(startTime>=endTime){
    //       alert("please select a valid time start time always less then end time")
    //      }
    // } 
    this.setState({ scriptTranslatedText: '' });
    this.setState({ selectedOriginalText: '' });
    this.setState({ startTime: moment().startOf("day"), endTime: moment().startOf("day") })
  }  
  
  handleSearchScript = (e) => {
    const { value } = e.target;
    this.setState({ searchScriptText: value });

    if (this.state.searchScriptText !== 0) {
      const resultData = this.state.fields['scriptFile'].filter((item) => {
        if(item.English.text){
          return (
            Object.values(item.English).join('').toLowerCase().includes(this.state.searchScriptText.toLowerCase())
          )
        }else{
          return(
            Object.values(item.English).join('').toLowerCase().includes(this.state.searchScriptText.toLowerCase())
          )
        } 
      })
      this.setState({ updateScript: resultData })
    }
    else {
      this.setState({ updateScript: this.state.fields['scriptFile'] })
    }
  }

  handleCheckBox = (e, ind) => {
    let isChecked = this.state.isChecked;
    if (e.target.checked) {
      isChecked.push(ind)
    } else {
      if (isChecked.indexOf(ind) !== -1) {
        isChecked = isChecked.filter(item => item !== ind)
      }
    }
    this.setState({ isChecked })
  }
    
  

  handleDownLoadScript = () => {
    const scriptFile = this.state.fields['scriptFile']
    const newScript = scriptFile.filter((item) => this.state.isChecked.includes(item.Index))
    const fileData = JSON.stringify(newScript);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "script.text";
    link.href = url;
    link.click();
    this.setState({isChecked:[]})
  }

  handlestart=(v,ind)=>{
    let startTime=moment(v._d).format('hh:mm:ss');
    const { fields,tranlatedLanguage} = this.state
    let statement = fields['scriptFile'][ind]
    if(statement.English.start){
      statement.English.start=startTime
    }
    if(statement.time && statement.time[tranlatedLanguage]){
      statement.time[tranlatedLanguage].start=startTime
    }
    this.setState({ fields, start:startTime })
  }

  handleEnd=(v,ind)=>{
    let endTime=moment(v._d).format('hh:mm:ss');
    const { fields,tranlatedLanguage} = this.state
    let statement = fields['scriptFile'][ind]
    if(statement.English.end){
      statement.English.end=endTime
    }  
    if(statement.time && statement.time[tranlatedLanguage]){
      statement.time[tranlatedLanguage].end=endTime
    }
    this.setState({ fields, end:endTime })
  }


  handlestartTime=(value)=>{
    this.setState({startTime:value})
  }

  handleEndTime=(value)=>{
    this.setState({endTime:value})
  }

  handleSuggestion=(e)=>{
    this.setState({ selectSuggestion: e.target.value })
    
  }
  handleUseSuggestion = (suggestion) => {
    this.setState({ scriptTranslatedText: suggestion })
  }

  handleIsCheckedTime=(e)=>{
    this.setState({isCheckedTime:e.target.checked})
  }

  changeStatus = () => {
    const { fields, tranlatedLanguage } = this.state
    // console.log( 'fields', fields, ' Language', tranlatedLanguage);
    var translationStatus = fields['each_translation_status'].find((item)=> item.language === tranlatedLanguage)
    if(!translationStatus){
      translationStatus  = {language :tranlatedLanguage , status : true }
    }
    const message = translationStatus.status === true ? 'Status will change to Inprogress': 'Status will change to Complete'
    const selectedLanguage = fields.languages.find(lang => {
      if(typeof lang.language === 'object' && lang.language.language === translationStatus.language){
        return lang
      }else if(lang.language === translationStatus.language){
        return lang
      }
    })
    swal({
      title: "Are you sure?",
      text: `${message}`,
      icon: "warning",
      buttons: ['Cancel', 'Yes'],
      dangerMode: true,
    })
    .then(async (pressYes) => {
      if (pressYes) {
        const projectAssigner = fields.activities[0].userDetail.email
        const data = {
          mailToUser: projectAssigner,
          projectName: fields.projectname,
          translationStatus: translationStatus
        }
        const webHookURL= ENV.slackWebHookLink;
        const webHookData={
          "text":`Translator Name:${selectedLanguage.translatorId.fullname}\n Translator Email:${selectedLanguage.translatorId.email}\n Project Name:${fields['projectname']} \n Message: Translation Completed for ${translationStatus.language}`
        }
        // await this.AddStatusActivity(translationStatus)
        // if(this.props.updateProject.success){
             
          await this.props.sendStatusChange(this.state.projectId, data)

        // }
       
        await axios.post(webHookURL,JSON.stringify(webHookData), {
          withCredentials:false,
          transformRequest:[(response, headers)=>{
            delete headers.post["Content-Type"]
            return response;
          }]  
        })
        if(this.props.translationStatus){
          swal(this.props.translationStatus.message, {icon: "success"});
          this.getProjectDetails(this.state.projectId)

          if (this.props?.viewProjectdetail?.success) {

            setTimeout(async()=>{

              await this.AddStatusActivity(translationStatus)

            },500)
          }  
        }  
      }
    })
  }


  AddStatusActivity = async( translationStatus )=>{
    const {fields , tranlatedLanguage } = this.state ; 
    const user = new Object();
    user.userDetail = decodedTranslatorToken;
    user.activity = translationStatus.status === true ? ` ${tranlatedLanguage} Translation Status Change To Inprogress`  : ` ${tranlatedLanguage} Translation Status  Change To Complete`;
    user.createdAt = moment().format()
   

    fields['activities'].push(user)
    this.setState({fields})
    let data ={
      ...this.state.fields,
    }
    await this.props.updateProjects(this.state.projectId,data);
    if(this.props.updateProject.success){
      this.setState({ disable: false })
      toast.success(this.props.updateProject.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }else{
      this.setState({ disable: false })
      toast.dismiss()
      toast.error( this.props.updateProject.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }

  }

  render() {
    const { scriptData, tranlatedLanguage } = this.state
    if(this.state.tranlatedLanguage){
      var  translationStatus = this.state.fields['each_translation_status'].filter((item)=>{
        if(item.language == tranlatedLanguage)
        return item
      })
    }

    const check = ['undefined', undefined, 'null', null, 0, '0', ''];

    return (
      <>
        <Container fluid className="p-sm-0">
          <Row className="mb-4">
            <Col sm={12} className="p-0">
              <div className="headTag d-flex align-items-center">
                <span className='back-icon-script' onClick={() => this.props.history.goBack()}><i class="fas fa-angle-left"></i></span>
              </div>
            </Col>
            <Col sm={12}>
              <Card className="projectTable border-0 rounded-0">
                <Card.Body>
                  <Row className='next-top-row'>
                    <Col sm={12} className="p-0 top-next-buttons">
                      <div className='top-next-buttons'>
                        <div className=''>
                          {/* <h5>
                            {fields['projectname']}
                            {scriptData.data ? scriptData.data.projectname : null}
                          </h5> */}
                          <button class="btn button-script"><i class="fa fa-angle-right"></i>
                            {/* {scriptData.data ? scriptData.data.projectname : null} */}
                            {this.state.fields['projectname']}

                          </button>
                        </div>
                        <button class="btn button-script button-english">English <i class="fa fa-arrow-right"></i></button>
                        <div className="select-box-next">
                        <select className="form-control" name="tranlatedLanguage" value={this.state['tranlatedLanguage']} onChange={this.handleSelectLanguage}>
                         <option value=''>Select language</option> 
                             { this.state.fields.languages.map((t,i)=>{
                                if(typeof t.language === 'object'){
                                  return (
                                    <option key={i} value={t?.language?.language}>{t?.language?.language}</option>
                                  )
                                }else{
                                  return(
                                    <option key={i} value={t.language}>{t.language}</option>
                                  )
                                }
                              })}
                          </select>
                          {this.state['tranlatedLanguage'] ?
                            <>
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {this.state.isCheckedTime ? 'hide start and end time if not required' : 'show start and end time if required'}
                                  </Tooltip>
                                )}
                                placement="left"
                              >
                                <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" 
                                  id="customSwitch1" value={this.state.isCheckedTime} onChange=
                                  {this.handleIsCheckedTime}/>
                                <label class="custom-control-label" for="customSwitch1">{this.state.isCheckedTime?'Hide':'Show'}</label>
                                </div>
                              </OverlayTrigger>
                            </>
                          :null  
                          }  
                        </div>
                       
                      </div>
                      <div className='status'>
                        {this.state.tranlatedLanguage ? 
                          translationStatus.length ?
                              translationStatus.map((item)=>{
                                return (
                                  <p> Status : {item.status == true ? 'Completed' : ' Inprogress'}</p>
                                )
                              })
                          :  <p> Status : Inprogress  </p> 
                        :null  
                        }
                      </div>
                      {this.state.tranlatedLanguage ?
                        <div className='submit-project-button'>
                          <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                      {translationStatus.length ?
                                        translationStatus.map((item)=>{
                                      return (
                                        <p>{item.status == true ? 'Click to Change Translation Status to Inprogress' : ' Click to Change Translation Status to Complete '}</p>
                                      )
                                      })
                                      :  <p> Click to Complete Translation  </p> }
                                </Tooltip>
                              )}
                              placement="left"
                          >
                            <Button style={{ backgroundColor: '#C93D3A', border: 'none', color: 'white' }} type="submit"  onClick={this.changeStatus}>
                                {translationStatus.length ?
                                    translationStatus.map((item)=>{
                                      return (
                                        item.status == true ? 'Mark as Inprogress' : ' Mark as Complete  '
                                      )
                                    })
                                :  'Mark To Complete ' 
                                }
                            </Button>
                          </OverlayTrigger>
                        </div>
                      :null  
                      }  
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="progressbar-row">
                      <ProgressBar now={60} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8} className="">
                      <div className='search-filter'>
                        <input type="text" class="form-control" id="exampleInputext" aria-describedby="text" placeholder="Search" onChange={this.handleSearchScript}></input>
                        <i className="fas fa-search"></i>
                      </div>
                      <Row className='wraper-script-translate'>
                        <Col sm={12} className="tablecheckbox-t">
                          <div className='select-language-wrap'>
                          {this.state['tranlatedLanguage'] && this.state.isCheckedTime==true ?
                            <Col sm={3} className='time-w'>
                              <h5>Time</h5>
                            </Col> 
                            :
                            null
                             }
                            <Col sm={5}>
                              <h5>English</h5>
                            </Col>
                            <Col sm={4}>
                              <h5>
                                {this.state.tranlatedLanguage}
                              </h5>
                            </Col>
                          </div>
                          {
                          this.state.searchScriptText.length == 0 ? (
                            this.state.fields['scriptFile'].map((language, languageIndex)=> {
                              if (typeof language.English === 'object'){
                                return (
                                  <div className='script-main-wrap' onClick={(e) => this.handleSelectedText(language.English.text, language.Index,language[tranlatedLanguage])}
                                    style={this.state.boxColor === language.Index?{ background:"#e8e8e8",cursor: 'pointer' }: { background: 'white',cursor: 'pointer' }}
                                    >
                                    {this.state['tranlatedLanguage'] && this.state.isCheckedTime==true
                                        ? <Col sm={3} className='date-time-w'>
                                            {language.English.start && language.English.end ?
                                                  <div key={languageIndex} className='date-time-picker'>
                                                    <div className='start-time-width'>
                                                      <p className='start-time-text'>start Time</p> 
                                                      <TimePicker
                                                        value={moment(language.English.start,"hh:mm:ss")}
                                                        id={language.Index}
                                                        onChange={(v,id=language.Index)=> this.handlestart(v,id)}
                                                      />
                                                    </div>
                                                    <div className='start-time-width'>  
                                                        <p className='start-time-text'>End Time</p>
                                                        <TimePicker
                                                        value={moment(language.English.end,"hh:mm:ss")}
                                                        id={language.Index}
                                                        onChange={(v,id=language.Index)=> this.handleEnd(v,id)}
                                                        />
                                                    </div>
                                                  </div>  

                                              : 
                                                  <div key={languageIndex} className='date-time-picker'>
                                                    <div className='start-time-width'>
                                                      <p className='start-time-text'>start Time</p> 
                                                      <TimePicker
                                                        value={this.state.startTime}
                                                        onChange={this.handlestartTime}
                                                      />
                                                    </div>
                                                    <div className='start-time-width'>  
                                                      <p className='start-time-text'>End Time</p>
                                                      <TimePicker
                                                        value={this.state.endTime}
                                                        onChange={this.handleEndTime}
                                                      />
                                                    </div>
                                                  </div>
                                            }
                                          </Col>
                                        : null
                                        }
                                    <Col sm={5} className='left-side-wrap'>
                                      <div className='wrap-language-index'>
                                        <p className='left-index'>
                                          {language.Index+1}  
                                        </p>
                                         <p >
                                          {language.English.text}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col sm={4} className='right-side-wrap'>
                                      <div key={languageIndex} className='checkbox-style-wrap' >
                                        <p className='translate-circle-wrap'>
                                        {(language.status && language
                                        [tranlatedLanguage] && language.status[tranlatedLanguage])
                                            ? <span className='circle-language'><i class="fas fa-circle"></i></span>
                                            : null
                                          }
                                        {/* {language.status ? 
                                              Object.keys(language.status).map((key,ind)=>{
                                                return(
                                                  language.status[key]==true &&language[tranlatedLanguage] ?
                                                  <span className='circle-language'><i class="fas fa-circle"></i></span>
                                                  :
                                                  null
                                                )  
                                              })
                                            :
                                             null
                                            } */}
                                           {language[tranlatedLanguage] || ''}
                                        </p>
                                        {/* <div className='checkbox-pointer'>
                                        <input type="checkbox"
                                              value={language.Index}
                                              checked={language.Index.checked}
                                              onChange={(e) => this.handleCheckBox(e, language.Index,language)} />
                                        </div> */}
                                      </div>
                                    </Col>
                                    <Col sm={3} className='right-side-wrap'>
                                      <div className='checkbox-pointer'>
                                        <input type="checkbox"
                                          value={language.Index}
                                          checked={this.state.isChecked.includes(language.Index)}
                                          onChange={(e) => this.handleCheckBox(e, language.Index)}
                                        />
                                      </div>
                                    </Col>
                                  </div>
                                )
                              }else{
                                  return(
                                    <div className='script-main-wrap' key={languageIndex} 
                                      onClick={() => this.handleSelectedText(language.English, 
                                      language.Index, language[tranlatedLanguage])}
                                          style={this.state.boxColor === language.Index ? { 
                                      background: "#e8e8e8", cursor: 'pointer' } : { 
                                      background: 'white', cursor: 'pointer' }}
                                    >
                                          {this.state['tranlatedLanguage'] && this.state.
                                           isCheckedTime==true ?
                                            <Col sm={3} className='date-time-w'>
                                              {language.time && language.time
                                              [tranlatedLanguage] ?
                                                <div key={languageIndex}      className='date-time-picker'
                                                >
                                                  <div className='start-time-width'>
                                                    <p className='start-time-text'>
                                                      start Time
                                                    </p>
                                                    <TimePicker
                                                      value={moment(language.time
                                                      [tranlatedLanguage].start,"hh:mm:ss")}
                                                      id={language.Index}
                                                      onChange={(v,id=language.Index)=> this.handlestart(v,id)}
                                                    />
                                                  </div>
                                                  <div className='start-time-width'>
                                                    <p className='start-time-text'>End Time</p>
                                                    <TimePicker
                                                      value={moment(language.time
                                                      [tranlatedLanguage].end,"hh:mm:ss")}
                                                      id={language.Index}
                                                      onChange={(v,id=language.Index)=> this.handleEnd(v,id)}
                                                    />
                                                  </div>
                                                </div>
                                                :
                                                <div key={languageIndex} 
                                                className='date-time-picker'
                                                >
                                                  <div className='start-time-width'>
                                                    <p className='start-time-text'>
                                                      start Time
                                                    </p>
                                                    <TimePicker
                                                      value={this.state.startTime}
                                                      onChange={this.handlestartTime}
                                                    />
                                                  </div>
                                                  <div className='start-time-width'>
                                                    <p className='start-time-text'>
                                                      End Time
                                                    </p>
                                                    <TimePicker
                                                      value={this.state.endTime}
                                                      onChange={this.handleEndTime}
                                                    />
                                                  </div>
                                                </div>
                                              }
                                            </Col>
                                            : null
                                          }
                                          <Col sm={5} className='left-side-wrap'>
                                            <div className='wrap-language-index'>
                                              <p className='left-index'>
                                                {language.Index + 1}
                                              </p>
                                              <p>
                                                {language.English}
                                              </p>
                                            </div>
                                          </Col>
                                          <Col sm={4} className='right-side-wrap'>
                                            <div key={languageIndex} 
                                             className='checkbox-style-wrap'
                                            >
                                              <p className='translate-circle-wrap'>
                                                {(language.status && language
                                                [tranlatedLanguage] && language.status
                                                [tranlatedLanguage])
                                                  ? <span className='circle-language'><i 
                                                    class="fas fa-circle"></i>
                                                    </span>
                                                  : null
                                                }
                                                {language[tranlatedLanguage] || ''}
                                              </p>
                                              {/* <div className='checkbox-pointer'>
                                                <input type="checkbox"
                                                  value={language.Index}
                                                  checked={this.state.isChecked.includes
                                                  (language.Index)}
                                                  onChange={(e) => this.handleCheckBox(e, 
                                                  language.Index)}
                                                />
                                              </div> */}
                                            </div>
                                          </Col>
                                          <Col sm={3} className='right-side-wrap'>
                                            <div className='checkbox-pointer'>
                                              <input type="checkbox"
                                                value={language.Index}
                                                checked={this.state.isChecked.includes(language.Index)}
                                                onChange={(e) => this.handleCheckBox(e, language.Index)}
                                              />
                                            </div>
                                          </Col>
                                    </div>

                                  )
                              }  
                            })
                          )
                              : 
                              (
                              this.state.updateScript.map((language, languageIndex) => {
                                if (typeof language.English === 'object'){
                                  return (
                                    <div className='script-main-wrap' onClick={(e) => this.handleSelectedText(language.English.text, language.Index,language[tranlatedLanguage])}
                                      style={this.state.boxColor === language.Index?{ background:"#e8e8e8",cursor: 'pointer' }: { background: 'white',cursor: 'pointer' }}
                                      >
                                      {this.state['tranlatedLanguage'] && this.state.isCheckedTime==true
                                          ? <Col sm={3} className='date-time-w'>
                                              {language.English.start && language.English.end ?
                                                    <div key={languageIndex} className='date-time-picker'>
                                                      <div className='start-time-width'>
                                                        <p className='start-time-text'>start Time</p> 
                                                        <TimePicker
                                                          value={moment(language.English.start,"hh:mm:ss")}
                                                          id={language.Index}
                                                          onChange={(v,id=language.Index)=> this.handlestart(v,id)}
                                                        />
                                                      </div>
                                                      <div className='start-time-width'>  
                                                          <p className='start-time-text'>End Time</p>
                                                          <TimePicker
                                                          value={moment(language.English.end,"hh:mm:ss")}
                                                          id={language.Index}
                                                          onChange={(v,id=language.Index)=> this.handleEnd(v,id)}
                                                          />
                                                      </div>
                                                    </div>  
  
                                                : 
                                                    <div key={languageIndex} className='date-time-picker'>
                                                      <div className='start-time-width'>
                                                        <p className='start-time-text'>start Time</p> 
                                                        <TimePicker
                                                          value={this.state.startTime}
                                                          onChange={this.handlestartTime}
                                                        />
                                                      </div>
                                                      <div className='start-time-width'>  
                                                        <p className='start-time-text'>End Time</p>
                                                        <TimePicker
                                                          value={this.state.endTime}
                                                          onChange={this.handleEndTime}
                                                        />
                                                      </div>
                                                    </div>
                                              }
                                            </Col>
                                          : null
                                          }
                                      <Col sm={5} className='left-side-wrap'>
                                        <div className='wrap-language-index'>
                                          <p className='left-index'>
                                            {language.Index+1}  
                                          </p>
                                           <p >
                                            {language.English.text}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col sm={4} className='right-side-wrap'>
                                        <div key={languageIndex} className='checkbox-style-wrap' >
                                         
                                          <p className='translate-circle-wrap'>
                                          {(language.status && language
                                          [tranlatedLanguage] && language.status[tranlatedLanguage])
                                              ? <span className='circle-language'><i class="fas fa-circle"></i></span>
                                              : null
                                            }
                                          {/* {language.status ? 
                                                Object.keys(language.status).map((key,ind)=>{
                                                  return(
                                                    language.status[key]==true &&language[tranlatedLanguage] ?
                                                    <span className='circle-language'><i class="fas fa-circle"></i></span>
                                                    :
                                                    null
                                                  )  
                                                })
                                              :
                                               null
                                              } */}
                                             {language[tranlatedLanguage] || ''}
                                          </p>
                                          <div className=' '>
                                          <input type="checkbox"
                                                value={language.Index}
                                                checked={language.Index.checked}
                                                onChange={(e) => this.handleCheckBox(e, language.Index,language)} />
                                          </div>
                                        </div>
                                        
                                      </Col>

                                    </div>
                                  )
                                }else{
                                    return(
                                      <div className='script-main-wrap' key={languageIndex} 
                                        onClick={() => this.handleSelectedText(language.English, 
                                        language.Index, language[tranlatedLanguage])}
                                            style={this.state.boxColor === language.Index ? { 
                                        background: "#e8e8e8", cursor: 'pointer' } : { 
                                        background: 'white', cursor: 'pointer' }}
                                      >
                                            {this.state['tranlatedLanguage'] && this.state.
                                             isCheckedTime==true ?
                                              <Col sm={3} className='date-time-w'>
                                                {language.time && language.time
                                                [tranlatedLanguage] ?
                                                  <div key={languageIndex}      className='date-time-picker'
                                                  >
                                                    <div className='start-time-width'>
                                                      <p className='start-time-text'>
                                                        start Time
                                                      </p>
                                                      <TimePicker
                                                        value={moment(language.time
                                                        [tranlatedLanguage].start,"hh:mm:ss")}
                                                        id={language.Index}
                                                        onChange={(v,id=language.Index)=> this.handlestart(v,id)}
                                                      />
                                                    </div>
                                                    <div className='start-time-width'>
                                                      <p className='start-time-text'>End Time</p>
                                                      <TimePicker
                                                        value={moment(language.time
                                                        [tranlatedLanguage].end,"hh:mm:ss")}
                                                        id={language.Index}
                                                        onChange={(v,id=language.Index)=> this.handleEnd(v,id)}
                                                      />
                                                    </div>
                                                  </div>
                                                  :
                                                  <div key={languageIndex} 
                                                  className='date-time-picker'
                                                  >
                                                    <div className='start-time-width'>
                                                      <p className='start-time-text'>
                                                        start Time
                                                      </p>
                                                      <TimePicker
                                                        value={this.state.startTime}
                                                        onChange={this.handlestartTime}
                                                      />
                                                    </div>
                                                    <div className='start-time-width'>
                                                      <p className='start-time-text'>
                                                        End Time
                                                      </p>
                                                      <TimePicker
                                                        value={this.state.endTime}
                                                        onChange={this.handleEndTime}
                                                      />
                                                    </div>
                                                  </div>
                                                }
                                              </Col>
                                              : null
                                            }
                                            <Col sm={5} className='left-side-wrap'>
                                              <div className='wrap-language-index'>
                                                <p className='left-index'>
                                                  {language.Index + 1}
                                                </p>
                                                <p>
                                                  {language.English}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col sm={4} className='right-side-wrap'>
                                              <div key={languageIndex} 
                                               className='checkbox-style-wrap'
                                              >
                                                <p className='translate-circle-wrap'>
                                                  {(language.status && language
                                                  [tranlatedLanguage] && language.status
                                                  [tranlatedLanguage])
                                                    ? <span className='circle-language'><i 
                                                      class="fas fa-circle"></i>
                                                      </span>
                                                    : null
                                                  }
                                                  {language[tranlatedLanguage] || ''}
                                                </p>
                                                {/* <div className='checkbox-pointer'>
                                                  <input type="checkbox"
                                                    value={language.Index}
                                                    checked={this.state.isChecked.includes
                                                    (language.Index)}
                                                    onChange={(e) => this.handleCheckBox(e, 
                                                    language.Index)}
                                                  />
                                                </div> */}
                                              </div>
                                            </Col>
                                            <Col sm={3} className='right-side-wrap'>
                                              <div className='checkbox-pointer'>
                                                <input type="checkbox"
                                                  value={language.Index}
                                                  checked={this.state.isChecked.includes(language.Index)}
                                                  onChange={(e) => this.handleCheckBox(e, language.Index)}
                                                />
                                              </div>
                                            </Col>
                                      </div>
  
                                    )
                                }  
                              })
                              )
                          }

                        </Col>
                        {/* <div className='add-btn-row'>
                         <Button onClick={this.handleAddRow}> <span className='plus-icon'>&#x2B;</span>Add a line</Button>       
                        </div> */}
                      </Row>
                    </Col>
                    {this.state.isChecked.length > 0 ?
                      <>
                        <div className='button-script-delete'>
                          <Button onClick={this.handleDownLoadScript}>Download Script</Button>
                        </div>
                      </>
                      :
                    <Col sm={4} className="">
                      <Row className='tab-content-next-sec'>
                        <Col sm={12} className="tabs-section-right">
                          <Row className='tab-second-sec-padding'>
                            <Col sm={12} className="suggation-sec">
                              <p>
                                { !check.includes(this.state.suggestion) ?
                                  <span>
                                    {this.state.suggestion.length == 0
                                      ? 'No Suggation available' : <span>{this.state.suggestion.length}Suggation available</span>
                                    }
                                  </span>
                                  :
                                  <span>
                                    No Suggation available
                                  </span>
                                }
                              </p>
                            </Col>
                            {!check.includes(this.state.suggestion) ?
                                  (

                                    <Row className=''>
                                      {this.state.suggestion.length > 0 ?
                                        <>
                                          <Col sm={12}>
                                            {this.state.suggestion.map((item) => {
                                              if (item.English.text) {
                                                return (
                                                  <div className='main-wraper-f'>
                                                    <div className='second-wrap'>
                                                      <div className='box-presentage'>
                                                        <p className='pre-p'>{item.percentage}</p>
                                                        <p>match</p>
                                                      </div>
                                                    </div>
                                                    <div className='text-wrap-s'>
                                                      <p>{item[tranlatedLanguage]}</p>
                                                      <p className='text-dark'>{item.English.text}</p>
                                                    </div>
                                                    <div className='logo-text-sec'>
                                                      <p><i class="fas fa-exchange-alt"></i> <span className='data-text-sec-user'> <Button onClick={() => this.handleUseSuggestion(item[tranlatedLanguage])}>
                                                        used</Button></span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                )
                                              }else{
                                                return (
                                                  <div className='main-wraper-f'>
                                                    <div className='second-wrap'>
                                                      <div className='box-presentage'>
                                                        <p className='pre-p'>{item.percentage}</p>
                                                        <p>match</p>
                                                      </div>
                                                    </div>
                                                    <div className='text-wrap-s'>
                                                      <p>{item[tranlatedLanguage]}</p>
                                                      <p className='text-dark'>{item.English}</p>
                                                    </div>
                                                    <div className='logo-text-sec'>
                                                      <p><i class="fas fa-exchange-alt"></i> <span className='data-text-sec-user'> <Button onClick={() => this.handleUseSuggestion(item[tranlatedLanguage])}>
                                                        used</Button></span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })}
                                          </Col>
                                        </> : null}

                                    </Row>

                                  ) : null
                                }
                          </Row>
                        </Col>
                      </Row>
                      <Col sm={12} className="third-colom-sec">
                        <Row>
                          <Col sm={12} className="edit-context-text-copy">
                            <div>
                              <div className='mb-3'>
                                <textarea
                                  value={this.state.selectedOriginalText}
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="7"
                                  placeholder='Original text here...'
                                  disabled={true}
                                />
                              </div>
                              <textarea
                                value={this.state.scriptTranslatedText}
                                onChange={this.handleTranslateText}
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="7"
                                placeholder='Your translated text here...please select language and enter start time '
                                disabled={!this.state.tranlatedLanguage}
                              />

                              {this.state.copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} className="edit-context-dropdown">
                            <Button variant="info" onClick={this.submitTranlation} disabled={!tranlatedLanguage || !this.state.scriptTranslatedText || !this.state.selectedOriginalText}>Save Translation</Button>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                    } 
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
const mapStateToProps = state => {

  const { project, translator } = state
  return {
    viewProjectdetail: project.viewProject,
    updateScript: translator.update_script_api_data,
    fetchScriptSuggestion:translator.allScriptSuggestion,
    translationStatus:translator.translationStatus,
    updateProject:translator.updateProject
  }
}
const dispatchAction = { viewProjectEmail, updateScriptLanguageAPI,suggestionsScript ,sendStatusChange , updateProjects}
export default connect(mapStateToProps, dispatchAction)(TranslateScript);