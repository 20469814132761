import React, { Component } from 'react';
import { Button, Card, Container, Row, Col, Form, Media, Badge, ListGroup, ListGroupItem, ProgressBar, TabPane, Tabs, Tab, Dropdown, ButtonGroup, Tooltip, OverlayTrigger, Alert,Modal } from "react-bootstrap";
import { scriptList, addProject, singleScript, updateProject , updateScriptLanguage, scriptSuggestions, exportToGoogleDrive, activeInactiveProject , sendTranslationNotification , fetchAllUsers} from '../../actions/projectActions';
import { connect } from 'react-redux';
import {pdf} from '@react-pdf/renderer';
import { saveAs } from "file-saver";
import ReactPDF from '@react-pdf/renderer';
import { isLoggedIn, decodedToken, isTranslatorLoggedIn, decodedTranslatorToken } from '../../helpers/decode-token';
// import { getAdmin } from '../../actions/adminActions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Autosuggest from 'react-autosuggest';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { socket } from '../../actions/fetchActions';
import 'bootstrap/dist/css/bootstrap.min.css';
import { convertToObject } from 'typescript';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import JSZip from 'jszip';
import moment, { lang } from 'moment';
import { dataDrivers } from 'dhx-suite';
import { array, object } from 'prop-types';
import { Document, Packer, Paragraph, TextRun } from "docx";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import FileSaver from 'file-saver';
import { jsPDF } from "jspdf";
import axios from 'axios';
import { data } from 'jquery';
import {ENV} from "../../env";
import * as XLSX from 'xlsx';

// const HtmlToRtfBrowser = require('html-to-rtf-browser');


class EditProjectScript extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectId: this.props.match.params.project_Id,
      copied: false,
      to: 'en',
      from: 'en',
      options: [],
      selectScript: true,
      isChecked: [],
      TranslateText: '',
      fields: {
        projectname: '',
        due_date: new Date(),
        translation_tasks: [],
        language_suggestion: '',
        languages: [],
        activities: [],
        scriptFile: [],  // is used to store uploaded File from drag and drop
        comments: '',
        each_translation_status : [] , 
      },
      selectedOriginalText: '',
      isActive: false,
      updateScript: [],
      tranlatedLanguage: '',
      scriptTranslatedText: '',
      selectedOriginalTextIndex: null,
      searchScriptText: '',
      boxColor: '',
      totalScriptSuggestion: [],
      scriptSuggested: '',
      downloadData: [],
      status: '',
      start: '',
      end: '',
      startTime: moment().startOf("day"),
      endTime: moment().startOf("day"),
      translateStatus: '',
      suggestion: '',
      selectSuggestion: '',
      time: '',
      isCheckedTime: false,
      expsrt: '',
      // admin:''
      showModel: false ,
      downloadScript : [],
      translationStatus :''
    }
  }


  componentDidMount() {
    // this.fetchUser()

    this.getScriptContent(this.state.projectId);
   
  }


  //   fetchUser = async () => {
  //     await this.props.getAdmin(decodedToken.userId)
  //     if(this.props.admin.success){
  //         this.setState({
  //             admin: this.props.admin.user,
  //         },()=>console.log("admin",this.state.admin))
  //         console.log(this.props.admin)
  //     }else{
  //         toast.dismiss()
  //         toast.error(this.props.admin.message, {position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
  //     }
  // }

  getScriptContent = async (projectId) => {
    let fields = this.state.fields;
    await this.props.singleScript(projectId)
    if (this.props.scriptData.success) {
      fields['projectname'] = this.props.scriptData.data.projectname;
      fields['due_date'] = new Date(this.props.scriptData.data.due_date);
      fields['translation_tasks'] = this.props.scriptData.data.translation_tasks;
      fields['languages'] = (this.props.scriptData.data.languages);
      fields['comments'] = (this.props.scriptData.data.comments);
      fields['activities'] = this.props.scriptData.data.activities;
      fields['scriptFile'] = this.props.scriptData.data.script;
      fields['each_translation_status'] = this.props.scriptData.data.each_translation_status
      this.setState({ fields })
    } else {
      toast.dismiss()
      toast.error(this.props.scriptData.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
  }

  handleSelectedText = async (originalStatement, index, translatedText) => {
    this.setState({ selectedOriginalText: originalStatement, selectedOriginalTextIndex: index })
    if (translatedText) {
      this.setState({ scriptTranslatedText: translatedText })
    } else {
      this.setState({ scriptTranslatedText: '' })
    }
    if (index === this.state.boxColor) {
      this.setState({ boxColor: null })
    } else {
      this.setState({ boxColor: index })
    }
    let language = this.state.tranlatedLanguage
    let statement = originalStatement
    if (this.state.tranlatedLanguage) {
        if (statement.text) {
          await this.props.scriptSuggestions(language, statement.text)
          
          if(this.props.fetchScriptSuggestion.success){
              if(this.props.fetchScriptSuggestion.suggestions.length > 0 ){
                this.setState({ suggestion: this.props.fetchScriptSuggestion.suggestions })
              }else{
                this.setState({suggestion : ''})
              }
          }
        }else{
              await this.props.scriptSuggestions(language, statement)

              if(this.props.fetchScriptSuggestion.success){
                if(this.props.fetchScriptSuggestion.suggestions.length > 0 ){

                  this.setState({ suggestion: this.props.fetchScriptSuggestion.suggestions })

                }else{

                  this.setState({suggestion : ''})

                }
              }
          }
    }    
  }

  handleSelectLanguage = (e) => {
    this.setState({ tranlatedLanguage: e.target.value });
    if (e.target.value) {
      this.setState({ selectedOriginalText: '', scriptTranslatedText: '', boxColor: '' })
    }
  }

  handleTranslateText = (e) => {
    const { value } = e.target;
    this.setState({ scriptTranslatedText: value })
  }

  submitTranlation = async (e) => {
    e.preventDefault();
    const { fields, selectedOriginalTextIndex, tranlatedLanguage, scriptTranslatedText, startTime, endTime, start, end } = this.state
    let languages = fields['languages'].map(lang => typeof lang.language === 'object' ? lang.language.language : lang.language)
    let scriptFile = fields['scriptFile']
    let statement = scriptFile[selectedOriginalTextIndex]
    statement[`${tranlatedLanguage}`] = scriptTranslatedText
    if (statement.status) {
      statement.status[tranlatedLanguage] = false
    }
    if (this.state.isCheckedTime) {
      if (!statement.time) {
        statement.time = {}
        languages.forEach(lkey => {
          if (tranlatedLanguage === lkey) {
            statement.time[lkey] = { start: moment(this.state.startTime).format("hh:mm:ss"), end: moment(this.state.startTime).format("hh:mm:ss") }
          } else {
            statement.time[lkey] = { start: "00:00:00", end: "00:00:00" }
          }
        })
      } else {
        if (statement.time) {
          if (start && end) {
            statement.time[tranlatedLanguage] = { start: start, end: end }
          } else {
            statement.time[tranlatedLanguage] = { start: "00.00.00", end: "00.00.00" }
          }
        }

      }
      scriptFile[selectedOriginalTextIndex] = statement;
    }
    else {
      scriptFile[selectedOriginalTextIndex] = statement;
    }
    fields.scriptFile = scriptFile
    this.setState({ fields: fields })
    let data = {
      script: scriptFile
    }
    await this.props.updateScriptLanguage(this.state.projectId, data);
    this.setState({ scriptTranslatedText: '' });
    this.setState({ selectedOriginalText: '' });
    this.setState({ startTime: moment().startOf("day"), endTime: moment().startOf("day") })
  }


  saveAndNextTranslation = async (e) => {
    e.preventDefault();
    const { fields, selectedOriginalTextIndex, tranlatedLanguage, scriptTranslatedText,
      startTime, endTime, start, end } = this.state
    let languages = fields['languages'].map(lang => typeof lang.language === 'object' ?
      lang.language.language : lang.language)
    let scriptFile = fields['scriptFile']
    let statement = scriptFile[selectedOriginalTextIndex]
    statement[`${tranlatedLanguage}`] = scriptTranslatedText
    if (statement.status) {
      statement.status[tranlatedLanguage] = false
    }
    if (this.state.isCheckedTime) {
      if (!statement.time) {
        statement.time = {}
        languages.forEach(lkey => {
          if (tranlatedLanguage === lkey) {
            statement.time[lkey] = { start: moment(this.state.startTime).format("hh:mm:ss"), end: moment(this.state.startTime).format("hh:mm:ss") }
          } else {
            statement.time[lkeyselectedOriginalText] = { start: "00:00:00", end: "00:00:00" }
          }
        })
      } else {
        if (statement.time) {
          if (start && end) {
            statement.time[tranlatedLanguage] = { start: start, end: end }
          } else {
            statement.time[tranlatedLanguage] = { start: "00.00.00", end: "00.00.00" }
          }
        }
      }
      scriptFile[selectedOriginalTextIndex] = statement;
    } else {
      scriptFile[selectedOriginalTextIndex] = statement;
    }
    fields.scriptFile = scriptFile
    this.setState({ fields: fields })
    let data = {
      script: scriptFile
    }
    await this.props.updateScriptLanguage(this.state.projectId, data);
    var index = this.state.selectedOriginalTextIndex + 1
    if (fields['scriptFile'].length == index) {
      this.handleAddRow();
    } else {
      let nextOriginalStatement = fields['scriptFile'][index]
      this.handleSelectedText(nextOriginalStatement.English, index, tranlatedLanguage)
    }
    this.setState({ scriptTranslatedText: '' });
    this.setState({
      startTime: moment().startOf("day"), endTime: moment().startOf
        ("day")
    })


  }

  handleCheckBox = (e, ind) => {
    let isChecked = this.state.isChecked;
    if (e.target.checked) {
      isChecked.push(ind)
    } else {
      if (isChecked.indexOf(ind) !== -1) {
        isChecked = isChecked.filter(item => item !== ind)
      }
    }
    this.setState({ isChecked })
  }


  handleSearchScript = (e) => {
    const { value } = e.target;
    this.setState({ searchScriptText: value });

    if (this.state.searchScriptText !== 0) {
      const resultData = this.state.fields['scriptFile'].filter((item) => {
        if (item.English.text) {
          return (
            Object.values(item.English).join('').toLowerCase().includes(this.state.searchScriptText.toLowerCase())
          )
        } else {
          return (
            Object.values(item.English).join('').toLowerCase().includes(this.state.searchScriptText.toLowerCase())
          )
        }
      })
      this.setState({ updateScript: resultData })
    }
    else {
      this.setState({ updateScript: this.state.fields['scriptFile'] })
    }
  }

  DeleteSelectdRow = async () => {
    let fields = { ...this.state.fields }
    swal({
      title: "Are you sure?",
      text: `Once Deleted,selected row data will be permanently delete`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const newScript = fields['scriptFile'].filter((item) => !this.state.isChecked.includes(item.Index))
          .map((item, index) => { return { ...item, Index: index } })
          fields['scriptFile'] = newScript
          this.setState({ fields, isChecked: [], selectedOriginalText: '', scriptTranslatedText: '' })

          const data = {
            script: fields['scriptFile']
          }

          await this.props.updateScriptLanguage(this.state.projectId, data);
          if (this.props.updateScriptLanguage.success) {
            this.getScriptContent(this.state.projectId)
            swal(this.props.updateScriptLanguage.message, {
              icon: "success",

            });
          } else {
            swal(this.props.updateScriptLanguage.message, {
              icon: "error",
            });
          }
        } else {

          swal("Good job!", `is Safe`, "success");
          this.setState({isChecked:[]})
          
        }
      });
  }

  handleDownLoadScript = () => {
    const {tranlatedLanguage , fields } = this.state
    const scriptFile = fields['scriptFile']
    const downloadData = scriptFile.map((item)=>{
      if(item.English || item.Index || item.time || item.status){
        delete item.English
        delete item.Index
        delete item.status
        delete item.time
      }  
      const statement = item[tranlatedLanguage]
      return (
        statement
      )
    })
    const script = downloadData.filter((item , key )=>{
      if ( item !==undefined){
        return (
          item
        )
      }
    })
    
    const downloadScript   = script.map((caption, index) => {
      if (caption) {
        return (index > 0 ? '\n' : '') + [
          // index+1,
          `${caption}`
        ].join('\n')
      }
    });

    
    var content = `${tranlatedLanguage }\n\n\n`
    content += `${downloadScript}\n`;

    // console.log("String", String)
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download =  tranlatedLanguage +  Date.now() + ".text" ;
    link.href = url;
    link.click();
    this.setState({ isChecked: [] })
  }

  handleOriginalText = async (e) => {
    const { value } = e.target;
    this.setState({ selectedOriginalText: value })

  }

  exportToDoc = async () =>{

    const {tranlatedLanguage , fields } = this.state
    const scriptFile = fields['scriptFile']
    const downloadData = scriptFile.map((item)=>{
      if(item.English || item.Index || item.time || item.status){
        delete item.English
        delete item.Index
        delete item.status
        delete item.time
      }  
      const statement = item[tranlatedLanguage]
      return (
        statement
      )
    })
    const script = downloadData.filter((item , key )=>{
      if ( item !==undefined){
        return (
          item
        )
      }
    })
    const downloadScript = script.map((caption, index) => {
      if (caption) {
        return (index > 0 ? '\n' : '') + [ 
          // index+1,
          `${caption}`
        ].join('\n')
      }
    });

    let content = `${tranlatedLanguage }\n\n\n`
    content += `${downloadScript}\n`;
      
    const element = document.createElement("a");
    const file = new Blob([content], {type: 'application/msword;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = tranlatedLanguage +  Date.now() + ".docx";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }


  handleSaveChanges = async () => {
    const { fields, selectedOriginalTextIndex, selectedOriginalText, status, tranlatedLanguage, scriptTranslatedText } = this.state;
    let statement = fields['scriptFile'][selectedOriginalTextIndex]
    statement[`English`] = selectedOriginalText
    let languages = fields['languages'].map(lang => typeof lang.language === 'object' ? lang.language.language : lang.language)
    if (!statement.status) {
      statement.status = {}
      languages.forEach(lkey => {
        if (tranlatedLanguage === lkey) {
          statement.status[lkey] = false
        } else {
          statement.status[lkey] = true
        }
      })
    }
    else {
      if (!statement.status[tranlatedLanguage]) {
        statement.status[tranlatedLanguage] = true
      } else {
        Object.keys(statement.status).forEach(key => {
          statement.status[key] = true
        })
      }
    }
    fields['scriptFile'][selectedOriginalTextIndex] = statement;
    this.setState({ fields })
    let data = {
      script: fields['scriptFile']
    }

    await this.props.updateScriptLanguage(this.state.projectId, data);
  }


  SaveChangesAndAddLine = async () => {
    const { fields, selectedOriginalTextIndex, selectedOriginalText, status,
      tranlatedLanguage, scriptTranslatedText } = this.state;
    this.handleSaveChanges();
    var index = this.state.selectedOriginalTextIndex + 1
    if (fields['scriptFile'].length == index) {
      this.handleAddRow();
    } else {
      let nextOriginalStatement = fields['scriptFile'][index]
      this.handleSelectedText(nextOriginalStatement.English, index)
    }
  }

  handlestartTime = (value) => {
    this.setState({ startTime: value })
  }

  handlestart = (v, ind) => {
    let startTime = moment(v._d).format('hh:mm:ss');
    const { fields, tranlatedLanguage } = this.state
    let statement = fields['scriptFile'][ind]
    if (statement.English.start) {
      statement.English.start = startTime
    }
    if (statement.time && statement.time[tranlatedLanguage]) {
      statement.time[tranlatedLanguage].start = startTime
    }
    this.setState({ fields, start: startTime })
  }

  handleEnd = (v, ind) => {
    let endTime = moment(v._d).format('hh:mm:ss');
    const { fields, tranlatedLanguage } = this.state
    let statement = fields['scriptFile'][ind]
    if (statement.English.end) {
      statement.English.end = endTime
    }
    if (statement.time && statement.time[tranlatedLanguage]) {
      statement.time[tranlatedLanguage].end = endTime
    }
    this.setState({ fields, end: endTime })

  }


  handleEndTime = (value) => {
    this.setState({ endTime: value })
  }


  handleAddRow = () => {
    let fields = { ...this.state.fields }
    let length = this.state.fields['scriptFile'].length
    fields['scriptFile'] = [...this.state.fields['scriptFile'], { Index: length, English: '' }]
    this.setState({ fields })
  }

  handleSuggestion = (e) => {
    this.setState({ selectSuggestion: e.target.value })

  }

  handleUseSuggestion = (suggestion) => {
    this.setState({ scriptTranslatedText: suggestion })
  }

  handleIsCheckedTime = (e) => {
    this.setState({ isCheckedTime: e.target.checked })
  }


  exportSRT = () => {
    const { tranlatedLanguage } = this.state;
    const srtString = this.state.fields['scriptFile'].map((caption, index) => {
      if (caption.time && caption[tranlatedLanguage]) {
        return (index > 0 ? '\n' : '') + [
          caption.Index,
          `${caption.time[tranlatedLanguage].start} --> ${(caption.time[tranlatedLanguage].end)}`,
          caption[tranlatedLanguage]
        ].join('\n')
      }else if (caption.English.text && caption[tranlatedLanguage]) {
        return (index > 0 ? '\n' : '') + [
          caption.Index,
          `${caption.English.start} --> ${(caption.English.end)}`,
          caption[tranlatedLanguage]
        ].join('\n')
      }
    }).join('\n') + '\n';
    const blob = new Blob([srtString], { type: "srt" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "script.srt";
    link.href = url;
    link.click();

  }

  downloadAsPDF = () => {

    const {tranlatedLanguage , fields } = this.state
    const scriptFile = fields['scriptFile']
    const downloadData = scriptFile.map((item)=>{
      if(item.English || item.Index || item.time || item.status){
        delete item.English
        delete item.Index
        delete item.status
        delete item.time
      }  
      const statement = item[tranlatedLanguage]
      return (
        statement
      )
    })
    const script = downloadData.filter((item , key )=>{
      if ( item !==undefined){
        return (
          item
        )
      }
    })
    const downloadScript = script.map((caption, index) => {
      if (caption) {
        return (index > 0 ? '\n' : '') + [ 
          index+1,
          `${caption}`
        ].join('\n')
      }
    });
  
        const doc = new jsPDF();
        const { selectedScript } = this.state;
        doc.setFontSize(22);
        doc.text(tranlatedLanguage, 20, 20); // title

        doc.setFontSize(14);
        let content = downloadScript
        doc.text(content, 20, 30);
        doc.save( tranlatedLanguage + Date.now()+".pdf")
                 
  }

  downloadAsCsv = () => {

    const {tranlatedLanguage , fields } = this.state
    const scriptFile = fields['scriptFile']
    const downloadData = scriptFile.map((item)=>{
      if(item.English || item.Index || item.time || item.status){
        delete item.English
        delete item.Index
        delete item.status
        delete item.time
      }  
      const statement = item[tranlatedLanguage]
      return (
        statement
      )
    })
    const script = downloadData.filter((item , key )=>{
      if ( item !==undefined){
        return (
          item
        )
      }
    })

    // var csv = `${tranlatedLanguage}\n`
    const downloadScript = script.map((caption, index) => {
      if (caption) {
        return (index > 0 ? '\n' : '') + [ 
          `${caption}`
        ].join('\n')
      }
    });
    let content = `${tranlatedLanguage }\n`
    content += `${downloadScript}\n`;
    // var csv = `${tranlatedLanguage}\n`;

    // script.forEach(function(row) {
    //   csv += row.join(',');
    //   csv += "\n";
    // });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(content);
    hiddenElement.target = '_blank';
    
    //provide the name for the CSV file to be downloaded
    hiddenElement.download = tranlatedLanguage + Date.now()+'.csv';
    hiddenElement.click();

  }

 


  ExportScriptFileToGoogleDrive = async () => {
    let data = {
      script: this.state.fields['scriptFile']
    }
    await this.props.exportToGoogleDrive(data);
  }

 
  ExportToExcel = () =>{
    const {tranlatedLanguage , fields } = this.state
    const scriptFile = fields['scriptFile']
    const worksheet = XLSX.utils.json_to_sheet(scriptFile);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Script.xlsx");

  }


  downloadAsRTF = () =>{

    const {tranlatedLanguage , fields } = this.state
    const scriptFile = fields['scriptFile']

   
    const downloadData = scriptFile.map((item)=>{
      if(item.English || item.Index || item.time || item.status){
        delete item.English
        delete item.Index
        delete item.status
        delete item.time
      }  
      const statement = item[tranlatedLanguage]
      return (
        statement
      )
    })
    const script = downloadData.filter((item , key )=>{
      if ( item !==undefined){
        return (
          item
        )
      }
    })

    // var csv = `${tranlatedLanguage}\n`
    const downloadScript = script.map((caption, index) => {
      if (caption) {
        return (index > 0 ? '\n' : '') + [ 
          `${caption}`
        ].join('\n')
      }
    });
    let content = `${tranlatedLanguage }\n`
    content += `${downloadScript}\n`;


    const plainText =  content.toString()

    // console.log("plainText",plainText)

    const htmlData =  this.htmlEscape(plainText)
    // console.log("htmlData", htmlData )

    const rtf = this.convertToRtf(plainText)

    // var htmlToRtf = HtmlToRtfBrowser();

    // const rtf = HtmlToRtfBrowser.convertHtmlToRtf(htmlData)

    const blob = new Blob([rtf], {type: "application/rtf;charset=utf-8"});
    const link=window.URL.createObjectURL(blob);
    window.location=link;
  }

  htmlEscape = (str) => {
    return String(str)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
}


  convertToRtf(plain) {
    plain = plain.replace(/\n/g, "\\par\n");
    return "{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang2057{\\fonttbl{\\f0\\fnil\\fcharset0 Microsoft Sans Serif;}}\n\\viewkind4\\uc1\\pard\\f0\\fs17 " + plain + "\\par\n}";
  }
 
  viewExtention = () => {

    this.setState({ showModel: true })

  }

  onCloseModel = () => {
    // console.log("item")

    this.setState({ showModel: false })
    this.getScriptContent(this.state.projectId)
   
  }
  changeStatus = () => {
      const { fields, tranlatedLanguage } = this.state
      // console.log( 'fields', fields, ' Language', tranlatedLanguage);
      const translationStatus = fields['each_translation_status'].find((item)=> item.language === tranlatedLanguage)
      const message = translationStatus.status === true ? 'Status will change to Inprogress': 'Status will change to Complete'
      const selectedLanguage = fields.languages.find(lang => {
        if(typeof lang.language === 'object' && lang.language.language === translationStatus.language){
          return lang
        }else if(lang.language === translationStatus.language){
          return lang
        }
      })
      swal({
        title: "Are you sure?",
        text: `${message}`,
        icon: "warning",
        buttons: ['Cancel', 'Yes'],
        dangerMode: true,
      })
      .then(async (pressYes) => {
        if (pressYes) {
          // const projectAssigner = fields.activities[0]
          const data = {
            mailToUser: selectedLanguage.translatorId,
            projectName: fields.projectname,
            translationStatus: translationStatus
          }
          const webHookURL= ENV.slackWebHookLink;
          const webHookData={
            "text":`Translator Name:${selectedLanguage.translatorId.fullname}\n Translator Email:${selectedLanguage.translatorId.email}\n Project Name:${fields['projectname']} \n Message: Translation Completed for ${translationStatus.language}`
          }
            // await this.AddStatusActivity(translationStatus)
        
            // if(this.props.projectUpdated.success){
             
              await this.props.sendTranslationNotification(this.state.projectId, data)

            // }
          await axios.post(webHookURL,JSON.stringify(webHookData), {
            withCredentials:false,
            transformRequest:[(response, headers)=>{
              delete headers.post["Content-Type"]
              return response;
            }]  
          })
          if(this.props.completeTranslationStatus){
            swal(this.props.completeTranslationStatus.message, {icon: "success"});
            this.getScriptContent(this.state.projectId)
            if (this.props?.scriptData?.success) {

              setTimeout(async()=>{

                await this.AddStatusActivity(translationStatus)

              },500)
              
            }
          }  
        }
      })
  }


  AddStatusActivity = async( translationStatus )=>{

    const {fields , tranlatedLanguage } = this.state ; 

    const user = new Object();
    user.userDetail = decodedToken;
    user.activity = translationStatus.status === true ? ` ${tranlatedLanguage} Translation Status Change To Inprogress`  : ` ${tranlatedLanguage} Translation Status  Change To Complete`;
    user.createdAt = moment().format()
    fields['activities'].push(user)
    this.setState({fields})
    let data ={
      ...this.state.fields,
    }
    await this.props.updateProject(this.state.projectId,data);
    if(this.props.projectUpdated.success){
      this.setState({ disable: false })
      toast.success(this.props.projectUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }else{
      this.setState({ disable: false })
      toast.dismiss()
      toast.error( this.props.projectUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }
 
  }


  render() {
    const { tranlatedLanguage, totalScriptSuggestion } = this.state;
    const assignerEmail = this.state.fields.activities
    const ExportData = this.state.fields['scriptFile'].filter((item)=>{
      if(item[tranlatedLanguage]){
        return true 
      }
    })

    if(this.state.tranlatedLanguage){
      var  translationStatus = this.state.fields['each_translation_status'].filter((item)=>{
        if(item.language == tranlatedLanguage)
        return item
      })
    }

    const check = ['undefined', undefined, 'null', null, 0, '0', ''];

    return (
      <>
        <Modal show={this.state.showModel} onHide={this.onCloseModel}>
            <div>
                  <span
                      className="trash_icon"
                      onClick={ ()=> this.onCloseModel()}>
                      <i className="fa fa-times" aria-hidden="true" />
                  </span>
            </div>
            <Modal.Body className='modelDownloadFormate'>
                {/* <Button className='pdfFormetermainwraper'>
                <PDFDownloadLink document={<MyDocument script = {this.state.fields['scriptFile']} language = {this.state.tranlatedLanguage}/>} fileName={`script`}> Pdf </PDFDownloadLink>
                </Button> */}
                <Button onClick = {this.downloadAsPDF}> Pdf </Button>
                <Button onClick = {this.handleDownLoadScript}> Text </Button>
                <Button onClick = { this.exportToDoc}> Doc </Button>
                <Button onClick = {this.exportSRT}> Srt </Button>
                <Button onClick = {this.ExportToExcel}> Xlsx </Button>
                {/* <Button onClick = {this.downloadAsCsv}> Csv </Button> */}
                <Button onClick = {this.downloadAsRTF}> Rtf </Button>
                <Button className='pdfFormetermainwraper'>
                  <CSVLink data = {this.state.fields['scriptFile']}> Csv </CSVLink>
                </Button>
            </Modal.Body>
        </Modal>
        <Container fluid className="p-sm-0">
          <Row className="mb-4">
            <Col sm={12} className="p-0">
              <div className="headTag d-flex align-items-center">
                <span className='back-icon-script' onClick={() => this.props.history.goBack()}><i class="fas fa-angle-left"></i></span>
              </div>
            </Col>
            <Col sm={12}>
              <Card className="projectTable border-0 rounded-0">
                <Card.Body>
                  <Row className='next-top-row'>
                    <Col sm={12} className="p-0 top-next-buttons">
                      <div className='top-next-buttons'>
                        <button class="btn button-script"><i class="fa fa-angle-right"></i>{this.state.fields['projectname']}</button>
                        <button class="btn button-script button-english">English (en) <i class="fa fa-arrow-right"></i></button>
                        <div className="select-box-next">
                          <select className="form-control" name="tranlatedLanguage" value={this.state['tranlatedLanguage']} onChange={this.handleSelectLanguage}>
                            <option value=''>Select language</option>
                            {this.state.fields.languages.map((t, i) => {
                              if (typeof t.language === 'object') {
                                return (
                                  <option key={i} value={t?.language?.language}>{t?.language?.language}</option>
                                )
                              } else {
                                return (
                                  <option key={i} value={t.language}>
                                    {t.language}
                                  </option>
                                )
                              }
                            })}
                          </select>
                          {this.state['tranlatedLanguage'] ?
                            <>
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {this.state.isCheckedTime ? 'hide start and end time if not required' : 'show start and end time if required'}
                                  </Tooltip>
                                )}
                                placement="left"
                              >
                                <div class="custom-control custom-switch">
                                  <input type="checkbox" class="custom-control-input" id="customSwitch1" value={this.state.isCheckedTime} onChange={this.handleIsCheckedTime} />
                                  <label class="custom-control-label" for="customSwitch1">{this.state.isCheckedTime ? 'Hide' : 'Show'}</label>
                                </div>
                              </OverlayTrigger>
                            </>
                            : null
                          }

                        </div>
                      </div>
                      <div className='status'>
                        {this.state.tranlatedLanguage ? 
                          translationStatus.length ?
                              translationStatus.map((item)=>{
                                return (
                                  <p> Status : {item.status == true ? 'Completed' : ' Inprogress'}</p>
                                )
                              })
                          :  <p> Status : Inprogress  </p> 
                        :null  
                        }
                      </div>
                      {this.state.tranlatedLanguage ?
                        <div className='submit-project-button'>
                            <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {translationStatus.length ?
                                      translationStatus.map((item)=>{
                                    return (
                                      <p>{item.status == true ? 'Click to Change Translation Status to Inprogress' : ' Click to Change Translation Status to Complete '}</p>
                                    )
                                     })
                                    :  <p> Click to Complete Translation  </p> }
                                  </Tooltip>
                                )}
                                placement="left"
                            >
                              <Button style={{ backgroundColor: '#C93D3A', border: 'none', color: 'white' }} type="submit"  onClick={()=>this.changeStatus(translationStatus)}>
                                {translationStatus.length ?
                                        translationStatus.map((item)=>{
                                      return (
                                        item.status == true ? 'Mark as Inprogress' : ' Mark as Complete  '
                                      )
                                      })
                                      :  'Mark as Complete ' 
                                }
                              </Button>
                            </OverlayTrigger>
                        </div>
                      :null  
                      }  
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="progressbar-row">
                      <ProgressBar now={60} />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8} className="">
                      <div className='edit-script-translate'>
                        <div className='search-filter'>
                          <input type="text" class="form-control" id="exampleInputext" aria-describedby="text" placeholder="Search" onChange={this.handleSearchScript}></input>
                          <i className="fas fa-search"></i>
                        </div>
                        <div className='add-btn-row'>
                          <Button onClick={this.handleAddRow}> <span
                            className='plus-icon'>&#x2B;</span>Add a line</Button>
                        </div>
                      </div>
                      <Row className='wraper-script-translate'>
                        <Col sm={12} className="tablecheckbox-t">
                          <div className="table-main-script">
                            <div className='select-language-wrap'>
                              {this.state['tranlatedLanguage'] && this.state.isCheckedTime == true ?
                                <Col sm={3} className='time-w'>
                                  <h5>Time</h5>
                                </Col>
                              :
                                null
                              }
                              <Col sm={5}>
                                <h5>English</h5>
                              </Col>
                              <Col sm={4}>
                                <h5>{this.state['tranlatedLanguage']}</h5>
                              </Col>
                            </div>
                            {this.state.searchScriptText.length == 0 ? (

                              this.state.fields['scriptFile'].map((language, languageIndex) => {
                                if (typeof language.English === 'object') {
                                  return (
                                    <div className='script-main-wrap' key={languageIndex} onClick={() => this.handleSelectedText(language.English.text, language.Index, language[tranlatedLanguage])}
                                      style={this.state.boxColor === language.Index ? { background: "#e8e8e8", cursor: 'pointer' } : { background: 'white', cursor: 'pointer' }}
                                    >
                                      {this.state['tranlatedLanguage'] && this.state.isCheckedTime == true
                                        ? <Col sm={3} className='date-time-w'>
                                          {language.English.start && language.English.end
                                            ?
                                            <div key={languageIndex} className='date-time-picker'>
                                              <div className='start-time-width'>
                                                <p className='start-time-text'>start Time</p>
                                                <TimePicker
                                                  value={moment(language.English.start, "hh:mm:ss")}
                                                  id={language.Index}
                                                  onChange={(v, id = language.Index) => this.handlestart(v, id)}
                                                />
                                              </div>
                                              <div className='start-time-width'>
                                                <p className='start-time-text'>End Time</p>
                                                <TimePicker
                                                  value={moment(language.English.end, "hh:mm:ss")}
                                                  id={language.Index}
                                                  onChange={(v, id = language.Index) => this.handleEnd(v, id)}
                                                />
                                              </div>
                                            </div>

                                            :
                                            <div key={languageIndex} className='date-time-picker'>
                                              <div className='start-time-width'>
                                                <p className='start-time-text'>start Time</p>
                                                <TimePicker
                                                  value={this.state.startTime}
                                                  onChange={this.handlestartTime}
                                                />
                                              </div>
                                              <div className='start-time-width'>
                                                <p className='start-time-text'>End Time</p>
                                                <TimePicker
                                                  value={this.state.endTime}
                                                  onChange={this.handleEndTime}
                                                />
                                              </div>
                                            </div>
                                          }

                                        </Col>
                                        : null
                                      }
                                      <Col sm={5} className='left-side-wrap'>
                                        <div className='wrap-language-index'>
                                          <p className='left-index'>
                                            {language.Index + 1}
                                          </p>
                                          <p>
                                            {language.English.text}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col sm={4} className='right-side-wrap'>
                                        <div key={languageIndex} className='checkbox-style-wrap' >
                                          <p className='translate-circle-wrap'>
                                            {(language.status && language[tranlatedLanguage] && language.status[tranlatedLanguage])
                                              ? <span className='circle-language'><i class="fas fa-circle"></i></span>
                                              : null
                                            }
                                            {language[tranlatedLanguage] || ''}
                                          </p>
                                          {/* <div className='checkbox-pointer'>
                                            <input type="checkbox"
                                              value={language.Index}
                                              checked={this.state.isChecked.includes(language.Index)}
                                              onChange={(e) => this.handleCheckBox(e, language.Index)} />
                                          </div> */}
                                        </div>
                                      </Col>
                                      <Col sm={3} className='right-side-wrap'>
                                      <div className='checkbox-pointer'>
                                            <input type="checkbox"
                                              value={language.Index}
                                              checked={this.state.isChecked.includes(language.Index)}
                                              onChange={(e) => this.handleCheckBox(e, language.Index)} />
                                          </div>
                                      </Col>
                                    </div>
                                  )
                                }else{
                                  return (
                                    <div className='script-main-wrap' key={languageIndex}
                                      onClick={() => this.handleSelectedText(language.English,
                                        language.Index, language[tranlatedLanguage])}
                                      style={this.state.boxColor === language.Index ? {
                                        background: "#e8e8e8", cursor: 'pointer'
                                      } : {
                                        background: 'white', cursor: 'pointer'
                                      }}
                                    >
                                      {this.state['tranlatedLanguage'] && this.state.
                                        isCheckedTime == true ?
                                        <Col sm={3} className='date-time-w'>
                                          {language.time && language.time
                                          [tranlatedLanguage] ?
                                            <div key={languageIndex} className='date-time-picker'
                                            >
                                              <div className='start-time-width'>
                                                <p className='start-time-text'>
                                                  start Time
                                                </p>
                                                <TimePicker
                                                  value={moment(language.time
                                                  [tranlatedLanguage].start, "hh:mm:ss")}
                                                  id={language.Index}
                                                  onChange={(v, id = language.Index) => this.handlestart(v, id)}
                                                />
                                              </div>
                                              <div className='start-time-width'>
                                                <p className='start-time-text'>End Time</p>
                                                <TimePicker
                                                  value={moment(language.time
                                                  [tranlatedLanguage].end, "hh:mm:ss")}
                                                  id={language.Index}
                                                  onChange={(v, id = language.Index) => this.handleEnd(v, id)}
                                                />
                                              </div>
                                            </div>
                                            :
                                            <div key={languageIndex}
                                              className='date-time-picker'
                                            >
                                              <div className='start-time-width'>
                                                <p className='start-time-text'>
                                                  start Time
                                                </p>
                                                <TimePicker
                                                  value={this.state.startTime}
                                                  onChange={this.handlestartTime}
                                                />
                                              </div>
                                              <div className='start-time-width'>
                                                <p className='start-time-text'>
                                                  End Time
                                                </p>
                                                <TimePicker
                                                  value={this.state.endTime}
                                                  onChange={this.handleEndTime}
                                                />
                                              </div>
                                            </div>
                                          }
                                        </Col>
                                        : null
                                      }
                                      <Col sm={5} className='left-side-wrap'>
                                        <div className='wrap-language-index'>
                                          <p className='left-index'>
                                            {language.Index + 1}
                                          </p>
                                          <p>
                                            {language.English}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col sm={4} className='right-side-wrap'>
                                        <div key={languageIndex}
                                          className='checkbox-style-wrap'
                                        >
                                          <p className='translate-circle-wrap'>
                                            {(language.status && language
                                            [tranlatedLanguage] && language.status
                                              [tranlatedLanguage])
                                              ? <span className='circle-language'><i
                                                class="fas fa-circle"></i>
                                              </span>
                                              : null
                                            }
                                            {language[tranlatedLanguage] || ''}
                                          </p>
                                          {/* <div className='checkbox-pointer'>
                                            <input type="checkbox"
                                              value={language.Index}
                                              checked={this.state.isChecked.includes
                                                (language.Index)}
                                              onChange={(e) => this.handleCheckBox(e,
                                                language.Index)}
                                            />
                                          </div> */}
                                        </div>
                                      </Col>
                                      <Col sm={3} className='right-side-wrap'>
                                          <div className='checkbox-pointer'>
                                            <input type="checkbox"
                                              value={language.Index}
                                              checked={this.state.isChecked.includes(language.Index)}
                                              onChange={(e) => this.handleCheckBox(e,language.Index)}
                                            />
                                          </div>
                                      </Col>
                                    </div>
                                  )
                                }
                              })
                            )
                              :
                              (
                                this.state.updateScript.map((language, languageIndex) => {
                                  if (typeof language.English === 'object') {
                                    return (
                                      <div className='script-main-wrap' key={languageIndex} onClick={() => this.handleSelectedText(language.English.text, language.Index, language[tranlatedLanguage])}
                                        style={this.state.boxColor === language.Index ? { background: "#e8e8e8", cursor: 'pointer' } : { background: 'white', cursor: 'pointer' }}
                                      >
                                        {this.state['tranlatedLanguage'] && this.state.isCheckedTime == true
                                          ? <Col sm={3} className='date-time-w'>
                                            {language.English.start && language.English.end
                                              ?
                                              <div key={languageIndex} className='date-time-picker'>
                                                <div className='start-time-width'>
                                                  <p className='start-time-text'>start Time</p>
                                                  <TimePicker
                                                    value={moment(language.English.start, "hh:mm:ss")}
                                                    id={language.Index}
                                                    onChange={(v, id = language.Index) => this.handlestart(v, id)}
                                                  />
                                                </div>
                                                <div className='start-time-width'>
                                                  <p className='start-time-text'>End Time</p>
                                                  <TimePicker
                                                    value={moment(language.English.end, "hh:mm:ss")}
                                                    id={language.Index}
                                                    onChange={(v, id = language.Index) => this.handleEnd(v, id)}
                                                  />
                                                </div>
                                              </div>

                                              :
                                              <div key={languageIndex} className='date-time-picker'>
                                                <div className='start-time-width'>
                                                  <p className='start-time-text'>start Time</p>
                                                  <TimePicker
                                                    value={this.state.startTime}
                                                    onChange={this.handlestartTime}
                                                  />
                                                </div>
                                                <div className='start-time-width'>
                                                  <p className='start-time-text'>End Time</p>
                                                  <TimePicker
                                                    value={this.state.endTime}
                                                    onChange={this.handleEndTime}
                                                  />
                                                </div>
                                              </div>
                                            }

                                          </Col>
                                          : null
                                        }
                                        <Col sm={5} className='left-side-wrap'>
                                          <div className='wrap-language-index'>
                                            <p className='left-index'>
                                              {language.Index + 1}
                                            </p>
                                            <p>
                                              {language.English.text}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col sm={4} className='right-side-wrap'>
                                          <div key={languageIndex} className='checkbox-style-wrap' >
                                            <p className='translate-circle-wrap'>
                                              {(language.status && language[tranlatedLanguage] && language.status[tranlatedLanguage])
                                                ? <span className='circle-language'><i class="fas fa-circle"></i></span>
                                                : null
                                              }
                                              {language[tranlatedLanguage] || ''}
                                            </p>
                                            {/* <div className='checkbox-pointer'>
                                              <input type="checkbox"
                                                value={language.Index}
                                                checked={this.state.isChecked.includes(language.Index)}
                                                onChange={(e) => this.handleCheckBox(e, language.Index)} />
                                            </div> */}
                                          </div>
                                        </Col>
                                        <Col sm={3} className='right-side-wrap'>
                                            <div className='checkbox-pointer'>
                                              <input type="checkbox"
                                                value={language.Index}
                                                checked={this.state.isChecked.includes(language.Index)}
                                                onChange={(e) => this.handleCheckBox(e, language.Index)} />
                                            </div>
                                        </Col>
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div className='script-main-wrap' key={languageIndex}
                                        onClick={() => this.handleSelectedText(language.English,
                                          language.Index, language[tranlatedLanguage])}
                                        style={this.state.boxColor === language.Index ? {
                                          background: "#e8e8e8", cursor: 'pointer'
                                        } : {
                                          background: 'white', cursor: 'pointer'
                                        }}
                                      >
                                        {this.state['tranlatedLanguage'] && this.state.
                                          isCheckedTime == true ?
                                          <Col sm={3} className='date-time-w'>
                                            {language.time && language.time
                                            [tranlatedLanguage] ?
                                              <div key={languageIndex} className='date-time-picker'
                                              >
                                                <div className='start-time-width'>
                                                  <p className='start-time-text'>
                                                    start Time
                                                  </p>
                                                  <TimePicker
                                                    value={moment(language.time
                                                    [tranlatedLanguage].start, "hh:mm:ss")}
                                                    id={language.Index}
                                                    onChange={(v, id = language.Index) => this.handlestart(v, id)}
                                                  />
                                                </div>
                                                <div className='start-time-width'>
                                                  <p className='start-time-text'>End Time</p>
                                                  <TimePicker
                                                    value={moment(language.time
                                                    [tranlatedLanguage].end, "hh:mm:ss")}
                                                    id={language.Index}
                                                    onChange={(v, id = language.Index) => this.handleEnd(v, id)}
                                                  />
                                                </div>
                                              </div>
                                              :
                                              <div key={languageIndex}
                                                className='date-time-picker'
                                              >
                                                <div className='start-time-width'>
                                                  <p className='start-time-text'>
                                                    start Time
                                                  </p>
                                                  <TimePicker
                                                    value={this.state.startTime}
                                                    onChange={this.handlestartTime}
                                                  />
                                                </div>
                                                <div className='start-time-width'>
                                                  <p className='start-time-text'>
                                                    End Time
                                                  </p>
                                                  <TimePicker
                                                    value={this.state.endTime}
                                                    onChange={this.handleEndTime}
                                                  />
                                                </div>
                                              </div>
                                            }
                                          </Col>
                                          : null
                                        }
                                        <Col sm={5} className='left-side-wrap'>
                                          <div className='wrap-language-index'>
                                            <p className='left-index'>
                                              {language.Index + 1}
                                            </p>
                                            <p>
                                              {language.English}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col sm={4} className='right-side-wrap'>
                                          <div key={languageIndex}
                                            className='checkbox-style-wrap'
                                          >
                                            <p className='translate-circle-wrap'>
                                              {(language.status && language
                                              [tranlatedLanguage] && language.status
                                                [tranlatedLanguage])
                                                ? <span className='circle-language'><i
                                                  class="fas fa-circle"></i>
                                                </span>
                                                : null
                                              }
                                              {language[tranlatedLanguage] || ''}
                                            </p>
                                              <div className='checkbox-pointer'>
                                                <input type="checkbox"
                                                  value={language.Index}
                                                  checked={this.state.isChecked.includes(language.Index)}
                                                  onChange={(e) => this.handleCheckBox(e,language.Index)}
                                                />
                                              </div>
                                          </div>
                                        </Col>
                                      </div>
                                    )
                                  }
                                })
                              )
                            }
                          </div>
                        </Col>
                        {/* <div className='add-btn-row'> */}
                        {/* <Button onClick={this.handleAddRow}> <span className='plus-icon'>&#x2B;</span>Add a line</Button> */}
                        {/* </div> */}
                      </Row>
                    </Col>
                    {this.state.isChecked.length > 0 ?
                      <>
                        <div className='button-script-delete'>
                          <Button onClick={this.DeleteSelectdRow}>Delete</Button>
                          {/* <Button onClick={this.handleDownLoadScript}>Download Script</Button> */}
                        </div>
                      </>
                      :
                      <>
                        <Col sm={4} className="">
                          <Row className='tab-content-next-sec'>
                            <Col sm={12} className="tabs-section-right">
                              <Row className='tab-second-sec-padding'>
                                <Col sm={12} className="suggation-sec">
                                  <p>
                                    { !check.includes(this.state.suggestion) ?
                                      <span>
                                        {this.state.suggestion.length == 0
                                          ? 'No Suggation available' : <span>{this.state.suggestion.length}Suggation available</span>
                                        }
                                      </span>
                                      :
                                      <span>
                                        No Suggation available
                                      </span>
                                    }
                                  </p>
                                </Col>
                                { !check.includes(this.state.suggestion) ?
                                  (
                                    <Row className=''>
                                      {this.state.suggestion.length > 0 ?
                                        <>
                                          <Col sm={12}>
                                            {this.state.suggestion.map((item) => {
                                              if (item.English.text) {
                                                return (
                                                  <div className='main-wraper-f'>
                                                    <div className='second-wrap'>
                                                      <div className='box-presentage'>
                                                        <p className='pre-p'>{item.percentage}</p>
                                                        <p>match</p>
                                                      </div>
                                                    </div>
                                                    <div className='text-wrap-s'>
                                                      <p>{item[tranlatedLanguage]}</p>
                                                      <p className='text-dark'>{item.English.text}</p>
                                                    </div>
                                                    <div className='logo-text-sec'>
                                                      <p><i class="fas fa-exchange-alt"></i> <span className='data-text-sec-user'> <Button onClick={() => this.handleUseSuggestion(item[tranlatedLanguage])}>
                                                        used</Button></span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                )
                                              } else {
                                                return (
                                                  <div className='main-wraper-f'>
                                                    <div className='second-wrap'>
                                                      <div className='box-presentage'>
                                                        <p className='pre-p'>{item.percentage}</p>
                                                        <p>match</p>
                                                      </div>
                                                    </div>
                                                    <div className='text-wrap-s'>
                                                      <p>{item[tranlatedLanguage]}</p>
                                                      <p className='text-dark'>{item.English}</p>
                                                    </div>
                                                    <div className='logo-text-sec'>
                                                      <p><i class="fas fa-exchange-alt"></i> <span className='data-text-sec-user'> <Button onClick={() => this.handleUseSuggestion(item[tranlatedLanguage])}>
                                                        used</Button></span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })}
                                          </Col>
                                        </> : null
                                      }

                                    </Row>

                                  ) 
                                : null

                                }

                              </Row>
                            </Col>
                          </Row>
                          <Col sm={12} className="third-colom-sec">
                            <Row>
                              <Col sm={12} className="edit-context-text-copy">
                                <div>
                                  <div className='mb-2'>
                                    <textarea
                                      value={this.state.selectedOriginalText.text ? this.state.selectedOriginalText.text : this.state.selectedOriginalText}
                                      class="form-control"
                                      rows="5"
                                      placeholder='Original text here...'
                                      onChange={this.handleOriginalText}
                                    />
                                  </div>
                                  <div className='script-changes-btn'>
                                    <Button className='mr-2' variant="info" onClick={this.handleSaveChanges} disabled={!this.state.selectedOriginalText}>Save Changes</Button>
                                    <Button variant="info" onClick={this.
                                      SaveChangesAndAddLine} disabled={!this.state.
                                        selectedOriginalText}>Save and Add Line</Button>
                                  </div>
                                  <textarea
                                    value={this.state.scriptTranslatedText}
                                    onChange={this.handleTranslateText}
                                    class="form-control"
                                    rows="5"
                                    placeholder='Your translated text here...
                                                 Please select a language...'
                                    disabled={!tranlatedLanguage}
                                  />
                                  {/* <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom">
                                    Tooltip on bottom                    {this.state.isChecked.length > 0 ?

                                  </button> */}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} className="edit-context-dropdown">
                                <Button className='mr-2' variant="info" onClick={this.submitTranlation} disabled={!tranlatedLanguage || !this.state.scriptTranslatedText || !this.state.selectedOriginalText}>Save Translation</Button>
                                <Button variant="info" onClick={this.saveAndNextTranslation}
                                  disabled={!tranlatedLanguage || !this.state.
                                    scriptTranslatedText || !this.state.selectedOriginalText}
                                >Save and Next Line</Button>
                              </Col>
                              <Col>
                                <div>

                                  { ExportData.length > 0 ?
                                    <>
                                      <Button className='ml-3' onClick={this.viewExtention}> Export </Button>
                                    </>
                                    : ''
                                  }
                                </div>
                                {/* <div>
                                      <Button className='mt-3' onClick={this.ExportScriptFileToGoogleDrive}>Export To Google Drive</Button>
                                  </div> */}
                                {/* {this.state.tranlatedLanguage?
                                      <div>
                                        <Button className='mt-3' onClick={this.notifyToSlack}><span><img src={require("assets/img/slack11.png")}></img></span>Send Notification</Button>
                                      </div>
                                   : null     
                                  } */}

                              </Col>
                            </Row>
                          </Col>
                        </Col>
                      </>
                    }
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
const mapStateToProps = state => {
  const { project } = state
  return {
    projectAdded: project.add,
    scriptData: project.single_script_data,
    UpdatedProjectData: project.update_script_data,
    fetchScriptSuggestion: project.allScriptSuggestion,
    completeTranslationStatus:project.completeTranslationStatus,
    total_users: project.all_users,
    projectUpdated : project.updateProjectApi,
  }
}
const dispatchAction = { addProject, singleScript, updateScriptLanguage, scriptSuggestions, exportToGoogleDrive , sendTranslationNotification , fetchAllUsers , updateProject}
export default connect(mapStateToProps, dispatchAction)(EditProjectScript);