import { FETCH_MESSAGES, DELETE_MESSAGE } from "../actions/types";

const initialState = { messages:'' };

export default (state = initialState, action) => {
    switch (action.type) {
    case FETCH_MESSAGES:
        return {
            ...state,
            messages: action.payload
        }
    default:
        return state
    }
}