import React, {Component} from 'react';
import { Button, Dropdown, Form, Media, Badge } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { fetchMessages, deleteMessage } from '../../actions/chatAction';
import Modal from 'react-responsive-modal';
import ReactPaginate from 'react-paginate';
import Switch from "react-switch";
import { socket } from '../../actions/fetchActions';
import { getAdmin } from '../../actions/adminActions';
import { isLoggedIn, decodedToken } from '../../helpers/decode-token';
import swal from 'sweetalert';
import appConfig from 'appConfig';
var moment = require('moment'); 
class ChatBox extends Component{
    constructor(props){
        super(props);
        this.state = {
            message:'',
            messages:[],
            room:props.room,
            loader:false,
            admin:''
        }
    }

    componentDidMount(){
        var { messages, room } = this.state;
        var {senderId, receiverId, type} = this.props;
        
        this.getMessages(room);
        this.fetchUser();       

        socket.emit('change-message-status', {room, receiverId,type});

        socket.on('message', message => {
            
            if(message.room == this.state.room){
                this.setState({ messages : [ ...this.state.messages, message ] })
                this.getMessages(this.state.room);
                if(document.querySelector(".messageBox")!== null){

                    document.querySelector(".messageBox").scrollTop = document.querySelector(".messageBox").scrollHeight;
                }
                this.props.getTranslator( 1, 10,'');
            }
        });

        socket.on('delete-message-response', message => {
            if(message.room == room){
                this.getMessages(room)
            }
        });

        socket.on('change-status-response', message => {
            
            if(message.room == room){
                this.getMessages(room);
                if(document.querySelector(".messageBox")!== null){

                    document.querySelector(".messageBox").scrollTop = document.querySelector(".messageBox").scrollHeight;
                }
                this.props.getTranslator( 1, 10,'');
            }
        });
        
    }




    ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;
     
      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

    getMessages = async ( room ) => {
        this.setState({ loader: false })

        await this.props.fetchMessages(room)
        if(this.props.messages.response){

            this.setState({
                messages: this.props.messages.messages,
                loader: false
            })
            if(document.querySelector(".messageBox") !== null){

                document.querySelector(".messageBox").scrollTop = document.querySelector(".messageBox").scrollHeight;
            }
        }
        
    }

    fetchUser = async () => {
        await this.props.getAdmin(decodedToken.userId)
        if(this.props.admin.success){
            this.setState({
                admin: this.props.admin.user,
                loader:false,
            })
        }else{
            toast.dismiss()
            toast.error(this.props.admin.message, {position: toast.POSITION.TOP_CENTER, hideProgressBar: true})
        }
    }



    sendMessage = (event) => {
        event.preventDefault();
        const { message, room  } = this.state;
        var {senderId, receiverId, type} = this.props;
        if(message) {
          socket.emit('sendMessage', {message, room, senderId, receiverId, type });
          this.setState({ message: '' })
        }

        this.props.getTranslator( 1, 10,'');
    }

    timeSince = (date) => {

        let new_date = new Date(date);

        var seconds = Math.floor((new Date() - new_date) / 1000);
      
        var interval = seconds / 31536000;
      
        if (interval > 1) {
        
            return moment(date).format('DD-MM-YYYY') ;
        }
        interval = seconds / 2592000;
        if (interval > 1) {
        
            return moment(date).format('DD-MM-YYYY') ;
        }
        interval = seconds / 86400;
        if (interval > 1) {
        
            return moment(date).format('DD-MM-YYYY') ;
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) + " minutes ago";
        }
        if(seconds == -1){
            seconds = 1;
        }
        return Math.floor(seconds) + " seconds ago";
    }

    onDeleteMessage = ( messageId ) => {
        swal({
          title: "Are you sure?",
          text: `Once Deleted, "Message will be permanently delete`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {

            const {  room  } = this.state;
            socket.emit('delete-message', { messageId, room });
          } else {
              swal("Good job!", `Message is Safe`, "success");
          }
        });
      }

    componentDidUpdate = async (prevProps, prevState) =>  {
        //Typical usage, don't forget to compare the props
        
        if (this.props.room !== prevProps.room && this.props.receiverId !== null) {
            
            await this.getMessages(this.props.room);
            this.setState({ room : this.props.room })
            socket.on('message', message => {
                
                if(message.room == this.props.room){
                    this.setState({ messages : [ ...this.state.messages, message ] })
                    this.getMessages(this.props.room);
                    if(document.querySelector(".messageBox")!== null){
    
                        document.querySelector(".messageBox").scrollTop = document.querySelector(".messageBox").scrollHeight;
                    }
                }
            });

            socket.on('change-status-response', message => {
               
                if(message.room == this.props.room){
                    this.getMessages(this.props.room);
                    if(document.querySelector(".messageBox")!== null){
    
                        document.querySelector(".messageBox").scrollTop = document.querySelector(".messageBox").scrollHeight;
                    }
                    this.props.getTranslator( 1, 10,'');
                }
            });
        }

    }

    render(){

        const { message, room, messages, admin, loader } = this.state;
        return (
            <>
            
            <div className="userName mb-3 border-bottom pb-3">
                <Media className="align-items-center">
                    <span className="userIcon">
                        <img
                            width={35}
                            height={35}
                            src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${this.props.translatorDetails.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${this.props.translatorDetails.profile}`  : require("assets/img/default-avatar.png")}
                            onError={(e)=>{{
                            e.target.onerror = null;
                            e.target.src= require("assets/img/default-avatar.png");
                            }}}
                            alt="Generic placeholder"
                            style={{borderRadius: "50%"}}
                        />
                        <Badge variant={this.props.translatorDetails.online_status ? "primary" : "secondary"} className={ this.props.translatorDetails.online_status ? "badgeOnline" :''} ></Badge>                                            
                    </span>
                    <Media.Body>
                        <h5 className="mb-0">{this.props.translatorDetails.fullname}</h5>
                    </Media.Body>
                </Media>
            </div>
            <div className="messageBox massage-box-pb">
             
                {
                    messages.map(( t, i ) => {
                        return (
                            <Media  className={t.type == this.props.type ? "userReply justify-content-end" : ""}  key={i}>
                                {t.type !== this.props.type ?
                                <img
                                    width={35}
                                    height={35}
                                    className={t.type == this.props.type ? "ml-3" : "mr-3"}
                                    src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${this.props.translatorDetails.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${this.props.translatorDetails.profile}`  : require("assets/img/default-avatar.png")} 
                                    onError={(e)=>{{
                                    e.target.onerror = null;
                                    e.target.src= require("assets/img/default-avatar.png");
                                    }}}
                                    alt="Generic placeholder"
                                    style={{borderRadius: "50%"}}
                                />
                                :''}
                                {t.deleted_at == null ? 
                                    <Media.Body>
                                        <h5>{t.message} </h5>
                                        <p className="d-flex align-items-center">{this.timeSince(t.createdAt)} 
                                        {t.type == this.props.type ?
                                        <span className="spanTick"><i class="fas fa-check" style={{ color:t.message ? t.read ? '#fdfdfd' : 'black': '#2bb0a2'}} ></i> </span>
                                        :''}
                                        </p>
                                        {t.type == this.props.type ?
                                        <>
                                        <span className="moreActions">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <i className="fas fa-angle-down"></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    
                                                    <Dropdown.Item  onClick={() => {
                                                        this.onDeleteMessage(t._id);
                                                        
                                                        }}>Delete</Dropdown.Item>
                                                    
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            </span>
                                            
                                        </>

                                        : ''}

                                    </Media.Body>
                                    :
                                    <Media.Body>
                                        <p>This Message has been deleted</p>
                                    </Media.Body>
                                
                            
                                }


                                {t.type == this.props.type ?
                                
                                <img
                                    width={35}
                                    height={35}
                                    className={t.type == this.props.type ? "ml-3" : "mr-3"}
                                    style={{borderRadius: "50%"}}
                                    src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/profile/`+`${admin.profile}`  : require("assets/img/user-icon.png")} 
                                    alt="Generic placeholder"
                                />
                                :''}
                            </Media>
                        )
                    })
                }
            </div>
            <div className="replyBox">
                <Form>
                    <input type="text" name="message" placeholder="Type a message here"  value={message}
                        onChange={({ target: { value } }) => this.setState({ message: value })}
                        onKeyPress={event => event.key === 'Enter' ? this.sendMessage(event) : null} />
                    <Button type="submit" variant="primary" onClick={e => this.sendMessage(e)}><i class="fas fa-paper-plane"></i></Button>
                </Form>
            </div>
        </>
        )
    }
}

// export default ChatBox
const mapStateToProps = state => {
    const { admin, messages } = state
        return {
            messages: messages.messages,
            admin: admin.admin
        }
    }
    
    const dispatchState = { 
        fetchMessages, getAdmin
    }
    export default connect(mapStateToProps, dispatchState)(ChatBox)