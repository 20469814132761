import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class NotFound extends Component{
    render(){
        return(
            
            <div className="not_found">
                <aside><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/Mirror.png" alt="404 Image" />
                </aside>
                <main style={{textAlign:'center' }}>
                    <h1>Sorry!</h1>
                    <p>
                    Either you aren't authotized to visit this page or it doesn't exist..
                    </p>
                    <Link to="#" onClick ={() => window.location.replace('/')} className="btn-primary rounded">Back To Dashboard! </Link>
                   
                </main>
            </div>
        )
    }
}

export default NotFound;