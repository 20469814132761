import React, { Component } from "react";
import { Button, Card, Container, Row, Col, Form, Media, Badge, ListGroup } from "react-bootstrap";
import { fetchAllLanguageSuggestions, updateTranslatorProject, viewProjectEmail } from '../../actions/projectActions';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { toast } from'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import "excel2json-wasm";
import { decodedTranslatorToken } from '../../helpers/decode-token';
import appConfig from "appConfig";

class EditTranslatorFullScreen extends Component{

    constructor(props){
        super(props)

        this.state = {
            projectId:props.match.params.projectId,
            showMessage:'',
            errors: {},
            disable: false,
            total_languages_suggestions:[],
            fields: {
                projectname:'',
                due_date:new Date(),
                translation_tasks:[],
                language_suggestion:'',
                languages:[],
                activities:[],
                spread_sheet:{},
                comments:'',
                edit_translator:'admin'
            },
            spreadsheet: {},
            loader:true,
        }

        this.initialState = JSON.parse(JSON.stringify(this.state));
    }

    componentDidMount(){

        this.getProjectDetails(this.state.projectId);
        document.getElementsByClassName('main-panel')[0].classList.add('expandPanel');

    }

   

    getProjectDetails = async (projectId)=>{

        let fields = this.state.fields;
        this.setState({loader:true})
        await this.props.viewProjectEmail(projectId)
        if(this.props.viewProjectdetail.success){
            fields['projectname'] = this.props.viewProjectdetail.data.projectname;
            fields['due_date'] = new Date(this.props.viewProjectdetail.data.due_date);
            fields['translation_tasks'] = this.props.viewProjectdetail.data.translation_tasks;
            fields['languages'] = this.props.viewProjectdetail.project_languages.filter(p => p.translatorId._id === decodedTranslatorToken.userId);
            fields['comments'] = (this.props.viewProjectdetail.data.comments);
            fields['spread_sheet'] = this.props.viewProjectdetail.data.spread_sheet;
            
            this.setState({
                fields,
                loader:false,
            }, () => {
                this.spreadSheetChange();
            })
        }else{
            this.setState({loader:false})
        }
    }

    spreadSheetChange = () => {
        const spreadsheet = new dhx.Spreadsheet("spreadsheet",{
            exportModulePath:
                "https://cdn.dhtmlx.com/libs/json2excel/1.0/worker.js",
            importModulePath:
                "https://cdn.dhtmlx.com/libs/excel2json/1.0/worker.js",
            menu: true,
            multiSheets: false,
        });
        
        spreadsheet.parse(this.state.fields['spread_sheet']);
        spreadsheet.lock("A1:Z1");
        spreadsheet.lock("A1:A200");

        spreadsheet.events.on("afterValueChange", (cell, value) => {
            let fields = this.state.fields;
            fields['spread_sheet'] = spreadsheet.serialize();

            this.setState({ fields });
        });

        
    }

    onSuggestionsFetchRequested = async ({ value }) => {

        await this.props.fetchAllLanguageSuggestions();
        var all_languages = [];
        if(this.props.total_languages_suggestions.success){

            all_languages = this.props.total_languages_suggestions.languages;
        }

        const escapedValue = this.escapeRegexCharacters(value.trim());
    
        if (escapedValue === '') {
            all_languages = [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');
        this.setState({
            total_languages_suggestions: all_languages.filter(p => regex.test(p.language))
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            total_languages_suggestions: []
        });
    };

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestionValue = (suggestion) => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors['languages'] = ''
        this.setState({ errors })
        if (suggestion.language < 1) return '';

        if(suggestion.language.trim().length < 1) return '';
            let languageFound = fields['languages'].find(t => t.language.toLowerCase() === suggestion.language.toLowerCase())
            if(languageFound){
                errors['languages'] = `Can't add duplicate languages`
                this.setState({ errors })
                return '';
            }

            fields['languages'].push(suggestion);
            errors['languages'] = ''
            this.setState({fields, errors, disable: false});
        
        return '';
    }

    renderSuggestion = (suggestion, { query }) => {
        return (
            <Media className="align-items-center justify-content-between">
                <span>
                    <i class="far fa-check-circle"></i> {suggestion.language}
                </span>
                <Media.Body>
                    <div className="projectTitle d-flex align-items-center">
                        <i class="far fa-calendar mr-3"></i>
                        <span className="projectIcon mr-3">
                            <img src={this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${suggestion.translatorId.profile}`) ? `${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${suggestion.translatorId.profile}`  : require("assets/img/user-icon.png").default}  alt="..." />
                            <Badge variant="success"></Badge>
                        </span>
                        <h3>{suggestion.translatorId.fullname} <small className="d-block text-gray font-italic">{suggestion.translatorId.country}</small></h3>
                    </div>
                </Media.Body>
            </Media>
        )
    }

    ImageExist = (url) => 
    {
      var img = new Image();
      img.src = url;
      
      if(img.src.indexOf('null') == -1 ){

        return true;
      }
      return false;
    }

    onHandleProjectChange = (event, { newValue, method }) => {
        let fields = this.state.fields
        fields['language_suggestion'] = newValue
        this.setState({ fields })
    };

    handleRemoveLanguageOnClick = (tag) => {
        let fields = this.state.fields;
        let languages = fields['languages'];
        languages = languages.filter(t => t.language !== tag.language)
        if(languages.length < 1){
            let errors = this.state.errors
            errors['languages'] = 'At least enter one languages'
            this.setState({ errors, disable: true })
        }
        fields['languages'] = languages
        this.setState({fields})
    }

    subDays = (days) => {
        var d = new Date();
        return d;
    }

    handleCheckbox = (e) => {
        let fields = this.state.fields
        const value = e.target.value;
        if(e.target.checked){

            fields['translation_tasks'].push(value);
        }else{
            if(fields['translation_tasks'].indexOf(value) != -1){
                fields['translation_tasks'] = fields['translation_tasks'].filter(item => item !== value)
            }
        }
        
        this.setState({ fields })
    }

    checkedBoxChecking = (value) => {
        let fields = this.state.fields;
        var checked = false;
        if(fields['translation_tasks'].indexOf(value) != -1){
            checked = true;
        }
        return checked;
        
    }

    handleOnChange = (e, parameter) => {
        let fields = this.state.fields
        let errors = this.state.errors
        errors[parameter] = ''
        fields[parameter] = e.target.value;
        this.setState({ fields, errors })
    }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        
        if(!fields["projectname"]){
            formIsValid = false;
            errors["projectname"] = " Project is required";
        }    
        
        if(!fields["due_date"]){
            formIsValid = false;
            errors["due_date"] = "Due Date is required";
        }
        if(!fields["comments"]){
            formIsValid = false;
            errors["comments"] = "Comments is required";
        }

        

        if(fields["translation_tasks"].length == 0){
            formIsValid = false;
            errors["translation_tasks"] = "At least One Select Translation task";
        }

        if(fields["languages"].length == 0){
            formIsValid = false;
            errors["languages"] = "At least enter one language";
        }
        
        

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleOnSubmit = async () => {
       
        this.setState({ disable: true })
        await this.props.updateTranslatorProject(this.state.fields,this.state.projectId);
        if(this.props.projectUpdated.success){
            this.setState({ disable: false })
            this.props.history.push(`/project-detail/${this.state.projectId}`)
            
        }else{
            this.setState({ disable: false })
            toast.dismiss()
            toast.error( this.props.projectUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }

    exportXlsx  = () => {
        var arr = this.state.spreadsheet.serialize();
        if(!Array.isArray(arr)){

            this.state.spreadsheet.export.xlsx();
        }
        return false;
        

    }


    importXlsx  = () => {
        this.state.spreadsheet.load("", "xlsx");
    }

    componentWillUnmount(){
        this.spreadSheetChange();
        document.getElementsByClassName('main-panel')[0].classList.remove('expandPanel');
    }

    render(){
        const { loader } = this.state
        const inputProps = {
            placeholder: `Type Project Name to 'Edit' or Enter New Project Name to 'Add'`,
            value: this.state.fields['language_suggestion'],
            onChange: this.onHandleProjectChange
        };
        return (
            <>
              <Container fluid className="p-sm-0">
                <Row className="mb-4">
                    {   loader ?
                            <div style={{ textAlign:'center' }} className="col-sm-12 col-sm-offset-5"> 
                                <Loader type="Audio" color="#000" height={30} width={30} />
                            </div>
                            : 
                            <Col sm={12}>
                                <Card className="projectTable border-0 rounded-0">
                                <Card.Body>
                                
                                    <Form className="form-detail" >
                                        <Row>
                                            <Col md={6}> 
                                            <Button variant="primary"  className="ml-1 mt-1"  onClick = {() => this.handleOnSubmit()}><span><i class="fa fa-arrow-left mr-2"></i></span>Back</Button>
                                            </Col>
                                        </Row>
                                        <Row> 
                                            <Col md={12}>   
                                                <ListGroup className="flex-row mt-4">   
                                                    <div style={{ width: "100%", height: "700px" }}  id="spreadsheet" ref={this.spreadsheet}
                                                    ></div>
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                    
                                </Card.Body>
                                </Card>
                            </Col>
                    }
                </Row>
              </Container>
            </>
        )
    }
}


const mapStateToProps = state => {
  const { project } = state
  return {
    total_languages_suggestions: project.all_language_suggestions,
    viewProjectdetail : project.viewProject,
    projectUpdated : project.projectUpdated,
    
  }
}
const dispatchAction = { fetchAllLanguageSuggestions, updateTranslatorProject, viewProjectEmail }
export default connect(mapStateToProps, dispatchAction)(EditTranslatorFullScreen);