const Email = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
const Dateformat= new RegExp(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i);
const Alphanumeric = new RegExp(/^[A-Za-z\d\s]+$/);
const Aphabeticals = new RegExp(/^[a-zA-Z ]*$/);

export const EmailValidation = email => Email.test(email);
export const DateFormatValidation = date => Dateformat.exec(date);
export const AlphanumeicValidation = text => Alphanumeric.test(text);
export const AphabeticalsValidation = text => Aphabeticals.test(text);
export const capitalizeFirstLetterEachWord = (str) => {
    str = str.split(" ");
    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(" ");
}


export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}