import axios from 'axios';
import { authHeader } from '../helpers/auth-header';
import openSocket from "socket.io-client";
import appConfig from 'appConfig';
console.log("appConfig" , appConfig)
var BaseURL = appConfig.REACT_APP_BASE_URL;
var TranslatorBaseURL = appConfig.REACT_APP_TRANSLATOR_BASE_URL;
var AdminBaseURL = appConfig.REACT_APP_ADMIN_BASE_URL;
var SocketURL = appConfig.REACT_APP_SERVER_PATH;
import { toast } from "react-toastify";
toast.configure()



const apiRequest = async (url, requestOptions) => {
    try {
        const response = await fetch(`${BaseURL}/${url}`, requestOptions);
        const result = await response.json();
        if(result.tokenExpired){
            localStorage.removeItem('scriptDBToken_admin');
            window.location.replace('/login')
        }
        return result;
    } catch (e) {
        throw e.response
    }
}


const formDataPost = async (url, formData) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data; charset=utf-8;",
                "authorization": authHeader() 
            }
        }
        const response = await axios.post(`${BaseURL}/${url}`, formData, config)

        const result = await response.data;
        return result;
    } catch (e) {
        throw e.response
    }
}

const formDataPut = async (url, formData) => {
    try {
        const config = {
            mode: "cors",
            headers: {
                "Content-Type": "multipart/form-data; charset=utf-8;",
                "authorization": authHeader() 
            }
        }
        const response = await axios.put(`${BaseURL}/${url}`, formData, config)
        const result = await response.data;
        return result;
    } catch (e) {
        throw e.response
    }
}

const translatorApiRequest = async (url, requestOptions) => {
    try {
        const response = await fetch(`${TranslatorBaseURL}/${url}`, requestOptions);
        const result = await response.json();
       

        if(result.tokenExpired){
            localStorage.removeItem('scriptDBToken_admin');
            window.location.replace('/login')
        }
        return result;
    } catch (e) {
        throw e.response
    }
}

const adminApiRequest = async (url, requestOptions) => {
    try {
        const response = await fetch(`${AdminBaseURL}/${url}`, requestOptions);
        const result = await response.json();
        if(result.tokenExpired){
            localStorage.removeItem('scriptDBToken_admin');
            window.location.replace('/login')
        }
        return result;
    } catch (e) {
        throw e.response
    }
}


const checkTokenExpire = (result) => {
    if(result && result.error && result.error.name === 'TokenExpiredError'){
        localStorage.clear();
        setTimeout(() => {
            window.location.replace('/login')
        }, 1000)
    }
}

const handleError = (error) => {
    toast.dismiss()
    if(error.data){
        toast.error( error.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
    }else{
        toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true})
    }
}

const socket = openSocket(SocketURL, {transports:['polling']}); 

export { apiRequest, formDataPost, formDataPut, translatorApiRequest, adminApiRequest, socket, checkTokenExpire, handleError }