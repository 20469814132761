import { FETCH_NOTIFICATIONS } from './types';
import { apiRequest, checkTokenExpire } from './fetchActions';
import { authHeader } from '../helpers/auth-header';
import { toast } from "react-toastify";
toast.configure()


export const fetchNotifications = (userId, page, per_page) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        const result = await apiRequest( `v1/notifications/${userId}?page=${page}&per_page=${per_page}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_NOTIFICATIONS, payload: result })
    } catch (error) {

        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}

export const fetchAdminNotifications = (adminId, page, per_page) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        const result = await apiRequest( `v1/admin-notifications/${adminId}?page=${page}&per_page=${per_page}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_NOTIFICATIONS, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
        }
    }
}