import React, { Component } from 'react';

import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import TranslatorSidebar from "components/Sidebar/TranslatorSidebar";
import ROUTES, { RenderRoutes } from "../routes";
import TRANSLATOR_ROUTES, { TranslatorRoutes } from "../translatorRoutes";
import { isLoggedIn } from '../helpers/decode-token';
import  jwt_decode  from 'jwt-decode';
import { viewTranslator } from 'actions/translatorActions';
import { connect } from 'react-redux';
import IntialLoginModel from 'components/FrontEnd/IntialLoginModel';

class Layout extends Component{
    constructor(props){
        super(props)
        this.state = {
          showModel:false,
          translatorId :''
         
        }
    }
    async componentDidMount(){
        await this.checkTranslatorRole();
    }

    componentDidUpdate(prevProps) {
        // Check if the viewTranslatordetail prop has changed
        if (prevProps.viewTranslatordetail !== this?.props?.viewTranslatordetail) {
            this.checkTranslatorRole();
        }
    }

    checkTranslatorRole=async() => {
        const token = localStorage.getItem('scriptDBToken_admin') || localStorage.getItem('scriptDBToken_translator');
        const decodedToken = token ? jwt_decode(token) : null;
        const userRole = decodedToken.role;
        // Check if the role is a string or an array
        if (typeof userRole === 'string') {
            // Handle the case when the role is a string
            if (userRole === 'translator') {
                await this.checkIntialLogin(decodedToken.userId);
            }
        } else if (Array.isArray(userRole)) {
            // Handle the case when the role is an array
            if (userRole.includes('translator')) {
                await this.checkIntialLogin(decodedToken.userId);
            }
        }
    }

    checkIntialLogin = async (translatorId) => {
        await this.props.viewTranslator(translatorId);
        if (this?.props?.viewTranslatordetail && this?.props?.viewTranslatordetail?.data) {
            if (!this?.props?.viewTranslatordetail?.data?.intialLogin) {
                this.setState({ showModel: true });
            }
        }
    }
    
    render(){
            const showModel = this.state.showModel
            const translator = this?.props?.viewTranslatordetail?.data
            if(showModel){
                return(
                    <IntialLoginModel translatorData= {translator}/>
                )
            }else{
                
                return (
                    <div id="wrapper">
                        {isLoggedIn ? <Sidebar color="black" routes={ROUTES} /> : <TranslatorSidebar color="black" routes={TRANSLATOR_ROUTES} />}
                            <div  class="main-panel">
                                <AdminNavbar {...this.props} />
                                <div class="content" >
                                {isLoggedIn ? <RenderRoutes routes={ROUTES}  /> : <TranslatorRoutes routes={TRANSLATOR_ROUTES}  />}
                            </div>
                        </div> 
                    </div>
                    // <h1>welcome</h1>
                )
            }
        }
}


const mapStateToProps = state => {
    const { translator } = state
    return {
      viewTranslatordetail : translator?.viewTranslator,
    }
  }
  const dispatchAction = {viewTranslator}
  export default connect(mapStateToProps, dispatchAction)(Layout);

// export default Layout;