import React, { Component } from 'react';
import { listCountries} from '../../actions/translatorActions';
import { Container, Button, Row, Col } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import { Modal , Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { translatorLogin  , viewTranslator , updateTranslator  } from '../../actions/translatorActions';
import { decodedTranslatorToken } from 'helpers/decode-token';

class IntialLoginModel extends Component{
    constructor(props){
        super(props);
        this.state = {
            fields : {
                languages : [] ,
                country : '' , 
                language_suggestion : '' ,
                intialLogin : '',
                translatorId : decodedTranslatorToken.userId
            },
            countries:{},
            total_languages_suggestions : [] , 
            errors: {},
        }
    }
    componentDidMount(){
        this.getCountries();
        const fields = this.state.fields ; 
    }

    getCountries = async () => {
        await this.props.listCountries()
        if(Object.keys(this.props.countries).length >= 0){
            var array = (this.props.countries);
            array = Object.values(array);

            array = array.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            this.setState({
                countries: array
            })
        }
    }

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    onSuggestionsFetchRequested = async ({ value }) => {

        await this.props.listCountries();
        var all_languages = [];

        all_languages = this?.props?.total_languages_suggestions;

        const escapedValue = this.escapeRegexCharacters(value.trim());

        var array = Object.values(all_languages);
        array.push({ name: "Khmer", native: "Cambodia" })
        array.push({name : "Urdu-Roman" , native : "رومن اردو" })
        if (escapedValue === '') {
            all_languages = [];
        }
        const regex = new RegExp('^' + escapedValue, 'i');
        

        this.setState({
            total_languages_suggestions: array.filter((c, i) => regex.test(c.name))
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            total_languages_suggestions: []
        });
    };


    handleRemoveLanguageOnClick = (tag) => {
        let fields = this.state.fields;
        let languages = fields['languages'];
        languages = languages.filter(t => t !== tag)
        if(languages.length < 1){
            let errors = this.state.errors
            errors['languages'] = 'At least enter one languages'
            this.setState({ errors })
        }
        fields['languages'] = languages
        this.setState({fields})
    }


    getSuggestionValue = (suggestion) => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        errors['languages'] = ''
        this.setState({ errors })
        if (suggestion.name < 1) return '';

        if(suggestion.name.trim().length < 1) return '';
            let languageFound = fields['languages'].find(t => t.toLowerCase() === suggestion.name.toLowerCase())
            if(languageFound){
                errors['languages'] = `Can't add duplicate languages`
                this.setState({ errors })
                return '';
            }

            fields['languages'].push(suggestion.name);
            errors['languages'] = ''
           
            this.setState({fields, errors, disable: false});
        
        return '';
    }


    renderSuggestion = (suggestion, { query }) => {
        return (
            <div>
                {suggestion.name}
            </div>
            
        )
    }

    onHandleProjectChange = (event, { newValue, method }) => {
        let fields = this.state.fields
        fields['language_suggestion'] = newValue
        this.setState({ fields })
    };



    EditTranslator = async (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            this.setState({ disable: true })
            
            let fields = this.state.fields
            fields.intialLogin = true

            var form_data = new FormData();

            for ( var key in fields ) {
                form_data.append(key, fields[key]);
            }
            await this.props.updateTranslator(form_data);
            if(this?.props?.translatorUpdated?.success){
                this.setState({ disable: false })
                swal("Good job!", this?.props?.translatorUpdated?.message, "success")
                .then(async (willDelete) => {
                    if (willDelete) {
                        setTimeout(()=> {
                            window.location.replace('/')
                        }, 1000)
                    }
                });
                this.setState({loader:false})
            }else{
                this.setState({ disable: false })
                toast.dismiss()
                toast.error( this.props.translatorUpdated.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true })
            }
        }
    }

    handleOnChange = (e, parameter) => {
        let fields = this.state.fields
        let errors = this.state.errors
        errors[parameter] = ''
        const value = e.target.value;
        fields[parameter] = value;
        this.setState({ fields, errors })
    }


    handleValidation = () => {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        if(!fields["country"]){
            formIsValid = false;
            errors["country"] = "Country is required";
        }

        if(fields["languages"].length == 0){
            formIsValid = false;
            errors["languages"] = "At least enter one language";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    render(){
        const {countries , total_languages_suggestions} = this.state ; 
        const inputProps = {
            placeholder: `Add Language`,
            value: this.state.fields['language_suggestion'],
            onChange: this.onHandleProjectChange
        };
        return (
            <Container>
                <Row>
                    <Col sm={12} className="contentSec">
                        <h3>Please select a Country and language </h3>
                        <Form className="admin" onSubmit= {this.EditTranslator}>
                            <Form.Group controlId="formBasicEmail">
                                    <Form.Control as="select" onChange={(e) => this.handleOnChange(e, 'country')} value={this.state.fields['country']}>
                                        <option value="">Select Country</option>
                                        {countries.length > 0 &&
                                            countries.map((c, i) => {
                                            return (
                                                <option key={i} value={c.name}>
                                                    {c.name}
                                                </option>
                                                )
                                            })
                                            
                                        }
                                    </Form.Control>     
                                    <span style={{color: "red", fontSize:'12px'}}>{this.state.errors["country"]}</span>                          
                            </Form.Group>
                            <div className="form-group languages-lebal">
                                <label htmlFor="languages">Languages</label>
                                {this.state.fields['languages'].length > 0 &&
                                <ul>
                                    {this.state.fields['languages'].map((t, i) => (
                                            <li key={i}>
                                                <span style={{display:'inline'}} className="mr-2"> {t} </span>
                                                <span style={{display:'inline', cursor:'pointer'}} className="fa fa-times" onClick={() => this.handleRemoveLanguageOnClick(t)}></span>
                                        </li>
                                    ))}
                                </ul>
                                }
                                <div className="inputs">
                                    <Autosuggest 
                                        suggestions={total_languages_suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        inputProps={inputProps}
                                    /> 
                                </div>
                            </div>    
                            <div className="form-group text-center">
                                <Button type="submit" className="btn btn-primary btn-block" >{this.state.disable ? 'Processing...' : 'Submit'}</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    
    const { admin, translator } = state
    return { 
        admin: admin.admin, 
        translator: translator.translator ,
        countries: translator.countries.data,
        total_languages_suggestions: translator.countries.languages,
        viewTranslatordetail : translator.viewTranslator,
        translatorUpdated : translator.translatorUpdated,
    }
}


const dispatchState = {listCountries , viewTranslator , updateTranslator};

export default connect(mapStateToProps, dispatchState)(IntialLoginModel);  