/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Navbar, Form, Container, Nav, Dropdown, Button, Badge,Card, Row, Col, Table } from "react-bootstrap";
import Loader from 'react-loader-spinner';
import { isLoggedIn, decodedToken, isTranslatorLoggedIn, decodedTranslatorToken } from '../../helpers/decode-token';
import { connect } from 'react-redux';
import { getAdmin } from '../../actions/adminActions';
import { viewTranslator } from '../../actions/translatorActions';
import { toast } from'react-toastify';
import appConfig from "appConfig";

class Sidebar extends Component {
  
  constructor(props){

    super(props)
    
    this.state = {
        admin:'',
        translator:'',
        loader:true,
    }

  }
 

  ImageExist = (url) => 
  {
    var img = new Image();
    img.src = url;
   
    if(img.src.indexOf('null') == -1 ){

      return true;
    }
    return false;
  }


  componentDidMount(){
    
    if(isTranslatorLoggedIn){

      this.getTranslatorDetails(decodedTranslatorToken.userId);
    }

    if(window.location.href !== '/'){
      document.getElementById('dashboard-class').classList.remove('active'); 
    }


  }

  getTranslatorDetails = async (translatorId)=>{
    this.setState({loader:true})
    await this.props.viewTranslator(translatorId)
    if(this.props.translator.success){
        this.props.translator.data.languages = this.props.translator.data.languages.map(lang => lang.language)
 
        this.setState({
            translator:this.props.translator.data,
            loader:false,
        })
    }else{
        this.setState({loader:false})
    }
  }



  render(){
    const { admin, translator, loader } = this.state

    return (
      <div className="sidebar" data-image={this.props.image} data-color={this.props.color}>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url(" + this.props.image + ")",
          }}
        />
        <div className="sidebar-wrapper">
          <div className="logo d-flex align-items-center justify-content-start">
            <a href="/" className="simple-text logo-mini mx-1">
              <div className="logo-img">
                <img
                  src={require("assets/img/siteLogo.png")}
                  alt="..."
                />
              </div>
            </a>
          </div>
          <Nav>
            
            <li key='DASHBOARD_INDEX'>
              <NavLink
                to='/'
                className="nav-link"
                activeClassName="active"
                id="dashboard-class"
              >
                <i className='fas fa-th' />
                <p>Projects / Tasks</p>
              </NavLink>
            </li>
            <li key='ADD_TRANSLATOR'>
            <NavLink
              to='/translator-side-chat'
              className="nav-link"
              activeClassName="active"
            >
              <i className='fas fa-user' />
              <p>Chat</p>
            </NavLink>
          </li>
          
          </Nav>
          <div className="profile-sec">
            {  ( !loader && translator != 'undefined') ?
              <div className="no-icon">
                {isTranslatorLoggedIn ? (this.ImageExist(`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${translator.profile}`) ? <><img src={`${appConfig.REACT_APP_SERVER_PATH}`+`/translator_profile/`+`${translator.profile}`} alt="..." 
                height ="50"
                width="50"
                style={{borderRadius: "50%"}}
                /> 
                
                <div className = "text-white ">{translator.fullname} </div></>
                
                : <><i className="fas fa-user-circle sidebar-icon"></i><div className = "text-white ">{translator.fullname} </div></>) : ''} 

                <Badge variant="success" className="online1"></Badge>
                <span className = "online-text">Online </span>
                
              </div>
              : <div style={{ textAlign:'center',display:'none' }} className="col-sm-12 col-sm-offset-5"> 
                </div>
            }
            </div>
        </div>
      </div>
    );

  }
}

const mapStateToProps = state => {
  const { admin, translator } = state
  return {
      admin: admin.admin,
      translator: translator.viewTranslator
  }
}

const disptachActions = {
  getAdmin, 
  viewTranslator
}

export default connect(mapStateToProps, disptachActions)(Sidebar)
