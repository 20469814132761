import { ACTIVE_INACTIVE_TRANSLATOR, TRANSLATOR_ADD, LIST_TRANSLATOR, DELETE_TRANSLATOR, LIST_COUNTRIES, VIEW_TRANSLATOR, UPDATE_TRANSLATOR, TRANSLATOR_LOGIN, LIST_TOTAL_TRANSLATOR,UPDATE_SCRIPT_LANGUAGE_API,SUGGESTIONS_SCRIPT,UPDATE_PROJECTS, NOTIFY_STATUS} from "../actions/types";

const initialState = { add: '', delete:'', listAll:'',listAllTranslator: '', status:'', countries:'', viewTranslator:'', translatorUpdated:'', translator:'',allScriptSuggestion:'',updateProject:'' , translationStatus: ''}

export default ( state = initialState, action ) => {

    switch (action.type) {
        case TRANSLATOR_ADD:
            return {
                ...state,
                add: action.payload
            }
        case DELETE_TRANSLATOR:
            return {
                ...state,
                delete: action.payload
            }
        case LIST_TRANSLATOR:
            return {
                ...state,
                listAll: action.payload
            }
        case LIST_TOTAL_TRANSLATOR:
            return {
                ...state,
                listAllTranslator: action.payload
            }
        case ACTIVE_INACTIVE_TRANSLATOR:
            return {
                ...state,
                status: action.payload
            }
        case LIST_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            }
        case VIEW_TRANSLATOR:
            return {
                ...state,
                viewTranslator: action.payload
            }
        case UPDATE_TRANSLATOR:
            return {
                ...state,
                translatorUpdated: action.payload
            }
        case TRANSLATOR_LOGIN:
            return {
                ...state,
                translator: action.payload
            }
            case UPDATE_SCRIPT_LANGUAGE_API:
                   return{
                       ...state,
                       update_script_api_data:action.payload
                   } 
            case SUGGESTIONS_SCRIPT :
            return{
                ...state,
                allScriptSuggestion:action.payload
            }
            case UPDATE_PROJECTS:
                return{
                    ...state,
                    updateProject:action.payload
                }        
            case NOTIFY_STATUS:
                return {
                    ...state,
                    translationStatus:action.payload
                }    
        default: return state;
    }

}
